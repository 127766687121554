import React, { useEffect, useState, useContext } from 'react'
import { Navigate, BrowserRouter as  Router, Routes, Route } from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom'

import Loader from '../Alertas/LoaderUniversal'
import tokenAuth from '../../config/token';
import LoaderD from '../componentes/Loader/LoaderD';
import LoaderCenter from '../componentes/Loader/LoaderCenter';


////////////// vamos a importar un hoook //////

import useAuth from '../../hooks/useAuth'
import useData from '../../hooks/useData'
import clienteAxios from '../../config/axios';
import axiosAuth from '../../config/axiosAuth';

import gimage from '../imagenes/google/icon.svg'
import BAlerta from '../Alertas/BAlerta'






const LoginDomo = () => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const {setWinAlert} = useData();
    const [ redirectlog, setRedirect ] = useState(false);
    const [olvido, setOlvido] = useState(false);

    const togle = () => {
        if(olvido === false) {
            setOlvido(true)
        }
        if(olvido === true) {
            setOlvido(false)
        }
    }
    


    const [cargando, setCargando] = useState(false);
    const [usuario, setUsuario] = useState({
        email:'',
        password:''
    })
    const [alerta, setAlerta] = useState({
        msg:'',
        error:false,

    })
    const navigate = useNavigate()
    const Create = () => {
        navigate('/create')
    }
    const Loggin = () => {
        navigate('/core')
    }
    const [ inn, guardarInn ] = useState({
        holder_email:1,
        holder_password:0,
    });
    const onChange = e => {
        // voy a colocar guardar usuario en el state
        setUsuario({
            ...usuario,
            [e.target.name] : e.target.value,
            
  
        })
    }

    const { email , password} = usuario
  
  
    const [ mostrarpassword , setMostrarPassword] = useState(false)
    const { setAuth,autenticarUsuario, setInStatus, ActiveEN, ActiveES , lang} = useAuth();
 const { setCoreAdmin, superData,
    MostrarProfile,
    MostrarBeneficiario,
    MostrarAcc  } = useData();

  

    const IniciarSesion = async (
        // {setCargando,cargando,setAlerta,email,password, setAuth, axiosAuth, Loggin}
        ) => {
        setCargando(true)
        
        try {
            
            if (olvido === true) {
                const { data } = await clienteAxios.post('/api/client/forgotpassword', {email})
                setWinAlert({
                    data:data.message,
                    status:true,
                    open:true,
                });
                setCargando(false);
                setOlvido(false);
                
            } else {

            
            const { data } = await clienteAxios.post('/api/client/authenticate', {email, password})
            https://www.strprisma.com/thedome/webservice/api/client/forgotpassword
            
            localStorage.setItem('token',data.success.token);
            clienteAxios.defaults.headers.common['Authorization'] = `Bearer ${data.success.token}` ;

            setAuth({
                data:data.success.user,
                status:true,
            })

            // setCoreAdmin(data.success.user.user_type)

                    setInStatus(true);
                    Loggin()
                    // navigate('/core');

                    // setCargando(false);
                    
        }


        } catch (error) {
            
            setWinAlert({
                data: error.response.data.message,
                status:false,
                open:true,
            });
            setCargando(false)     
            // setTimeout( () => { 
            //     setAlerta({
            //         msg: '',
            //         error:false
            //     });
            //     },4000)
            //     setTimeout( () => { 
            //         setCargando(false)
            //         },1500)
                
        }
    }
        //// aqui vamos a colocar la logica de accion del boton

        const onSubmit = e => {
            e.preventDefault()
            
            // elGuardarCargando(true)
            window.scrollTo(0, 0)
            // PrismaLoad()
            // validar que no haya campos vacios
            if(email.trim() === '' || password.trim() ==='') {
                setWinAlert({
                    data: 'Todos los campos son obligatorios',
                    status:false,
                    open:true,
                });
                return
            } 
            if (password.length < 6) {  
                setWinAlert({
                    data: 'Todos los campos son obligatorios',
                    status:false,
                    open:true,
                });
                return
            }
         IniciarSesion()
           
            
        }
        


         if(cargando) return <LoaderCenter/>


    return (  

            <> 
            <BAlerta
                />
            
            <div className="bar-flag-all-domo d-none"> 
            <button className={`  ${lang.status === 'ES' ? 'Active-lang-2' :''}`} onClick={ActiveES}>
            {`${lang.data.Header.spanish}`}
            </button>

            <div className="text-center lil-text">
            {lang.status}

            </div>

            <button className={` ${lang.status === 'EN' ? 'Active-lang-2-left' :''}`} onClick={ActiveEN}>
            {`${lang.data.Header.english}`}
            </button>

        </div>

        {olvido === true ? 
            <>
                    <form className="domo-content" onSubmit={onSubmit}>
                        <section className="head-bar-domo">

                        </section>

                        <section className="body-bar-domo">

                                <div className="login-box-domo">
                                    <div className="domo-elemento-1">

                                        <span className="imagen-one">

                                        </span>

                                        <span className="imagen-two">
                                            <h3>
                                                   GROUNDSCRIPT FOR BUILDINGS
                                                {/* {`${lang.data.Domo.domocomun}`} */}
                                            </h3>
                                            <h2>{`${lang.data.Domo.recover}`}</h2>

                                        </span>

                                        



                                    </div>

                                    <div className="domo-elemento-2">

                                        <input  className="domo-input"
                                        type="text"
                                        id="email"
                                        required
                                        name="email"
                                        value={email}
                                        onChange={onChange}
                                            placeholder={`${lang.data.Domo.email}`}
                                        />
                                        
                               



                                    </div>

                                    <button className="btn-domo"
                                        type="submit"
                                    
                                    >
                                    {`${lang.data.Domo.recover}`}
                                        </button>

                                        <h2 className="create-link-domo hover-w" onClick={togle} >{`${lang.data.Domo.signin}`}</h2>




                                </div>

                                <div className="social-box-login-domo">

                                <div className="or-social-domo">{`${lang.data.Domo.ousa}`}</div>

                                    <div className="container-social-domo">
                                        <p>{`${lang.data.Domo.continuewith}`}</p>
                                    <img src={gimage} alt="google-icon"/>

                                    </div>



                                </div>



                            
                        </section>
           </form>
            
            </> 
            : 
            <> 
            <form className="domo-content" onSubmit={onSubmit}>
                        <section className="head-bar-domo">

                        </section>

                        <section className="body-bar-domo">

                                <div className="login-box-domo bg-ground ">
                                    <div className="domo-elemento-1">

                                        <span className="imagen-one">

                                        </span>

                                        <span className="imagen-two">
                                            <h2>
                                                {/* {`${lang.data.Domo.domocomun}`} */}
                                                GROUNDSCRIPT FOR BUILDINGS
                                                </h2>
                                            <h3>{`${lang.data.Domo.signin}`}</h3>

                                        </span>

                                        



                                    </div>

                                    <div className="domo-elemento-2">
                                        <label className="text-right">{`${lang.data.Domo.email}`}</label>

                                        <input  className="domo-input"
                                        type="text"
                                        id="email"
                                        required
                                        name="email"
                                        value={email}
                                        onChange={onChange}
                                            placeholder={`${lang.data.Domo.email}`}
                                        />

                                        <label className="text-right">{`${lang.data.Domo.password}`}</label>
                                        <input className="domo-input"
                                             type="password"
                                             id="password"
                                             required
                                             name="password"
                                             onChange={onChange}                                                            
                                             value={password}
                                            placeholder={`${lang.data.Domo.password}`}
                                        />
                                        <p className="text-right" onClick={togle}>{`${lang.data.Domo.forgot}`}</p>



                                    </div>

                                    <button className="btn-domo"
                                        type="submit"
                                    
                                    >
                                    {`${lang.data.Domo.signin}`}
                                        </button>

                                        <h2 className="create-link-domo">{`${lang.data.Domo.nota}`} <span className="create-domo" onClick={Create}> {`${lang.data.Domo.create}`} </span></h2>




                                </div>

                                <div className="social-box-login-domo ">

                                <div className="or-social-domo">{`${lang.data.Domo.ousa}`}</div>

                                    <div className="container-social-domo">
                                        <p>{`${lang.data.Domo.continuewith}`}</p>
                                    <img src={gimage} alt="google-icon"/>

                                    </div>



                                </div>



                            
                        </section>
           </form>
            

            </> 
        }

           
            </>


    );
}
 
export default LoginDomo;