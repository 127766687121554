import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'
import InputOn from '../../componentes/InputD/InputOn';


const HerramientasParaCorredoresProfesionales = () => {
    const information = [
        {   id:0,
            icon:a,
            title:'¿Por qué mi información no aparece aquí?',
            cuento:'Para proteger tu identidad, ocultamos algunos datos de la cuenta.'
        }, {   id:1,
            icon:b,
            title:'¿Qué datos se pueden editar?',
            cuento:'No se pueden modificar los datos que Airbnb utiliza para verificar tu identidad. Aunque puedes modificar tus datos personales y de contacto, podríamos pedirte que verifiques tu identidad la próxima vez que hagas una reservación o crees un anuncio.'
        }, {   id:2,
            icon:c,
            title:'¿Qué información se comparte con los demás?',
            cuento:'Airbnb solo proporciona los datos de contacto de los anfitriones y los huéspedes cuando se confirma una reservación.'
        },
    ]


    const information02 = [
        {   id:0,
            icon:b,
            title:'Protege tu cuenta',
            cuento:'Responde solo a las solicitudes de personas que conozcas. Es posible que los hackers quieran acceder a tu cuenta imitando el correo electrónico de un miembro del equipo.'
        }
    ]

    const information01 = [
        {   id:0,
            icon:b,
            title:'Vamos a hacer que tu cuenta sea más segura',
            cuento:'Trabajamos constantemente en nuevas formas para aumentar la seguridad de nuestra comunidad. Es por eso que revisamos cada cuenta para asegurarnos de que sea lo más segura posible.',
            btn:'Mejorar'
        }
    ] 

    const information03 = [
        {   id:0,
            icon:a,
            title:'Cómo agregar dispositivos de personas de confianza',
            cuento:'Cuando apruebas una solicitud, le estás dando a esa persona acceso total a tu cuenta. Podrá modificar las reservaciones y enviar mensajes en tu nombre.',
            btn:'Mejorar'
        }
    ]



    const task = [
       { id:0,
        value:'INICIO DE SESIÓN'
        },
        { id:1,
            value:'SOLICITUDES DE INICIO DE SESIÓN'
            },
            { id:2,
                value:'ACCESO COMPARTIDO'
                },
    ]

    const {
        alessa,
        MeEncantas,
        focustask, setFocusTask,
        activarscroll
    } = useAcc()

    // useEffect( () => {
    //     if(focustask === "") {
    //         setFocusTask('INICIO DE SESIÓN')
    //     }
    // },[alessa])


    
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        
        
        <>
        { alessa.T == 'Herramientas para corredores profesionales' ?
                 <div className="box-header-general">
                 <div className={`sabes-que-te-quiero`} >

                     <div>
                         <div className={`header-joema-hola-acc`}>
                             <p onClick={MeEncantas} className={`text-deco`}>
                                 Cuenta
                             </p>
                             <span>{'>'}</span>
                                 <p >{alessa.T}</p>
                         </div>
                         <div className={`header-joema-hola-title`}>
                             <p className={`Font-Love-Title`}>
                                 {alessa.T}
                             </p>
                         </div>
                     </div>
                    
                              <section className={`contenedor-doble`}>
                                       <div className={``}>

                                        <div className={`contenedor-input`}>

                                            <div className={`contenedor-header-input`}>
                                                    <h2 className={``}>
                                                    Empresa
                                                    </h2>

                                                    <button >
                                                        { 'Administrar'}
                                                    </button>
                                            </div>   


                                         <div className={`information-input`}>
                                                <p className={`text-input`}>
                                                Edita la información de tu empresa y administra anuncios a gran escala
                                                </p>
                                                </div>
                                         </div>

                                         <div className={`contenedor-input-33`}> 


                                            <div> 

                                             <div className={`information-input`}>
                                                <p className={`text-input subinputt`}>Quiero usar un alias en el correo electrónico para comunicarme con los huéspedes</p>
                                                <p className={`text-input`}>
                                                Confirmo que voy a usar el alias de correo electrónico solo por las siguientes razones: 
                                                </p>

                                                <ul className={`subul`}>
                                                    <li>Necesito enviar documentos adjuntos requeridos por las leyes locales.</li>
                                                    <li>Necesito enviar contratos de arrendamiento como adjunto.</li>
                                                    <li>Necesito enviar guías de llegada como adjunto.</li>
                                                    <li>Uso software conectado al que no está integrado con los mensajes.</li>
                                                </ul>


                                                
                                                <p className={`text-input`}>
                                                Entiendo que mi uso del alias de correo electrónico está sujeto a la <span className="btn-text-raya">  Política de uso fuera de plataforma.</span>
                                                </p>
                                                </div>

                                            </div>

                                            <div className={`obcentere`}>
                                                <InputOn
                                                    state={true}
                                                />
                                            </div>

                                         </div>

                                                   



                                                </div>
                                                <div >


                                                    {/* <div className={`information-box-box-container`}>
                                                    {  information03.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                            <img src={m.icon}/>  
                                                                        <h3>{m.title}</h3>
                                                                        <p>{m.cuento}</p>
                                                                      
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div> */}
                                                    
                                                </div>

                     </section>
                                        
               
                    

                 </div>

                
          </div> : null

        }

       
        </>

    );
}
 
export default HerramientasParaCorredoresProfesionales;