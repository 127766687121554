import React, { useReducer } from 'react';
import SignatureContext from './signatureContext';
import SignatureReducer from './signatureReducer';

import clienteAxios from '../../../config/axios';
import tokenAuth from '../../../config/token';


import {
    STEP_1_SIGNATURE_OPEN, 
    STEP_COMPLETE_SIGNATURE_OPEN 
} from '../../../types'; 




/// vamos a definir a nuestro state

const SignatureState = ({children}) => {

    ///inicializar un state 
    const initialState = {
        primero:true,
        segundo:false,
        tercero:false,
        cuarto:false,
        quinto:false,
        sexto:false,
        septimo:false,
        octavo:false,
        complete:null,
    }

    // siempre va a utilizar 2 parametros el 
    // el reducer que quieras usar y el state inicial
    const [ state, dispatch ] = useReducer(SignatureState,initialState);

    // las funciones 

    // funcion para registrar usuario


    const Step1 = () => {
        dispatch({
                type:STEP_1_SIGNATURE_OPEN,
                payload:true,
        })
    }



    const Complete = () => {
        dispatch({
                type:STEP_COMPLETE_SIGNATURE_OPEN,
                payload:true,
        })
    }


    const Wait = () => {
        dispatch({
                type:STEP_COMPLETE_SIGNATURE_OPEN,
                payload:false,
        })
    }




    





    return (

        // dentro de la primera llave vamos a pasar el toquen y 
        // si el usuario esta autenticado

        // aqui le estamos pasando los datos que creamos en las funciones
            <SignatureContext.Provider
            value={{
                primero: state.primero,
                segundo: state.segundo,
                tercero: state.tercero,
                cuarto: state.cuarto,
                quinto: state.cuarto,
                sexto:state.sexto,
                septimo:state.septimo,
                octavo:state.octavo,
                complete:state.complete,
                Step1,
                Complete,
                Wait
   

            }} 
            
            > 
            {children}

            </SignatureContext.Provider>

    )



}

export default SignatureState;