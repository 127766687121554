import React, { useState, createContext, useEffect} from 'react'
import clienteAxios from '../../config/axios'
import { Link, useNavigate } from 'react-router-dom'
import {  PRO , COM } from '../../microdata/dataLocation'




const ActJoemaSearchContext = createContext();

const ActJoemaSearchProvider = ({children}) => {
    /// vamos a colocar los estados del loader 

   console.log(PRO)
   console.log(COM)

    const [loeader, setLo] = useState(false)
    const oloader = () => {
        setLo(true)
    }
    const cloader = () => {
        setLo(false)
    }
    useEffect( () =>{
        // setTimeout(() => {
        //     cloader()
        //   }, 2000)

          const timer = setTimeout(() => {
            cloader();
          }, 300);
      
          // Limpiar el temporizador cuando el componente se desmonta o cuando loader cambie
          return () => clearTimeout(timer);

          
    },[loeader])

    /// aqui vamos a colocar toda la logica del buscador
    const [ p01 , setP01] = useState(true); //// estado de paso 1 provincias seleccion
    const [ p02 , setP02] = useState(false); /// estado de paso 2 distrito seleccion
    const [ p03 , setP03] = useState(false); /// estado de paso 3 corregimiento
    const [paso, setPaso] = useState(1)
    const Provincia = () => {
        setPaso(1)
        setP01(true);
        setP02(false);
        setP03(false);
    }
    const Distrito = () => { /// funcion para activar seleccion de distrito
        setPaso(2)
        setP01(false);
        setP02(true);
        setP03(false);
        setTermCorregimiento("")
        oloader()
    }
    const Corregimiento = () =>{ //// funcinon pra activar en el buscador corregimiento
        setPaso(3)
        setP01(false);
        setP02(false);
        setP03(true);
    }
    const [selectpro,setSelectPro] = useState([]) ///// aqui estoy almacenando el nuevo arreglo
    const [selectdis, setSelectDis] = useState([]) 
    const [finalterm, setFinalTerm ] = useState('');
    const [term, setTerm ] = useState(""); //// aqui estoy almacenando la pronvinia en el termino
    // console.log(term)
    const [termI, setTermI] = useState(""); /// aqui estoy almacenando el mapa de la provincia
    const [termdistrito, setTermDistrito ] = useState("") ; //////
    const [termcorregimiento, setTermCorregimiento ] = useState("")

    const [cantidadderesultados, setCantBusqueda ] = useState(0);//// este estado el numero de elementos encontrados
    // const onChange = ( e ) =>{
    //     setTerm(e.target.value)
    //  }
    const addTermFinal = (x) => {
        setFinalTerm(`${x}`)
    }

    // function searchingTerm(finalterm){
    //     return function(x){
    //       return (  
    //                 (x.provincia.includes(finalterm)) || 
    //                 (x.corregimiento.includes(finalterm)) || 
    //                 (x.distrito.includes(finalterm)) ||   
    //                  (!finalterm)  
    //               )
    //     }
    //   }
      ////// aqui vamos a colocar la segunda data de prueba
      const filtro = {
        provincia: term,
        distrito: termdistrito,
        corregimiento: termcorregimiento,
      }; 
    //   console.log(filtro)
     
      /// aqui lo impresionante es que esta reaccionando a la variables , solo con cadena
      /// cone el onClick
      function searchingTerm(terms) {
        return function (x) {
          const provinciaMatch = terms.provincia ? x.provincia.includes(terms.provincia) : true;
          const distritoMatch = terms.distrito ? x.distrito.includes(terms.distrito) : true;
          const corregimientoMatch = terms.corregimiento ? x.corregimiento.includes(terms.corregimiento) : true;
      
          return provinciaMatch && distritoMatch && corregimientoMatch;
        };
      }

      console.log(filtro)
      console.log(cantidadderesultados)

    return (
        <ActJoemaSearchContext.Provider
            value={{  
                searchingTerm,
                term,
                setTerm,
                termI,
                setTermI,
                setSelectPro,
                selectpro,
                Provincia,
                Distrito,
                Corregimiento,
                p01,
                p02,
                p03,
                paso,
                selectdis, setSelectDis,
                termdistrito, setTermDistrito,
                addTermFinal,
                finalterm,
                setFinalTerm,
                termcorregimiento, setTermCorregimiento,
                filtro,
                loeader,
                oloader,cantidadderesultados, setCantBusqueda

            }}
        >


            {children}
        </ActJoemaSearchContext.Provider>

    )


}

export {
    ActJoemaSearchProvider
}

export default ActJoemaSearchContext;