import React, { useEffect, useState, lazy, Suspense } from 'react';
import HeaderBar from './DashBoardUltimate/HeaderBar';
import FooterBar from './DashBoardUltimate/FooterBar';
import Propiedades from './DashBoardUltimate/Propiedades';
import useAct from '../hooks/useAct';
const LazyPropiedades = lazy(() => import('./DashBoardUltimate/Propiedades'));

const UltraUsuario = () => {

    // const data = [
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/2d8fd134-8d7c-4b69-a37e-17558908c426.jpg',
    //         img2:'https://a0.muscache.com/im/pictures/2d8fd134-8d7c-4b69-a37e-17558908c426.jpg',
    //         img3:'https://a0.muscache.com/im/pictures/2d8fd134-8d7c-4b69-a37e-17558908c426.jpg',
    //     },
    //     label: 'Welcome! Start Here',
    //     text:"Descanso en Melgar, con WIFI-BBQ  y mas",
    //     id:1 ,
    //     type:'Master',
    //     total:'401', 
    //     pornoche:'42',
    //     puntuacion:'5.0',
    //     ncamas:"11"    
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/2adf6ef9-e131-431b-a34e-9566e768f509.jpg',
    //         img2:'https://a0.muscache.com/im/pictures/2adf6ef9-e131-431b-a34e-9566e768f509.jpg',
    //         img3:'https://a0.muscache.com/im/pictures/2adf6ef9-e131-431b-a34e-9566e768f509.jpg',
    //     }, 
    //         label:'Alojamiento en Melgar',
    //         text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:2 ,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"      
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/82c577ee-3422-4fda-ae09-6716d76e8bef.jpg',
    //         img2:'https://a0.muscache.com/im/pictures/82c577ee-3422-4fda-ae09-6716d76e8bef.jpg',
    //         img3:'https://a0.muscache.com/im/pictures/82c577ee-3422-4fda-ae09-6716d76e8bef.jpg',
    //     }, 
    //         label: 'Alojamiento en Melgar',
    //         text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:3 ,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"      
    //     },
    //     {img: {
    //         img1:  'https://a0.muscache.com/im/pictures/1ef9b49c-6f99-4018-95f9-8471a9fbbd15.jpg',
    //         img2:  'https://a0.muscache.com/im/pictures/1ef9b49c-6f99-4018-95f9-8471a9fbbd15.jpg',
    //         img3: 'https://a0.muscache.com/im/pictures/1ef9b49c-6f99-4018-95f9-8471a9fbbd15.jpg',
    //     },
    //         label: 'Alojamiento en Melgar',
    //         text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:4,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"       
    //     },
    //     {img: {
    //         img1:'https://a0.muscache.com/im/pictures/ace7d6e7-589c-4fd9-be13-c2355a2e9576.jpg',
    //         img2: 'https://a0.muscache.com/im/pictures/ace7d6e7-589c-4fd9-be13-c2355a2e9576.jpg',
    //         img3: 'https://a0.muscache.com/im/pictures/ace7d6e7-589c-4fd9-be13-c2355a2e9576.jpg',
    //     },
    //     label: 'Alojamiento en Melgar',
    //     text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:5,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"       
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-50374296/original/1ecf8b4d-836c-42c1-b528-4bf7bb7d07fb.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-50374296/original/1ecf8b4d-836c-42c1-b528-4bf7bb7d07fb.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-50374296/original/1ecf8b4d-836c-42c1-b528-4bf7bb7d07fb.jpeg',
    //     },
    //     label: 'Alojamiento en Melgar',
    //     text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //     id:6,
    //     total:'401', 
    //     pornoche:'42',
    //     puntuacion:'5.0',
    //     ncamas:"11"  
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-53298889/original/b6f677cf-9461-4e89-8628-a6e61015eebb.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-53298889/original/b6f677cf-9461-4e89-8628-a6e61015eebb.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-53298889/original/b6f677cf-9461-4e89-8628-a6e61015eebb.jpeg',
    //     },
    //     label: 'Welcome! Start Here',
    //     text:"Descanso en Melgar, con WIFI-BBQ  y mas",
    //     id:7 ,
    //     type:'Master',
    //     total:'401', 
    //     pornoche:'42',
    //     puntuacion:'5.0',
    //     ncamas:"11"    
    //     },
    //     {img: {
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-24020528/original/62f97b0e-00e7-46d5-aac4-9f586cac3dec.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-24020528/original/62f97b0e-00e7-46d5-aac4-9f586cac3dec.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-24020528/original/62f97b0e-00e7-46d5-aac4-9f586cac3dec.jpeg',
    //     },
    //         label:'Alojamiento en Melgar',
    //         text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:8 ,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"      
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-51170869/original/9476f028-282b-4d3c-860a-b1a24e1bf46c.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-51170869/original/9476f028-282b-4d3c-860a-b1a24e1bf46c.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-51170869/original/9476f028-282b-4d3c-860a-b1a24e1bf46c.jpeg',
    //     },
    //         label: 'Alojamiento en Melgar',
    //         text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:9 ,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"      
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-42086947/original/7798bc24-1766-416b-9b1e-108b3a78ea14.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-42086947/original/7798bc24-1766-416b-9b1e-108b3a78ea14.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-42086947/original/7798bc24-1766-416b-9b1e-108b3a78ea14.jpeg',
    //     },
    //         label: 'Alojamiento en Melgar',
    //         text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:10,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"       
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-602204472942251748/original/8a68f968-d4d7-4756-af4b-f8180340e887.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-602204472942251748/original/8a68f968-d4d7-4756-af4b-f8180340e887.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-602204472942251748/original/8a68f968-d4d7-4756-af4b-f8180340e887.jpeg',
    //     },
    //     label: 'Alojamiento en Melgar',
    //     text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:11,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"       
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-49206876/original/ad679757-aa55-4860-99b3-6207514314ac.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-49206876/original/ad679757-aa55-4860-99b3-6207514314ac.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-49206876/original/ad679757-aa55-4860-99b3-6207514314ac.jpeg',
    //     },
    //     label: 'Alojamiento en Melgar',
    //     text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //     id:12,
    //     total:'401', 
    //     pornoche:'42',
    //     puntuacion:'5.0',
    //     ncamas:"11"  
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-36598885/original/8cdf5462-535f-4eb5-8bfc-b0710412bd0a.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-36598885/original/8cdf5462-535f-4eb5-8bfc-b0710412bd0a.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-36598885/original/8cdf5462-535f-4eb5-8bfc-b0710412bd0a.jpeg',
    //     },
    //     label: 'Welcome! Start Here',
    //     text:"Descanso en Melgar, con WIFI-BBQ  y mas",
    //     id:13 ,
    //     type:'Master',
    //     total:'401', 
    //     pornoche:'42',
    //     puntuacion:'5.0',
    //     ncamas:"11"    
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-656267262053988998/original/6c3922ce-0363-4451-8aae-78e06e08e70a.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-656267262053988998/original/6c3922ce-0363-4451-8aae-78e06e08e70a.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-656267262053988998/original/6c3922ce-0363-4451-8aae-78e06e08e70a.jpeg',
    //     },
    //         label:'Alojamiento en Melgar',
    //         text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:14 ,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"      
    //     },
    //     {img: {
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-800250652648024285/original/5711a2f6-4305-4d82-84b0-032ab615e47d.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-800250652648024285/original/5711a2f6-4305-4d82-84b0-032ab615e47d.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-800250652648024285/original/5711a2f6-4305-4d82-84b0-032ab615e47d.jpeg',
    //     },
    //         label: 'Alojamiento en Melgar',
    //         text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:15 ,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"      
    //     },
    //     {img:{
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-628239343174073625/original/254d1924-9819-41d3-baef-f517c29d8bbc.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-628239343174073625/original/254d1924-9819-41d3-baef-f517c29d8bbc.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-628239343174073625/original/254d1924-9819-41d3-baef-f517c29d8bbc.jpeg',
    //     },
    //         label: 'Alojamiento en Melgar',
    //         text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:16,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"       
    //     },
    //     {img: {
    //         img1:'https://a0.muscache.com/im/pictures/miso/Hosting-50773133/original/eaf5492e-e776-48c1-a48c-a1ffdeb6e791.jpeg',
    //         img2:'https://a0.muscache.com/im/pictures/miso/Hosting-50773133/original/eaf5492e-e776-48c1-a48c-a1ffdeb6e791.jpeg',
    //         img3:'https://a0.muscache.com/im/pictures/miso/Hosting-50773133/original/eaf5492e-e776-48c1-a48c-a1ffdeb6e791.jpeg',
    //     },
    //     label: 'Alojamiento en Melgar',
    //     text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //         id:17,
    //         type:'Normal',
    //         total:'401', 
    //         pornoche:'42',
    //         puntuacion:'5.0',
    //         ncamas:"11"       
    //     },
    //     {img: {
    //         img1:'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    //         img2:'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    //         img3:'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    //     },
    //     label: 'Alojamiento en Melgar',
    //     text:'Descanso en Melgar, con WIFI-BBQ  y mas',
    //     id:18,
    //     total:'401', 
    //     pornoche:'42',
    //     puntuacion:'5.0',
    //     ncamas:"11"  
    //     },
     
    // ] 


    const {
        open,
        toggle,
        setO
    } = useAct()


    return (  

            <>

            <section className="App-container-joema"  onClick={
                () => {
                    if (open === true) {
                        setO(false)
                    }
                }

            }>
               <section className="container-pad">
                    <HeaderBar/>
                    <Propiedades/>

                                {/* <Suspense fallback={<div>Loading...</div>}>
                        <LazyPropiedades />
                        </Suspense> */}
                        
                    <FooterBar/>
               </section>
            </section>

            </>

    );
}
 
export default UltraUsuario;