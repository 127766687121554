import React, { useReducer } from 'react';
import StepLogicalContext from './steplogicalContext';
import StepLogicalReducer from './steplogicalReducer';

import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';


import {
    
    STEP_COMPLETE_INDIVIDUAL_OPEN,
    STEP_COMPLETE_ACCOUNT_OPEN,
    STEP_COMPLETE_BEN_OPEN,
    STEP_COMPLETE_SIGNATURE_OPEN,
    STEP_COMPLETE_COMUNICATION_OPEN,



    STEP_WAIT_SIGNATURE_OPEN, 
    STEP_HIDE_SIGNATURE_OPEN
} from '../../types'; 



/// vamos a definir a nuestro state

const StepLogicalState = ({children}) => {

    ///inicializar un state 
    const initialState = {
        individual_status:true,
        accoutopen_status:null,
        ben_status:null,
        sign_status:null,
        comunication_status:null,
    }

    // siempre va a utilizar 2 parametros el 
    // el reducer que quieras usar y el state inicial
    const [ state, dispatch ] = useReducer(StepLogicalState,initialState);


    const StateBtn = async datos => {
        
        try {
            const respuesta = await clienteAxios.post('/api/client/getsteps');

            console.log(respuesta)

            
            dispatch({
                type:STEP_COMPLETE_INDIVIDUAL_OPEN,
                payload: respuesta.data
            }) ;

            dispatch({
                type:STEP_COMPLETE_ACCOUNT_OPEN,
                payload: respuesta.data
            }) ;

            dispatch({
                type:STEP_COMPLETE_BEN_OPEN,
                payload: respuesta.data
            }) ;

            dispatch({
                type:STEP_COMPLETE_SIGNATURE_OPEN,
                payload: respuesta.data
            }) ;

            dispatch({
                type:STEP_COMPLETE_COMUNICATION_OPEN,
                payload: respuesta.data
            }) ;
            
        } catch (error) {

            return
            
            // dispatch({
            //     type:LISTAR_MIS_DATOS_ERROR,
            //     payload:error.response.data.message
            // }) ;
            
        }
    }  






    // las funciones 

    // funcion para registrar usuario
    const CompleteSignature = async () => {


        try {
            


            

            dispatch({
                type:STEP_COMPLETE_SIGNATURE_OPEN,
                payload:true,
        })
            // una ves que sea un registro exitoso, vamos a obtener el usuario
            

        } catch (error) {
             

            dispatch({
                type: STEP_COMPLETE_SIGNATURE_OPEN,
                payload:error.response.data.message
            }) 

        } 


        
    }


    const WaitSignature = () => {
        dispatch({
                type:STEP_WAIT_SIGNATURE_OPEN,
                payload:false,
        })
    }

    const HideSignature = () => {
        dispatch({
            type:STEP_HIDE_SIGNATURE_OPEN,
            payload:null,
        })
    }




    return (

        // dentro de la primera llave vamos a pasar el toquen y 
        // si el usuario esta autenticado
        // aqui le estamos pasando los datos que creamos en las funciones
            <StepLogicalContext.Provider
            value={{
                individual_status:state.individual_status,
                accoutopen_status:state.accoutopen_status,
                ben_status:state.ben_status,
                sign_status:state.sign_status,
                comunication_status:state.comunication_status,
                CompleteSignature,
                WaitSignature,
                HideSignature,
                StateBtn
   

            }} 
            
            > 
            {children}

            </StepLogicalContext.Provider>

    )



}

export default StepLogicalState;