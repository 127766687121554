export const MOSTRAR_ALERTA =  'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA =  'OCULTAR_ALERTA';
export const USUARIO_INCORRECTO = 'USUARIO_INCORRECTO';

export const LIMPIAR_ALERTA = 'LIMPIAR_ALERTA';



// AUTH

export const USUARIO_AUTENTICADO = 'USUARIO_AUTENTICADO';
export const USUARIO_AUTENTICADO_ERROR = 'USUARIO_AUTENTICADO_ERROR';


// funcinales
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const CERRAR_SESION = 'CERRAR_SESION'; 

/// apis


// Api // login 1
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';


// api de olvido password 2
export const OLVIDO_PASSWORD = 'OLVIDO_PASSWORD';
export const OLVIDO_PASSWORD_ERROR = 'OLVIDO_PASSWORD_ERROR';


// api registrar usuario 3
export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';


// api listar mis datos 4
export const LISTAR_MIS_DATOS = 'LISTAR_MIS_DATOS';
export const LISTAR_MIS_DATOS_ERROR = 'LISTAR_MIS_DATOS_ERROR';


//api // cambiar password 5
export const CAMBIAR_PASSWORD = 'CAMBIAR_PASSWORD';
export const CAMBIAR_PASSWORD_ERROR = 'CAMBIAR_PASSWORD_ERROR';


// apis // actualizar datos 6 
export const ACTUALIZAR_DATOS = 'ACTUALIZAR_DATOS';
export const ACTUALIZAR_DATOS_ERROR = 'ACTUALIZAR_DATOS_ERRROR';

// api / listado de contratos 7
export const LISTADO_CONTRATOS = 'LISTADO_CONTRATOS';
export const LISTADO_CONTRATOS_ERROR = 'LISTADO_CONTRATOS_ERROR';


export const USER_STATUS = 'USER_STATUS';

export const DEPOSIT_LIST = 'DEPOSIT_LIST';
export const DEPOSIT_LIST_ERROR = 'DEPOSIT_LIST_ERROR';


export const RETIRO_LIST = 'RETIRO_LIST';
export const RETIRO_LIST_ERROR = 'RETIRO_LIST_ERROR';
//// vamos a empezar a hacer funciones




/// api para consultar el saldo del dashboard
export const SALDOS_DASH = 'SALDOS_DASH';
export const SALDOS_DASH_ERROR = 'SALDOS_DASH_ERROR';


/// aqui vamos a colocar los mostradores de banners

export const CONTENIDO_PROYECTOS = 'CONTENIDO_PROYECTOS';
export const BANNER_PROYECTOS = 'BANNER_PROYECTOS';
////*******/////
export const CONTENIDO_INVERSIONES = 'CONTENIDO_INVERSIONES';
export const BANNER_INVERSIONES = 'BANNER_INVERSIONES';
////*******/////
export const CONTENIDO_OFERTAS = 'CONTENIDO_OFERTAS';
export const BANNER_OFERTAS = 'BANNER_OFERTAS';
////*******/////


///// llamar a beneficiarios
export const LISTA_NACIONALES = 'LISTA_NACIONALES';
export const LISTA_NACIONALES_ERROR = 'LISTA_NACIONALES_ERROR';

export const LISTA_INTERNACIONALES = 'LISTA_INTERNACIONALES';
export const LISTA_INTERNACIONALES_ERROR = 'LISTA_INTERNACIONALES_ERROR';

export const LISTA_REMESAS = 'LISTA_REMESAS';
export const LISTA_REMESAS_ERROR = 'LISTA_REMESAS_ERROR';



///// vamos a colocar los tipes para la lista de retiros

export const TODOS_TUS_RETIROS_ERROR = 'TODOS_TUS_RETIROS_ERROR';
export const TODOS_TUS_RETIROS = 'TODOS_TUS_RETIROS'; 
export const CANTIDAD_RETIROS = 'CANTIDAD_RETIROS'; 


//// vamos a hacer la parte de depositos

export const TODOS_TUS_DEPOSITOS_ERROR = 'TODOS_TUS_DEPOSITOS_ERROR';
export const TODOS_TUS_DEPOSITOS = 'TODOS_TUS_DEPOSITOS'; 
export const CANTIDAD_DEPOSITOS = 'CANTIDAD_DEPOSITOS';

///// vamos a cargar los pdf para depositar
export const FILENAME = 'FILENAME';


////// vamos a colocar los types de mis ofertas
export const LISTA_OFERTAS = 'LISTA_OFERTAS';
export const LISTA_OFERENTE = 'LISTA_OFERENTE';


//// aqui vamos a colocar el total de paginas 
export const LISTA_OFERTAS_TOTALES_PAGINAS = 'LISTA_OFERTAS_TOTALES_PAGINAS';
export const LISTA_OFERENTE_TOTALES_PAGINAS = 'LISTA_OFERENTE_TOTALES_PAGINAS';

//////vamos a colocar lostypes de mis inversiones
export const MIS_INVERSIONES_ACTIVAS = 'MIS_INVERSIONES_ACTIVAS';
export const MIS_INVERSIONES_ACTIVAS_TOTALES_PAGINAS = 'MIS_INVERSIONES_ACTIVAS_TOTALES_PAGINAS'

export const MIS_INVERSIONES_VENDIDAS = 'MIS_INVERSIONES_VENDIDAS';
export const MIS_INVERSIONES_VENDIDAS_TOTALES_PAGINAS = 'MIS_INVERSIONES_VENDIDAS_TOTALES_PAGINAS';


export const PROYECTOS_PRIMARIOS = ' PROYECTOS_PRIMARIOS';

export const PROYECTOS_PRIMARIOS_CANTIDAD = ' PROYECTOS_PRIMARIOS_CANTIDAD';
export const PROYECTOS_PRIMARIOS_TOTAL_PAGINA = 'PROYECTOS_PRIMARIOS_TOTAL_PAGINA';
export const PROYECTOS_PRIMARIOS_PAGINA = 'PROYECTOS_PRIMARIOS_PAGINA';
/// aqui vamos a hacer erl type de inversiones activas y inversiones vendidas

export const PROYECTOS_SECUNDARIOS = 'PROYECTOS_SECUNDARIOS';

export const PROYECTOS_SECUNDARIOS_CANTIDAD = 'PROYECTOS_SECUNDARIOS_CANTIDAD';
export const PROYECTOS_SECUNDARIOS_TOTAL_PAGINA = 'PROYECTOS_SECUNDARIOS_TOTAL_PAGINA';
export const PROYECTOS_SECUNDARIOS_PAGINA = 'PROYECTOS_SECUNDARIOS_PAGINA';




export const FILTROS_INVERSIONES = 'FILTRO_INVERSIONES';
export const FILTROS_PROYECTOS = 'FILTROS_PROYECTOS';




//// aqui vamos a colocar los types para el filtro
export const FILTRO_PROYECTOS_PAIS = 'FILTRO_PROYECTOS_PAIS'
export const FILTRO_PROYECTOS_TIPO_OFERTA = 'FILTRO_PROYECTOS_TIPO_OFERTA'
export const FILTRO_PROYECTOS_EMISOR = 'FILTRO_PROYECTOS_EMISOR';

////// AQUI VAMOS A COLOCAR LOS TYPES DE LOS ULTIMOS MOVIMIENTOS, CON SUS FILTROS
export const ULTIMOS_MOVIMIENTOS = 'ULTIMOS_MOVIMIENTOS';

export const ULTIMOS_MOVIMIENTOS_CANTIDAD = 'ULTIMOS_MOVIMIENTOS_CANTIDAD';
export const ULTIMOS_MOVIMIENTOS_PAGE = 'ULTIMOS_MOVIMIENTOS_PAGE';
export const ULTIMOS_MOVIMIENTOS_TOTAL_PAGE = 'ULTIMOS_MOVIMIENTOS_TOTAL_PAGE';

export const ULTIMOS_MOVIMIENTOS_CSV = 'ULTIMOS_MOVIMIENTOS_CSV';
export const ULTIMOS_MOVIMIENTOS_PDF = 'ULTIMOS_MOVIMIENTOS_PDF';


export const DEMO_STATUS = 'DEMO_STATUS';
 



export const VALORTOTAL = "VALORTOTAL"
export const EQUIDADTOTAL = "EQUIDADTOTAL"

export const MARGENLIBRETOTAL = "MARGENLIBRETOTAL"

export const FLOTANTE = "FLOTANTE"
export const GANANCIADIARIA = "GANANCIADIARIA"

export const GANANCIAESPERADA = "GANANCIAESPERADA"
export const FLOTANTEHOY = "FLOTANTEHOY"

export const MARGENLIBREHOY = "MARGENLIBREHOY"

export const CAMBIAOACTUAL = "CAMBIAOACTUAL"

export const COLLAPSE_TRUE = "COLLAPSE_TRUE"        
export const COLLAPSE_FALSE = "COLLAPSE_FALSE"        


export const CARGANDO_PRISMA = "CARGANDO_PRISMA"
export const CARGANDO_PRISMA_X = "CARGANDO_PRISMA_X"

export const CARGANDO_PRISMA_NULL = "CARGANDO_PRISMA_NULL"
export const CARGANDO_PRISMA_X_NULL = "CARGANDO_PRISMA_X_NULL"


////////// aqui vamos a colocar la data del Faq //////////////////
////////// aqui vamos a colocar la data del Faq //////////////////
////////// aqui vamos a colocar la data del Faq //////////////////

export const FAQ_DATA = "FAQ_DATA"


export const GALLERY_ADU_CLIENT = "GALLERY_ADU_CLIENT"
export const LAST_CONTRACT = "LAST_CONTRACT"



///////////////////////// aqui vamos a colocar los nuevos tipeos /////

export const ACCOUNT_OPEN = "ACCOUNT_OPEN"
export const BENDESIG_NATION = "BENDESIG_NATION"
export const ELECTRONIC_COMMUNICATIONS = "ELECTRONIC_COMMUNICATIONS"
export const INDIVIDUAL_FORM = "INDIVIDUAL_FORM"
export const SIGNATURE_CARD = "SIGNATURE_CARD"



/////////// qui vamos a colocar los Types de los Wizard //////




//// INDIVIDUAL CLIENT FORM ///
export const STEP_1_INDIVIDUAL_OPEN = "STEP_1_INDIVIDUAL_OPEN"
export const STEP_2_INDIVIDUAL_OPEN = "STEP_2_INDIVIDUAL_OPEN"
export const STEP_3_INDIVIDUAL_OPEN = "STEP_3_INDIVIDUAL_OPEN"
export const STEP_4_INDIVIDUAL_OPEN = "STEP_4_INDIVIDUAL_OPEN"
export const STEP_5_INDIVIDUAL_OPEN = "STEP_5_INDIVIDUAL_OPEN"
export const STEP_6_INDIVIDUAL_OPEN = "STEP_6_INDIVIDUAL_OPEN"
export const STEP_7_INDIVIDUAL_OPEN = "STEP_7_INDIVIDUAL_OPEN"
export const STEP_8_INDIVIDUAL_OPEN = "STEP_8_INDIVIDUAL_OPEN"
export const STEP_9_INDIVIDUAL_OPEN = "STEP_9_INDIVIDUAL_OPEN"
export const STEP_10_INDIVIDUAL_OPEN = "STEP_10_INDIVIDUAL_OPEN"
export const STEP_11_INDIVIDUAL_OPEN = "STEP_11_INDIVIDUAL_OPEN"
export const STEP_12_INDIVIDUAL_OPEN = "STEP_12_INDIVIDUAL_OPEN"
export const STEP_13_INDIVIDUAL_OPEN = "STEP_13_INDIVIDUAL_OPEN"
export const STEP_14_INDIVIDUAL_OPEN = "STEP_14_INDIVIDUAL_OPEN"
///******////
export const STEP_COMPLETE_INDIVIDUAL_OPEN  = "STEP_COMPLETE_INDIVIDUAL_OPEN"
export const STEP_WAIT_INDIVIDUAL_OPEN  = "STEP_WAIT_INDIVIDUAL_OPEN"
export const STEP_HIDE_INDIVIDUAL_OPEN  = "STEP_HIDE_INDIVIDUAL_OPEN"
//// INDIVIDUAL CLIENT FORM ///


/// ACOUNT OPEN ///
export const STEP_1_ACCOUNT_OPEN = "STEP_1_ACCOUNT_OPEN"
export const STEP_2_ACCOUNT_OPEN = "STEP_2_ACCOUNT_OPEN"
export const STEP_3_ACCOUNT_OPEN = "STEP_3_ACCOUNT_OPEN"
export const STEP_4_ACCOUNT_OPEN = "STEP_4_ACCOUNT_OPEN"
export const STEP_5_ACCOUNT_OPEN = "STEP_5_ACCOUNT_OPEN"
export const STEP_6_ACCOUNT_OPEN = "STEP_6_ACCOUNT_OPEN"
export const STEP_7_ACCOUNT_OPEN = "STEP_7_ACCOUNT_OPEN"
export const STEP_8_ACCOUNT_OPEN = "STEP_8_ACCOUNT_OPEN"
export const STEP_9_ACCOUNT_OPEN = "STEP_9_ACCOUNT_OPEN"
///******////
export const STEP_COMPLETE_ACCOUNT_OPEN  = "STEP_COMPLETE_ACCOUNT_OPEN"
export const STEP_WAIT_ACCOUNT_OPEN  = "STEP_WAIT_ACCOUNT_OPEN"
export const STEP_HIDE_ACCOUNT_OPEN  = "STEP_HIDE_ACCOUNT_OPEN"
/// ACOUNT OPEN ///


/// BENEFICIARIES DESIGNATION FORM ////

export const STEP_1_BEN_OPEN = "STEP_1_BEN_OPEN"
export const STEP_2_BEN_OPEN = "STEP_2_BEN_OPEN"
export const STEP_3_BEN_OPEN = "STEP_3_BEN_OPEN"
///******////
export const STEP_COMPLETE_BEN_OPEN  = "STEP_COMPLETE_BEN_OPEN"
export const STEP_WAIT_BEN_OPEN  = "STEP_WAIT_BEN_OPEN"
export const STEP_HIDE_BEN_OPEN  = "STEP_HIDE_BEN_OPEN"

/// BENEFICIARIES DESIGNATION FORM ////



//// SIGNATURE CARD ////

export const STEP_1_SIGNATURE_OPEN = "STEP_1_SIGNATURE_OPEN"
///******////
export const STEP_COMPLETE_SIGNATURE_OPEN = "STEP_COMPLETE_SIGNATURE_OPEN"
export const STEP_WAIT_SIGNATURE_OPEN = "STEP_WAIT_SIGNATURE_OPEN"
export const STEP_HIDE_SIGNATURE_OPEN = "STEP_HIDE_SIGNATURE_OPEN"

//// SIGNATURE CARD ////

//// COMUNICATION ////

export const STEP_1_COMUNICATION_OPEN = "STEP_1_COMUNICATION_OPEN "
///******////
export const STEP_COMPLETE_COMUNICATION_OPEN = "STEP_COMPLETE_COMUNICATION_OPEN"
export const STEP_WAIT_COMUNICATION_OPEN = "STEP_WAIT_COMUNICATION_OPEN"
export const STEP_HIDE_COMUNICATION_OPEN = "STEP_HIDE_COMUNICATION_OPEN"

//// COMUNICATION ////



 



///////////////////////////////////




