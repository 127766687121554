import React, {useReducer} from 'react'
import MenuContext from './menuContext'
import MenuReducer from './menuReducer'

import {
    
    ACCOUNT_OPEN,
    BENDESIG_NATION,
    ELECTRONIC_COMMUNICATIONS,
    INDIVIDUAL_FORM,
    SIGNATURE_CARD

} from '../../types/index'




const MenuState = ({children}) => {    
    const initialState = {
       individual:false,
       accopening:false,
       ben:false,
       signature:false,
       electronical:false,
   }
    // siempre va a utilizar 2 parametros el 
    // el reducer que quieras usar y el state inicial
    const [ state, dispatch ] = useReducer(MenuReducer,initialState);
    const IndiOpen = () => {
        dispatch({
                type:INDIVIDUAL_FORM,
                payload:true
        })
    }



    const AccOpen = () => {
        dispatch({
                type:ACCOUNT_OPEN,
                payload:true
        })
    }

    const BenOpen = () => {
        dispatch({
                type:BENDESIG_NATION,
                payload:true
        })
    }

    const EleOpen = () => {
        dispatch({
                type:ELECTRONIC_COMMUNICATIONS,
                payload:true
        })
    }

    const SigOpen = () => {
        dispatch({
                type:SIGNATURE_CARD,
                payload:true
        })
    }


    const CloseWindows = ( )=> {

        dispatch({
            type:SIGNATURE_CARD,
            payload:false })

            dispatch({
            type:ELECTRONIC_COMMUNICATIONS,
            payload:false })

            dispatch({
                type:BENDESIG_NATION,
                payload:false })

            dispatch({
                type:ACCOUNT_OPEN,
                payload:false })

                dispatch({
                    type:INDIVIDUAL_FORM,
                    payload:false })
    }


    

    






    return (

        // dentro de la primera llave vamos a pasar el toquen y 
        // si el usuario esta autenticado
        // aqui le estamos pasando los datos que creamos en las funciones
            <MenuContext.Provider
            value={{
                individual:state.individual,
                accopening:state.accopening,
                ben: state.ben,
                signature:state.signature,
                electronical:state.electronical,
                CloseWindows,
                IndiOpen,
                AccOpen,
                BenOpen,
                EleOpen,
                SigOpen
   

            }} 
            
            > 
            {children}

            </MenuContext.Provider>

    )



}

export default MenuState;





