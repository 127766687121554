import React, { useContext } from 'react'

import DataRequestContext from '../context/data/DataRequestContext'

const useData = () => {
    return useContext(DataRequestContext)
}


export default useData;
