import React, { useState, useEffect } from 'react'
import useAct from '../../hooks/useAct';
import key from '../../imagenes/modalicon/key.svg'
import pets from '../../imagenes/modalicon/pets.svg'
import auto from '../../imagenes/modalicon/auto.svg'
import chat from '../../imagenes/modalicon/chat.svg'
import wifi from '../../imagenes/modalicon/wifi.svg'
import vista from '../../imagenes/modalicon/vista.svg'
import FooterBar from './FooterBar';
import img1x from '../../imagenes/iconosacc/datos.svg'
import img2x from '../../imagenes/iconosacc/inicio.svg'
import img3x from '../../imagenes/iconosacc/x3.svg'
import img4x from '../../imagenes/iconosacc/x4.svg'
import img5x from '../../imagenes/iconosacc/x5.svg'
import img6x from '../../imagenes/iconosacc/x6.svg'
import img7x from '../../imagenes/iconosacc/x7.svg'
import img8x from '../../imagenes/iconosacc/x8.svg'
import img9x from '../../imagenes/iconosacc/x9.svg'
import img10x from '../../imagenes/iconosacc/x10.svg'
import allmensaje from '../../imagenes/iconos/allmensaje.svg'
import x from '../../imagenes/iconos/x.svg'

const WindowIdioma = () => {

    const datados = [
        {   
            id:220,
            idioma:'Español',
            pais:'México',
            state:'disponible',
        },
        {   
            id:221,
            idioma:'Español',
            pais:'Latinoaméroca',
            state:'disponible',
        },
        
        {   
            id:222,
            idioma:'English',
            pais:'United States',
            state:'disponible',
        },
        
        {   
            id:223,
            idioma:'English',
            pais:'United Kingdom',
            state:'disponible',
        },
    ]

    const data = [
        {   
            id:0,
            idioma:'Español',
            pais:'Panamá',
            state:'disponible',
        },
        {   
            id:1,
            idioma:'Azərbaycan dili',
            pais:'Azərbaycan',
            state:'disponible',
        },
        
        {   
            id:2,
            idioma:'Bahasa Indonesia',
            pais:'Indonesia',
            state:'disponible',
        },
        
        {   
            id:3,
            idioma:'Bosanski',
            pais:'Bosna i Hercegovina',
            state:'disponible',
        },
        
        {   
            id:4,
            idioma:'Català',
            pais:'Espanya',
            state:'disponible',
        },
        {   
            id:5,
            idioma:'Čeština',
            pais:'Česká republika',
            state:'disponible',
        },
        {   
            id:6,
            idioma:'Crnogorski',
            pais:'Crna Gora',
            state:'disponible',
        },
        {   
            id:7,
            idioma:'Dansk',
            pais:'Danmark',
            state:'disponible',
        },
        {   
            id:8,
            idioma:'Deutsch',
            pais:'Deutschland',
            state:'disponible',
        },
        {   
            id:9,
            idioma:'Deutsch',
            pais:'Österreich',
            state:'disponible',
        }, {   
            id:10,
            idioma:'Deutsch',
            pais:'Schweiz',
            state:'disponible',
        },
        {   
            id:11,
            idioma:'Eesti',
            pais:'Eesti',
            state:'disponible',
        },
        
        {   
            id:12,
            idioma:'English',
            pais:'Australia',
            state:'disponible',
        },
        
        {   
            id:13,
            idioma:'English',
            pais:'Canada',
            state:'disponible',
        },
        
        {   
            id:14,
            idioma:'English',
            pais:'Guyana',
            state:'disponible',
        },
        {   
            id:15,
            idioma:'English',
            pais:'India',
            state:'disponible',
        },
        {   
            id:16,
            idioma:'English',
            pais:'Ireland',
            state:'disponible',
        },
        {   
            id:17,
            idioma:'English',
            pais:'New Zealand',
            state:'disponible',
        },
        {   
            id:18,
            idioma:'English',
            pais:'Singapore',
            state:'disponible',
        },
        {   
            id:19,
            idioma:'English',
            pais:'United Arab Emirates',
            state:'disponible',
        }, {   
            id:20,
            idioma:'Español',
            pais:'Argentina',
            state:'disponible',
        },
        {   
            id:21,
            idioma:'Español',
            pais:'Belice',
            state:'disponible',
        },
        
        {   
            id:22,
            idioma:'Español',
            pais:'Bolivia',
            state:'disponible',
        },
        
        {   
            id:23,
            idioma:'Español',
            pais:'Chile',
            state:'disponible',
        },
        
        {   
            id:24,
            idioma:'Español',
            pais:'Colombia',
            state:'disponible',
        },
        {   
            id:25,
            idioma:'Español',
            pais:'Costa Rica',
            state:'disponible',
        },
        {   
            id:26,
            idioma:'Español',
            pais:'Ecuador',
            state:'disponible',
        },
        {   
            id:27,
            idioma:'Español',
            pais:'El Salvador',
            state:'disponible',
        },
        {   
            id:28,
            idioma:'Español',
            pais:'España',
            state:'disponible',
        },
        {   
            id:29,
            idioma:'Español',
            pais:'Estados Unidos',
            state:'disponible',
        }, {   
            id:30,
            idioma:'Español',
            pais:'Guatemala',
            state:'disponible',
        },
        {   
            id:31,
            idioma:'Español',
            pais:'Honduras',
            state:'disponible',
        },
        
        {   
            id:32,
            idioma:'Español',
            pais:'Nicaragua',
            state:'disponible',
        },
        
        {   
            id:33,
            idioma:'Español',
            pais:'Paraguay',
            state:'disponible',
        },
        
        {   
            id:34,
            idioma:'Español',
            pais:'Perú',
            state:'disponible',
        },
        {   
            id:35,
            idioma:'Español',
            pais:'Venezuela',
            state:'disponible',
        },
        {   
            id:36,
            idioma:'Français',
            pais:'Belgique',
            state:'disponible',
        },
        {   
            id:37,
            idioma:'Français',
            pais:'Canada',
            state:'disponible',
        },
        {   
            id:38,
            idioma:'Français',
            pais:'France',
            state:'disponible',
        },
        {   
            id:39,
            idioma:'Français',
            pais:'Suisse',
            state:'disponible',
        }, {   
            id:40,
            idioma:'Gaeilge',
            pais:'Éire',
            state:'disponible',
        },
        {   
            id:41,
            idioma:'Hrvatski',
            pais:'Hrvatska',
            state:'disponible',
        },
        
        {   
            id:42,
            idioma:'isiXhosa',
            pais:'eMzantsi Afrika',
            state:'disponible',
        },
        
        {   
            id:43,
            idioma:'isiZulu',
            pais:'iNingizimu Afrika',
            state:'disponible',
        },
        
        {   
            id:44,
            idioma:'Íslenska',
            pais:'Ísland',
            state:'disponible',
        },
        {   
            id:45,
            idioma:'Italiano',
            pais:'Italia',
            state:'disponible',
        },
        {   
            id:46,
            idioma:'Italiano',
            pais:'Svizzera',
            state:'disponible',
        },
        {   
            id:47,
            idioma:'Kiswahili',
            pais:'Āfrika',
            state:'disponible',
        },
        {   
            id:48,
            idioma:'Latviešu',
            pais:'Latvija',
            state:'disponible',
        },
        {   
            id:49,
            idioma:'Lietuvių',
            pais:'Lietuva',
            state:'disponible',
        }, {   
            id:50,
            idioma:'Magyar',
            pais:'Magyarország',
            state:'disponible',
        },
        {   
            id:51,
            idioma:'Malti',
            pais:'Malta',
            state:'disponible',
        },
        
        {   
            id:52,
            idioma:'Melayu',
            pais:'Malaysia',
            state:'disponible',
        },
        
        {   
            id:53,
            idioma:'Nederlands',
            pais:'België',
            state:'disponible',
        },
        
        {   
            id:54,
            idioma:'Nederlands',
            pais:'Nederland',
            state:'disponible',
        },
        {   
            id:55,
            idioma:'Norsk',
            pais:'Norge',
            state:'disponible',
        },
        {   
            id:56,
            idioma:'Polski',
            pais:'Polska',
            state:'disponible',
        },
        {   
            id:57,
            idioma:'Português',
            pais:'Brasil',
            state:'disponible',
        },
        {   
            id:58,
            idioma:'Português',
            pais:'Portugal',
            state:'disponible',
        },
        {   
            id:59,
            idioma:'Română',
            pais:'România',
            state:'disponible',
        }, {   
            id:60,
            idioma:'Shqip',
            pais:'Shqipëri',
            state:'disponible',
        },
        {   
            id:61,
            idioma:'Slovenčina',
            pais:'Slovensko',
            state:'disponible',
        },
        
        {   
            id:62,
            idioma:'Slovenščina',
            pais:'Slovenija',
            state:'disponible',
        },
        
        {   
            id:63,
            idioma:'Srpski',
            pais:'Srbija',
            state:'disponible',
        },
        
        {   
            id:64,
            idioma:'Suomi',
            pais:'Suomi',
            state:'disponible',
        },
        {   
            id:65,
            idioma:'Svenska',
            pais:'Sverige',
            state:'disponible',
        },
        {   
            id:66,
            idioma:'Tagalog',
            pais:'Pilipinas',
            state:'disponible',
        },
        {   
            id:67,
            idioma:'Tiếng Việt',
            pais:'Việt Nam',
            state:'disponible',
        },
        {   
            id:68,
            idioma:'Türkçe',
            pais:'Türkiye',
            state:'disponible',
        },
        {   
            id:69,
            idioma:'Ελληνικά',
            pais:'Ελλάδα',
            state:'disponible',
        }, {   
            id:70,
            idioma:'Български',
            pais:'България',
            state:'disponible',
        },
        {   
            id:71,
            idioma:'Македонски',
            pais:'Северна Македонија',
            state:'disponible',
        },
        
        {   
            id:72,
            idioma:'Русский',
            pais:'Россия',
            state:'disponible',
        },
        
        {   
            id:73,
            idioma:'Українська',
            pais:'Україна',
            state:'disponible',
        },
        
        {   
            id:74,
            idioma:'ქართული',
            pais:'საქართველო',
            state:'disponible',
        },
        {   
            id:75,
            idioma:'Հայերեն',
            pais:'Հայաստան',
            state:'disponible',
        },
        {   
            id:76,
            idioma:'עברית',
            pais:'ישראל',
            state:'disponible',
        },
        {   
            id:77,
            idioma:'العربية',
            pais:'العالم',
            state:'disponible',
        },
        {   
            id:78,
            idioma:'हिन्दी',
            pais:'भारत',
            state:'disponible',
        },
        {   
            id:79,
            idioma:'ไทย',
            pais:'ประเทศไทย',
            state:'disponible',
        }, {   
            id:80,
            idioma:'한국어',
            pais:'대한민국',
            state:'disponible',
        },
        {   
            id:81,
            idioma:'日本語',
            pais:'日本',
            state:'disponible',
        },
        
        {   
            id:82,
            idioma:'简体中文',
            pais:'美国',
            state:'disponible',
        },
        
        {   
            id:83,
            idioma:'繁體中文',
            pais:'美國',
            state:'disponible',
        },
        
        {   
            id:84,
            idioma:'简体中文',
            pais:'中国',
            state:'disponible',
        },
        {   
            id:85,
            idioma:'繁體中文',
            pais:'香港',
            state:'disponible',
        },
        {   
            id:86,
            idioma:'繁體中文',
            pais:'台灣',
            state:'disponible',
        }
    

    ]

    const cuenta = [
            {
                id:1,
                T:'Datos personales',
                D:'Proporciona tus datos personales e indícanos cómo podemos ponernos en contacto contigo',
                icon:img1x,
            },
            {   id:2,
                T:'Inicio de sesión y seguridad',
                D:'Actualiza la contraseña y protege tu cuenta',
                icon:img2x,

            },
            {   id:3,
                T:'Pagos y cobros',  
                D:'Revisa pagos, cobros, cupones y tarjetas de regalo',
                icon:img3x,

            },
            {   id:4,
                T:'Impuestos',
                D:'Administra la información y los documentos fiscales de los contribuyentes',
                icon:img4x,

            },
            {   id:5,
                T:'Notificaciones',
                D:'Elige las preferencias de notificación y tu forma de contacto',
                icon:img5x,

            },
            {   id:6,
                T:'Privacidad y uso compartido',
                D:'Administra tus datos personales, servicios relacionados y configuración de uso compartido de datos',
                icon:img6x,

            },
            {   id:7,
                T:'Preferencias globales',
                D:'Configura tu idioma, moneda y zona horaria',
                icon:img7x,

            },
            {   id:8,
                T:'Viajes de trabajo',
                D:'Agrega un correo electrónico de empresa para obtener beneficios en los viajes de negocios',
                icon:img8x,

            },
            {   id:9,
                T:'Herramientas para corredores profesionales',
                D:'Obtén herramientas profesionales si administras varias propiedades en Airbnb',
                icon:img9x,

            },
            {   id:10,
                T:'Crédito y cupones por invitaciones',
                D:'Tienes $0 de crédito y cupones por invitaciones. Más información.',
                icon:img10x,

            }
            

    ]

    const {
        win,
        WinAct,
        img1win, setImg1,
        img2win, setImg2,
        img3win, setImg3,
        img4win, setImg4,
        img5win, setImg5,
        winidioma, WinIdioma,
    } = useAct()

    const [profileactive, setProfileAct] = useState(false);
    const [estadoselection, stSelect] = useState(0);
    const StateZona = () => {
        stSelect(0)
    }
    const PriZona = () => {
        stSelect(1)
    }
    const SecZona = () => {
        stSelect(2)
    }
    const TreZona = () => {
        stSelect(3)
    } 

    /////  aqui vamos a coloar el estado del detalles /////

    const [detalles, setDetalle] = useState(true);
    const ActDetalles = () => {
        setDetalle(!detalles)
    }
    useEffect(
        () => {         
            if(detalles === false) {
                SecZona()
            }
            if(detalles === true) {
                TreZona()
            }
                                
        },[detalles]
    )

    const [terminoselect, setTerminoSelect] = useState(
        {   
            id:0,
            idioma:'Español',
            pais:'Panamá',
            state:'disponible',
        }
    ) 
    
    /// aqui estamos colocando el objeto individual /// debe tener su estado inicial

    const [arr, setArr] = useState([]); //// este es el nuevo arreglo donde se van estar agregando todos los objetos

    const agregar = () => { //// esta es la funcinon que se usa para agregar los objetos
      if (terminoselect) {
        setArr([...arr, terminoselect]);
      }
    };

    // console.log(arr)
    // console.log(terminoselect)
  

    const [terminoselectnormal, setTerminoSelectN] = useState([
        {   
            id:0,
            idioma:'Español',
            pais:'Panamá',
            state:'disponible',
        }
    ])

    useEffect(() => {
        agregar();
      }, [terminoselect]);


    return ( 
            <>
            {
                winidioma === true ? 
                <> 
                 <div  className={` windows-selection-01 ${winidioma !== false ? ' op-true adelante ':''}  ${winidioma !== true ? 'animation-open-option-close  op-none':''}`}>
                <section className="world-box" >

                    <section className={`closet-world`} 
                        onClick={ () => {
                            WinIdioma();
                            stSelect(0);
                            setArr([])
                        }
                        }
                        >
                        

                    </section>
                    

                    <section className={`world-select bg-ground`}> 

                        <div className={`padding-world`}>
                            <img 
                             onClick={ () => {
                                WinIdioma();
                                stSelect(0); 
                                setArr([])
                                
                            }}
                            className={`img-fix-x cursor-pointer`} src={x} />
                        </div>

                        <section className={`box-world-world-body padding-world`}>
                            <div className={`boddy-joema-world`}>
                                        <h2>Idiomas y regiones sugeridos</h2>

                                        <div className={`mapping-world-content-group`}>
                                        {
                                        datados.map((s) => {
                                            return(
                                                <div key={s.id} className={`mapping-world-content-individual 
                                                ${s.id === terminoselect.id ? 'map-active' :''}
                                                `} 
                                                    onClick={ 
                                                            () => {
                                                                setTerminoSelect(s)    }
                                                    }
                                                >
                                                <h4>{s.idioma}</h4>
                                                <h5>{s.pais}</h5>
                                            </div>
                                            )
                                        })
                                    }
                                    </div>
                                        <h2>Elige un idioma y una región</h2>
                                    <div className={`mapping-world-content-group`}>
                                        {
                                        data.map((s) => {

                                           
                                           
                                         
                                            return(



                                                <div key={s.id} className={`
                                                    mapping-world-content-individual
                                                 ${s.id === terminoselect.id ? 'map-active' :'map-noactive'}
                                                    
                                                
                                                `}   
                                                onClick={ 
                                                    () => {
                                                    
                                                        setTerminoSelect(s)
                                                    }
                                                    }
                                                
                                                >
                                                <h4>{s.idioma}</h4>
                                                <h5>{s.pais}</h5>
                                            </div>
                                            )
                                        })
                                    }


                                    </div>

                            </div>
                        </section>

                    </section>

                    


                    

                    

                </section>
            </div>
                
                </> : null
            }

           
            </>


     );
}
 
export default WindowIdioma;