import img1x from '../imagenes/mapimages/PanamaOeste.png'
import img2x from '../imagenes/mapimages/Bocas-del-Toro.png'
import img3x from '../imagenes/mapimages/Chiriqui.png'
import img4x from '../imagenes/mapimages/Cocle.png'
import img5x from '../imagenes/mapimages/Colon.png'
import img6x from '../imagenes/mapimages/Darien.png'
import img7x from '../imagenes/mapimages/GunaYala.png'
import img8x from '../imagenes/mapimages/Herrera.png'
import img9x from '../imagenes/mapimages/LosSantos.png'
import img10x from '../imagenes/mapimages/Ngabe-Bugle.png'
import img11x from '../imagenes/mapimages/Veraguas.png'
import img12x from '../imagenes/mapimages/PanamaCiudad.png'



const PRO  = [
    {   id:0,
        map:img2x,
        provincia:'Bocas del Toro',
        distritos:[
            {  
                id:0,
                distrito:'Almirante',
                cabecera:'Almirante',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Almirante',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Bajo Culubre',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Barriada Guaymí',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Barrio Francés',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Cauchero',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Ceiba',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Miraflores',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Nance de Riscó',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Valle de Aguas Arriba',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Valle de Riscó',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Bocas del Toro',
                cabecera:'Bocas del Toro',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bastimentos',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Bocas del Toro',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Bocas del Drago',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Punta Laurel',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Tierra Oscura',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'San Cristóbal',
                                    }
                                ] 
            },
            {  
                id:2,
                distrito:'Changuinola',
                cabecera:'Changuinola',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Barriada 4 de Abril',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Barranco Adentro',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Changuinola',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Cochigro',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Empalme',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'El Silencio',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Finca 4',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Finca 6',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Finca 12',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Finca 30',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'Finca 51',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'Finca 60',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Finca 66',
                                    },
                                    {
                                        id:13,
                                        corregimiento:'Guabito',
                                    },
                                    {
                                        id:14,
                                        corregimiento:'La Gloria',
                                    },
                                    {
                                        id:15,
                                        corregimiento:'La Mesa',
                                    },
                                    {
                                        id:16,
                                        corregimiento:'Las Delicias',
                                    },
                                    {
                                        id:17,
                                        corregimiento:'Las Tablas',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'Chiriquí Grande',
                cabecera:'Chiriquí Grande',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bajo Cedro',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Chiriquí Grande',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Miramar',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Punta Peña',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Punta Robalo',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Rambala',
                                    }
                                ] 
            }, 
        ]
    },
    {   id:1,
        provincia:'Chiriquí',
        map:img3x,
        distritos:[
            {  
                id:0,
                distrito:'Alanje',
                cabecera:'Alanje',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Alanje',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Divalá',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Canta Gallo',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'El Tejar',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Guarumal',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Nuevo México',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Querévalo',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Palo Grande',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Santo Tomás',
                                    },
                                    
                                ] 
            },
            {  
                id:1,
                distrito:'Barú',
                cabecera:'Puerto Armuelles',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Baco',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Limones',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Progreso',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Puerto Armuelles',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Rodolfo Aguilar Delgado',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'El Palmar',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Manaca',
                                    }
                                ] 
            },
            {  
                id:2,
                distrito:'Boquerón',
                cabecera:'Boquerón',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bágala',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Boquerón',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Cordillera',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Guabal',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Guayabal',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Paraíso',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Pedregal',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Tijeras',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'Boquete',
                cabecera:'Bajo Boquete',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Alto Boquete',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Bajo Boquete',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Caldera',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Jaramillo',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Los Naranjos',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Palmira',
                                    }
                                ] 
            },
            {  
                id:4,
                distrito:'Bugaba',
                cabecera:'La Concepción',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Aserrío de Gariché',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Bugaba',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Bongo',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Gómez',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'La Concepción',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'La Estrella',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'San Andrés',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Santa Marta',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Santa Rosa',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Santo Domingo',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Sortová',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Solano',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'San Isidro',
                                    },
                                    
                                ] 
            },
            {  
                id:5,
                distrito:'David',
                cabecera:'David',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bijagual',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Cochea',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Chiriquí',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Guacá',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Las Lomas',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Pedregal',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'San Carlos',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'David',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'David Este',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'David Sur',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'San Pablo Nuevo',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'San Pablo Viejo',
                                    }
                                ] 
            },
            {  
                id:6,
                distrito:'Dolega',
                cabecera:'Dolega',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Dolega',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Dos Ríos',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Los Algarrobos',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Los Anastacios',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Potrerillos',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Potrerillos Abajo',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Rovira',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Tinajas',
                                    }
                                ] 
            },
            {  
                id:7,
                distrito:'Gualaca',
                cabecera:'Gualaca',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Gualaca',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Hornito',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Los Ángeles',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Paja de Sombrero',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Rincón',
                                    }
                                ] 
            },{  
                id:8,
                distrito:'Remedios',
                cabecera:'Remedios',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'El Nancito',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Porvenir',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Puerto',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Remedios',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Santa Lucía',
                                    }
                                ] 
            },
            {  
                id:9,
                distrito:'Renacimiento',
                cabecera:'Río Sereno',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Breñón',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Cañas Gordas',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Dominical',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Monte Lirio',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Plaza de Caisán',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Río Sereno',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Santa Cruz',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Santa Clara',
                                    }
                                ] 
            },
            {  
                id:10,
                distrito:'San Félix',
                cabecera:'Las Lajas',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Las Lajas',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Lajas Adentro',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Juay',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'San Félix',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Santa Cruz',
                                    }
                                ] 
            },
            {  
                id:11,
                distrito:'San Lorenzo',
                cabecera:'Horconcitos',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Boca Chica',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Boca del Monte',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Horconcitos',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'San Juan',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'San Lorenzo',
                                    }
                                ] 
            },
            {  
                id:12,
                distrito:'Tierras Altas',
                cabecera:'Volcán',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Volcán',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Cerro Punta',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Cuesta de Piedra',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Nueva California',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Paso Ancho',
                                    }
                                ] 
            },
            {  
                id:13,
                distrito:'Tolé',
                cabecera:'Tolé',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bella Vista',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Cerro Viejo',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Cristo',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Justo Fidel Palacios',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Lajas de Tolé',
                                    }
                                    ,
                                    {
                                        id:5,
                                        corregimiento:'Potrero de Caña',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Quebrada de Piedra',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Tolé',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Veladero',
                                    }
                                ] 
            },    
        ]
    },
    {   id:2,
        map:img4x,
        provincia:'Coclé',
        distritos:[

            {  
                id:0,
                distrito:'Aguadulce',
                cabecera:'Aguadulce',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Aguadulce',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Barrios Unidos',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Cristo',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'El Roble',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Hato de San Juan de Dios',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Pocrí',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Pueblos Unidos',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Virgen del Carmen',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Antón',
                cabecera:'Antón',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Antón',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Caballero',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Cabuya',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'El Chirú',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Retiro',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'El Valle',
                                    }, {
                                        id:7,
                                        corregimiento:'Juan Díaz',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Río Hato',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'San Juan de Dios',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'Santa Rita',
                                    },
                                    
                                ] 
            },
            {  
                id:2,
                distrito:'La Pintada',
                cabecera:'La Pintada',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'El Harino',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Potrero',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'La Pintada',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Las Lomas',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Llano Grande',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Piedras Gordas',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Llano Norte',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'Natá',
                cabecera:'Natá de los Caballeros',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Capellanía',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Caño',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Guzmán',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Las Huacas',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Natá',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Toza',
                                    }, {
                                        id:7,
                                        corregimiento:'Villarreal',
                                    }
                                ] 
            },
            {  
                id:4,
                distrito:'Olá',
                cabecera:'Olá',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'El Copé',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Palmar',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Picacho',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'La Pava',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Olá',
                                    }
                                ] 
            }, {  
                id:5,
                distrito:'Penonomé',
                cabecera:'Penonomé',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cañaveral',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Coclé',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Chiguirí Arriba',
                                    },{
                                        id:3,
                                        corregimiento:'El Coco',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Pajonal',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Penonomé',
                                    },{
                                        id:6,
                                        corregimiento:'Río Grande',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Río Indio',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Toabré',
                                    },{
                                        id:9,
                                        corregimiento:'Tulú',
                                    },{
                                        id:10,
                                        corregimiento:'Boca de Tucué',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'Candelario Ovalle',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Las Minas',
                                    },{
                                        id:13,
                                        corregimiento:'Riecito',
                                    },
                                    {
                                        id:14,
                                        corregimiento:'San Miguel',
                                    },
                                    {
                                        id:15,
                                        corregimiento:'Victoriano Lorenzo',
                                    }
                                ] 
            },
            ]
    },
    {   id:3,
        map:img5x,
        provincia:'Colón',
        distritos:[

            {  
                id:0,
                distrito:'Colón',
                cabecera:'Colón',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Barrio Norte',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Barrio Sur',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Buena Vista',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Cativá',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Ciricito',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Cristóbal',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Cristóbal Este',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Escobal',
                                    },
                                     {
                                        id:8,
                                        corregimiento:'Limón',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Nueva Providencia',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'Puerto Pilón',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'Sabanitas',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Salamanca',
                                    },
                                    {
                                        id:13,
                                        corregimiento:'San Juan',
                                    },
                                    {
                                        id:14,
                                        corregimiento:'Santa Rosa',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Chagres',
                cabecera:'Nuevo Chagres',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Achiote',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Guabo',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'La Encantada',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Nuevo Chagres',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Palmas Bellas',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Piña',
                                    }, {
                                        id:7,
                                        corregimiento:'Salud',
                                    }
                                ] 
            },
            {  
                id:2,
                distrito:'Donoso',
                cabecera:'Miguel de la Borda',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Coclé del Norte',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Guásimo',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Gobea',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Miguel de la Borda',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Río Indio',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'Portobelo',
                cabecera:'Portobelo',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cacique',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Garrote',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Isla Grande',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'María Chiquita',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Portobelo',
                                    }
                                ] 
            },
            {  
                id:4,
                distrito:'Santa Isabel',
                cabecera:'Palenque',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cuango',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Miramar',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Nombre de Dios',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Palenque',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Palmira',
                                    }, {
                                        id:5,
                                        corregimiento:'Playa Chiquita',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Santa Isabel',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Viento Frío',
                                    }
                                ] 
            }, {  
                id:5,
                distrito:'Omar Torrijos Herrera',
                cabecera:'Coclesito',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'San José del General',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'San Juan de Turbe',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Nueva Esperanza',
                                    }
                                ] 
            },
            ]
    },
    {   id:4,
        map:img6x,
        provincia:'Darién',
        distritos:[

            {  
                id:0,
                distrito:'Chepigana',
                cabecera:'La Palma',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Camogantí',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Chepigana',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Garachiné',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Jaqué',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'La Palma',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Puerto Piña',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Sambú',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Setegantí',
                                    },
                                     {
                                        id:8,
                                        corregimiento:'Taimatí',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Tucutí',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Pinogana',
                cabecera:'El Real de Santa María',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Boca de Cupe',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Real de Santa ',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'María',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Metetí',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Paya',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Pinogana',
                                    }, {
                                        id:7,
                                        corregimiento:'Púcuro',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Yape',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Yaviza',
                                    }, {
                                        id:10,
                                        corregimiento:'Wargandí',
                                    },
                                ] 
            },
            {  
                id:2,
                distrito:'Santa Fe',
                cabecera:'Santa Fe',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Agua Fría',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Cucunatí',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Río Congo',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Río Congo Arriba',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Río Iglesias',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Santa Fe',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Zapallal',
                                    },
                                    
                                ] 
            }
            ]
    },
    {   id:5,
        map:img8x,
        provincia:'Herrera',
        distritos:[

            {  
                id:0,
                distrito:'Chitré',
                cabecera:'Chitré',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Chitré',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'La Arena',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Llano Bonito',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'San Juan Bautista',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Monagrillo',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Las Minas',
                cabecera:'Las Minas',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Chepo',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Chumical',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Toro',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Las Minas',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Leones',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Quebrada del Rosario',
                                    }, {
                                        id:7,
                                        corregimiento:'Quebrada El Ciprián',
                                    }
                                ] 
            },
            {  
                id:2,
                distrito:'Los Pozos',
                cabecera:'Los Pozos',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'El Capurí',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Calabacito',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Cedro',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'La Arena',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'La Pitaloza',
                                    },{
                                        id:5,
                                        corregimiento:'Las Llanas',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Los Cerritos',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Los Cerros de Paja',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Los Pozos',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'Ocú',
                cabecera:'Ocú',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cerro Largo',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Tijera',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Entradero del Castillo',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Los Llanos',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Llano Grande',
                                    }, {
                                        id:5,
                                        corregimiento:'Menchaca',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Peñas Chatas',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Ocú',
                                    }
                                ] 
            },
            {  
                id:4,
                distrito:'Parita',
                cabecera:'Parita',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cabuya',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Los Castillos',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Llano de la Cruz',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'París',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Parita',
                                    }, {
                                        id:5,
                                        corregimiento:'Portobelillo',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Potuga',
                                    }
                                ] 
            }, {  
                id:5,
                distrito:'Pesé',
                cabecera:'Pesé',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'El Barrero',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Pedregoso',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Ciruelo',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Pájaro',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Las Cabras',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Pesé',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Rincón Hondo',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Sabanagrande',
                                    }
                                ] 
            },
            {  
                id:6,
                distrito:'Santa María',
                cabecera:'Santa María',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Chupampa',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Rincón',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Limón',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Los Canelos',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Santa María',
                                    },
                                ] 
            },
            ]
    },
    {   id:6,
        map:img9x,
        provincia:'Santos',
        distritos:[

            {  
                id:0,
                distrito:'Guararé',
                cabecera:'Guararé',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'El Espinal',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Hato',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Macano',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Guararé',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Guararé Arriba',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'La Enea',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'La Pasera',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Las Trancas',
                                    },
                                     {
                                        id:8,
                                        corregimiento:'Llano Abajo',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Perales',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Las Tablas',
                cabecera:'Las Tablas',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bajo Corral',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Bayano',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Carate',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'El Cocal',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Manantial',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'El Muñoz',
                                    }, {
                                        id:6,
                                        corregimiento:'El Pedregoso',
                                    },{
                                        id:7,
                                        corregimiento:'El Sesteadero',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'La Laja',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'La Miel',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'La Palma',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'La Tiza',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Las Palmitas',
                                    }, {
                                        id:13,
                                        corregimiento:'Las Tablas Abajo',
                                    },
                                    {
                                        id:14,
                                        corregimiento:'Nuario',
                                    },
                                    {
                                        id:15,
                                        corregimiento:'Palmira',
                                    },
                                    {
                                        id:16,
                                        corregimiento:'Peña Blanca',
                                    },
                                    {
                                        id:17,
                                        corregimiento:'Río Hondo',
                                    },
                                    {
                                        id:18,
                                        corregimiento:'San José',
                                    },
                                    {
                                        id:19,
                                        corregimiento:'San Miguel',
                                    }, {
                                        id:20,
                                        corregimiento:'Las Tablas',
                                    },{
                                        id:21,
                                        corregimiento:'Santo Domingo',
                                    },
                                    {
                                        id:22,
                                        corregimiento:'Valle Rico',
                                    },
                                    {
                                        id:23,
                                        corregimiento:'Vallerriquito',
                                    }
                                ] 
            },
            {  
                id:2,
                distrito:'Los Santos',
                cabecera:'La Villa de Los Santos',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'El Ejido',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Guásimo',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'La Colorada',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'La Espigadilla',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'La Villa de Los Santos',
                                    },{
                                        id:5,
                                        corregimiento:'Las Cruces',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Las Guabas',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Los Ángeles',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Los Olivos',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Llano Largo',
                                    },{
                                        id:10,
                                        corregimiento:'Sabanagrande',
                                    },{
                                        id:11,
                                        corregimiento:'Santa Ana',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Tres Quebradas',
                                    },
                                    {
                                        id:13,
                                        corregimiento:'Villa Lourdes',
                                    },{
                                        id:14,
                                        corregimiento:'Agua Buena',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'Macaracas',
                cabecera:'Macaracas',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bahía Honda',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Bajos de Güera',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Corozal',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Chupá',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Cedro',
                                    },{
                                        id:5,
                                        corregimiento:'Espino Amarillo',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'La Mesa',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Las Palmas',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Llano de Piedras',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Macaracas',
                                    },{
                                        id:10,
                                        corregimiento:'Mogollón',
                                    },
                                ] 
            },
            {  
                id:4,
                distrito:'Pedasí',
                cabecera:'Pedasí',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Los Asientos',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Mariabé',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Oria Arriba',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Pedasí',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Purio',
                                    }
                                ] 
            }, {  
                id:5,
                distrito:'Pocrí',
                cabecera:'Pocrí',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'El Cañafístulo',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Lajamina',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Paraíso',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Paritilla',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Pocrí',
                                    }
                                ] 
            },
            {  
                id:6,
                distrito:'Tonosí',
                cabecera:'Tonosí',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Altos de Güera',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Cambutal',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Cañas',
                                    }, {
                                        id:3,
                                        corregimiento:'El Bebedero',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Cacao',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'El Cortezo',
                                    },  {
                                        id:6,
                                        corregimiento:'Flores',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Guánico',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Isla de Cañas',
                                    },{
                                        id:9,
                                        corregimiento:'La Tronosa',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'Tonosí',
                                    },
                                ] 
            },
            ]
    },
    {   id:7,
        map:img12x,
        provincia:'Panamá',
        distritos:[

            {  
                id:0,
                distrito:'Balboa',
                cabecera:'San Miguel',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'La Ensenada',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'La Esmeralda',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'La Guinea',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Pedro González',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Saboga',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'San Miguel',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Chepo',
                cabecera:'Chepo',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cañita',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Chepillo',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Llano',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Las Margaritas',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Chepo',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Santa Cruz de Chinina',
                                    }, {
                                        id:7,
                                        corregimiento:'Tortí',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Madugandí',
                                    },
                                ] 
            },
            {  
                id:2,
                distrito:'Chimán',
                cabecera:'Chimán',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Brujas',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Chimán',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Gonzalo Vásquez',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Pásiga',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Unión Santeña',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'Panamá',
                cabecera:'Panamá',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'24 de Diciembre',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Alcalde Díaz',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Ancón',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Betania',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Bella Vista',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Calidonia',
                                    }, {
                                        id:7,
                                        corregimiento:'Caimitillo',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Chilibre',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Curundú',
                                    }, {
                                        id:10,
                                        corregimiento:'Don Bosco',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'El Chorrillo',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Ernesto Córdoba Campos',
                                    },
                                    {
                                        id:13,
                                        corregimiento:'Juan Díaz',
                                    },
                                    {
                                        id:14,
                                        corregimiento:'Las Cumbres',
                                    }, {
                                        id:15,
                                        corregimiento:'Las Garzas',
                                    },
                                    {
                                        id:16,
                                        corregimiento:'Las Mañanitas',
                                    },
                                    {
                                        id:17,
                                        corregimiento:'Pacora',
                                    },
                                    {
                                        id:18,
                                        corregimiento:'Parque Lefevre',
                                    },
                                    {
                                        id:19,
                                        corregimiento:'Pedregal',
                                    },
                                    {
                                        id:20,
                                        corregimiento:'Pueblo Nuevo',
                                    },
                                    {
                                        id:21,
                                        corregimiento:'Río Abajo',
                                    },
                                    {
                                        id:22,
                                        corregimiento:'San Felipe',
                                    },
                                    {
                                        id:23,
                                        corregimiento:'San Francisco',
                                    },
                                    {
                                        id:24,
                                        corregimiento:'San Martín',
                                    }, {
                                        id:25,
                                        corregimiento:'Santa Ana',
                                    },
                                    {
                                        id:26,
                                        corregimiento:'Tocumen',
                                    },
                                ] 
            },
            {  
                id:4,
                distrito:'San Miguelito',
                cabecera:'San Miguelito',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Amelia Denis de Icaza',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Arnulfo Arias',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Belisario Frías',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Belisario Porras',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'José Domingo Espinar',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Mateo Iturralde',
                                    }, {
                                        id:7,
                                        corregimiento:'Omar Torrijos',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Rufina Alfaro',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Victoriano Lorenzo',
                                    }
                                ] 
            }, {  
                id:5,
                distrito:'Taboga',
                cabecera:'Taboga',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Otoque Occidente',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Otoque Oriente',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Taboga',
                                    }
                                ] 
            },
            ]
    },
    {   id:8,
        map:img1x,
        provincia:'Panamá Oeste',
        distritos:[

            {  
                id:0,
                distrito:'Arraiján',
                cabecera:'Arraiján',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Arraiján',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Burunga',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Cerro Silvestre',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Juan Demóstenes Arosemena',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Nuevo Emperador',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Santa Clara',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Veracruz',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Vacamonte',
                                    },
                                     {
                                        id:8,
                                        corregimiento:'Vista Alegre',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Capira',
                cabecera:'Capira',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Caimito',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Campana',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Capira',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Cermeño',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Cirí de Los Sotos',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Cirí Grande',
                                    }, {
                                        id:7,
                                        corregimiento:'El Cacao',
                                    }, {
                                        id:8,
                                        corregimiento:'La Trinidad',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Las Ollas Arriba',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'Lídice',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'Villa Carmen',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Villa Rosario',
                                    }, {
                                        id:13,
                                        corregimiento:'Santa Rosa',
                                    }
                                ] 
            },
            {  
                id:2,
                distrito:'Chame',
                cabecera:'Chame',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bejuco',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Buenos Aires',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Cabuya',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Chame',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Chicá',
                                    },{
                                        id:5,
                                        corregimiento:'El Líbano',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Las Lajas',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Nueva Gorgona',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Punta Chame',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Sajalices',
                                    },{
                                        id:10,
                                        corregimiento:'Sorá',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'La Chorrera',
                cabecera:'La Chorrera',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Amador',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Arosemena',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Barrio Balboa',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Barrio Colón',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Arado',
                                    },{
                                        id:5,
                                        corregimiento:'El Coco',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Feuillet',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Guadalupe',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Herrera',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Hurtado',
                                    },{
                                        id:10,
                                        corregimiento:'Iturralde',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'La Represa',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Los Díaz',
                                    },
                                    {
                                        id:13,
                                        corregimiento:'Mendoza',
                                    },{
                                        id:14,
                                        corregimiento:'Obaldía',
                                    },
                                    {
                                        id:15,
                                        corregimiento:'Playa Leona',
                                    },
                                    {
                                        id:16,
                                        corregimiento:'Puerto Caimito',
                                    },
                                    {
                                        id:17,
                                        corregimiento:'Santa Rita',
                                    }
                                ] 
            },
            {  
                id:4,
                distrito:'San Carlos',
                cabecera:'San Carlos',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'El Espino',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Higo',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Guayabito',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'La Ermita',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'La Laguna',
                                    }, {
                                        id:5,
                                        corregimiento:'Las Uvas',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Los Llanitos',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'San Carlos',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'San José',
                                    }
                                ] 
            }
            ]
    },{   id:9,
        map:img11x,
        provincia:'Veraguas',
        distritos:[

            {  
                id:0,
                distrito:'Atalaya',
                cabecera:'Atalaya',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Atalaya',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Barrito',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'La Carrillo',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'La Montañuela',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'San Antonio',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Calobre',
                cabecera:'Calobre',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Barnizal',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Calobre',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Chitra',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'El Cocla',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Potrero',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'La Laguna',
                                    }, {
                                        id:7,
                                        corregimiento:'La Raya de Calobre',
                                    },{
                                        id:8,
                                        corregimiento:'La Tetilla',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'La Yeguada',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'Las Guías',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'Monjarás',
                                    }, {
                                        id:12,
                                        corregimiento:'San José',
                                    }
                                ] 
            },
            {  
                id:2,
                distrito:'Cañazas',
                cabecera:'Cañazas',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cañazas',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Cerro de Plata',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Aromillo',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'El Picador',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Las Cruces',
                                    }, {
                                        id:5,
                                        corregimiento:'Los Valles',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'San José',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'San Marcelo',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'La Mesa',
                cabecera:'La Mesa',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bisvalles',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Boró',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Higo',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'La Mesa',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Los Milagros',
                                    },{
                                        id:5,
                                        corregimiento:'Llano Grande',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'San Bartolo',
                                    }
                                ] 
            },
            {  
                id:4,
                distrito:'Las Palmas',
                cabecera:'Las Palmas',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cerro de Casa',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Corozal',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El María',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'El Prado',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Rincón',
                                    }, {
                                        id:5,
                                        corregimiento:'Las Palmas',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Lolá',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Manuel E. Amador Terrero',
                                    },{
                                        id:8,
                                        corregimiento:'Pixvae',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Puerto Vidal',
                                    }, {
                                        id:10,
                                        corregimiento:'San Martín de Porres',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'Viguí',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Zapotillo',
                                    }
                                ] 
            }, {  
                id:5,
                distrito:'Mariato',
                cabecera:'Mariato',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Arenas',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Cacao',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Mariato',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Quebro',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Tebario',
                                    }
                                ] 
            }, {  
                id:6,
                distrito:'Montijo',
                cabecera:'Montijo',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cébaco',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Costa Hermosa',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Gobernadora',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'La Garceana',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Leones',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Montijo',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Pilón',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Unión del Norte',
                                    }
                                ] 
            },
            {  
                id:7,
                distrito:'Río de Jesús',
                cabecera:'Río de Jesús',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Catorce de Noviembre',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Río de Jesús',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Las Huacas',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Los Castillos',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Utira',
                                    }
                                ] 
            },
            {  
                id:8,
                distrito:'San Francisco',
                cabecera:'San Francisco',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Corral Falso',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Los Hatillos',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Remance',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'San Francisco',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'San Juan',
                                    }, {
                                        id:5,
                                        corregimiento:'San José',
                                    }
                                ] 
            },
            {  
                id:9,
                distrito:'Santa Fe',
                cabecera:'Santa Fe',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Calovébora',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'El Alto',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Cuay',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'El Pantano',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Gatuncito',
                                    }, {
                                        id:5,
                                        corregimiento:'Río Luis',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Rubén Cantú',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Santa Fe',
                                    }
                                ] 
            },
            {  
                id:10,
                distrito:'Santiago',
                cabecera:'Santiago',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Canto del Llano',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Carlos Santana Ávila',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Edwin Fábrega',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'La Colorada',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'La Peña',
                                    }, {
                                        id:5,
                                        corregimiento:'La Raya de Santa María',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Los Algarrobos',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Nuevo Santiago',
                                    },{
                                        id:8,
                                        corregimiento:'Ponuga',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'San Martín de Porres',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'San Pedro del Espino',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'Santiago',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Santiago Este',
                                    }, {
                                        id:13,
                                        corregimiento:'Santiago Sur',
                                    },
                                    {
                                        id:14,
                                        corregimiento:'Rodrigo Luque',
                                    },
                                    {
                                        id:15,
                                        corregimiento:'Urracá',
                                    }
                                ] 
            }, {  
                id:11,
                distrito:'Soná',
                cabecera:'Soná',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bahía Honda',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Calidonia',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Cativé',
                                    },{
                                        id:3,
                                        corregimiento:'El Marañón',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Guarumal',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Hicaco',
                                    },{
                                        id:6,
                                        corregimiento:'La Soledad',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'La Trinchera',
                                    },
                                    {
                                        id:8,
                                        corregimiento:'Quebrada de Oro',
                                    },{
                                        id:9,
                                        corregimiento:'Río Grande',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'Rodeo Viejo',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'Soná',
                                    }
                                ] 
            },
            ]
    },
]



 const COM  = [
    {   id:0,
        map:null,
        comarca:'Emberá-Wounaan',
        distritos:[
            {  
                id:0,
                distrito:'Cémaco',
                cabecera:'Unión Chocó',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cirilo Guaynora',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Lajas Blancas',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Manuel Ortega',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Sambú',
                cabecera:'Puerto Indio',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Jingurudó',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Río Sabalo',
                                    }
                                ] 
            }
        ]
    },
    {   id:1,
        map:img7x,
        comarca:'Comarca Guna Yala',
        distritos:[
            {  
                id:0,
                distrito:'Guna Yala',
                cabecera:'Gaigirgordub',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Ailigandí',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Narganá',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Puerto Obaldía',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Tubualá',
                                    },
                                ] 
            }   
        ]
    },
    {   id:2,
        map:null,
        comarca:'Comarca Naso Tjër Di',
        distritos:[

            {  
                id:0,
                distrito:'Naso Tjër Di',
                cabecera:'Sieyic',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bonyik',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'San San Drui',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'El Teribe',
                                    }
                                ] 
            }
            ]
    },
    {   id:3,
        map:img10x,
        comarca:'Ngäbe-Buglé',
        distritos:[

            {  
                id:0,
                distrito:'Besikó',
                cabecera:'Soloy',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Boca de Balsa',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Cerro Banco',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Cerro Patena',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Camarón Arriba',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Emplanada de Chorcha',
                                    },
                                    {
                                        id:5,
                                        corregimiento:'Nämnoní',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Niba',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Soloy',
                                    }
                                ] 
            },
            {  
                id:1,
                distrito:'Kankintú',
                cabecera:'Bisira',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bisira',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Calante',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Kankintú',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Guoroní',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Mününí',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Piedra Roja',
                                    }, {
                                        id:7,
                                        corregimiento:'Tolote',
                                    }
                                ] 
            },
            {  
                id:2,
                distrito:'Kusapín',
                cabecera:'Kusapín',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Bahía Azul',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Cañaveral',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Kusapín',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Río Chiriquí',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Tobobé',
                                    }
                                ] 
            },
            {  
                id:3,
                distrito:'Mironó',
                cabecera:'Hato Pilón',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cascabel',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Hato Corotú',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Hato Culantro',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Hato Pilón',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Hato Jobo',
                                    },{
                                        id:5,
                                        corregimiento:'Hato Julí',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Quebrada de Loro',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Salto Dupí',
                                    }
                                ] 
            },
            {  
                id:4,
                distrito:'Müna',
                cabecera:'Chichica',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Alto Caballero',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Bakama',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Cerro Caña',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Cerro Puerco',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Chichica',
                                    }, {
                                        id:5,
                                        corregimiento:'Krüa',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'Maraca',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Nibra',
                                    },{
                                        id:8,
                                        corregimiento:'Peña Blanca',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'Roka',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'Sitio Prado',
                                    },
                                    {
                                        id:11,
                                        corregimiento:'Umaní',
                                    },
                                    {
                                        id:12,
                                        corregimiento:'Diko',
                                    }, {
                                        id:13,
                                        corregimiento:'Kikari',
                                    },
                                    {
                                        id:14,
                                        corregimiento:'Dikeri',
                                    },
                                    {
                                        id:15,
                                        corregimiento:'Mreeni',
                                    }
                                ] 
            }, {  
                id:5,
                distrito:'Nole Düima',
                cabecera:'Cerro Iglesias',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Cerro Iglesias',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Hato Chamí',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Jädeberi',
                                    },{
                                        id:3,
                                        corregimiento:'Lajero',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Susama',
                                    }
                                ] 
            },
            {  
                id:6,
                distrito:'Ñürüm',
                cabecera:'Buenos Aires',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Agua de Salud',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Alto de Jesús',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Buenos Aires',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Cerro Pelado',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'El Bale',
                                    },{
                                        id:5,
                                        corregimiento:'El Paredón',
                                    },
                                    {
                                        id:6,
                                        corregimiento:'El Piro',
                                    },
                                    {
                                        id:7,
                                        corregimiento:'Guayabito',
                                    },{
                                        id:8,
                                        corregimiento:'Güibale',
                                    },
                                    {
                                        id:9,
                                        corregimiento:'El Peñón',
                                    },
                                    {
                                        id:10,
                                        corregimiento:'El Piro N°2',
                                    }
                                ] 
            },
            {  
                id:7,
                distrito:'Jirondai',
                cabecera:'Samboa',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Burí',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Guariviara',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'Man Creek',
                                    },
                                    {
                                        id:3,
                                        corregimiento:'Samboa',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Tuwai',
                                    }
                                ] 
            }, {  
                id:8,
                distrito:'Santa Catalina o Calovébora',
                cabecera:'Santa Catalina o Calovébora',
                corregimientos:[
                                    {
                                        id:0,
                                        corregimiento:'Alto Bilingüe',
                                    },
                                    {
                                        id:1,
                                        corregimiento:'Loma Yuca',
                                    },
                                    {
                                        id:2,
                                        corregimiento:'San Pedrito',
                                    },{
                                        id:3,
                                        corregimiento:'Valle Bonito',
                                    },
                                    {
                                        id:4,
                                        corregimiento:'Santa Catalina o Calovébora',
                                    }
                                ] 
            },
            ]
    }
]

export { PRO , COM }







