import React, { useEffect, useState } from 'react'
import { Switch, Navigate, BrowserRouter as  Router, Routes, Route } from "react-router-dom";
import Forgot from '../auth/Forgot.jsx'
import Login from '../auth/Login.jsx'
import Create from '../auth/Create.jsx'

import RutaProtegida from '../auth/RutaProtegida.jsx';


import DashCliente from '../A-Bienes-Raices/dash/Cliente'
import MenuState from '../context/menu/menuState'; 
import SignatureState from '../context/StepLogical/Signature/signatureState'

import StepLogicalState from '../context/StepLogical/steplogicalState'

import  {AuthProvider}  from '../context/autenticacion/AuthProvider'
import  {DataRequestProvider}  from '../context/data/DataRequestContext'
import  {JoelReactProvider}  from '../context/logical/JoelReactContext'
import RedirectPage from '../componentes/redirect/RedirectPage'
///// aqui vamos
/// domo ////
import CreateAcc from './auth/CreateAcc'
import LoginAcc from './auth/LoginAcc'

import { NavJoemaProvider } from './context/nav/NavJoemaContext.jsx';
//// banca en linea  /////

import { ActJoemaProvider } from  './context/Actions/ActJoemaContext.jsx';
import { ActJoemaCuentaProvider } from  './context/Actions/ActJoemaCuentaContext.jsx';

import { ActJoemaSearchProvider } from './context/Actions/ActJoemaSearchContext.jsx';

function App() {
  
  window.scrollTo(0, 0)
  //////////////////// este es eel app de bienes raices
  return (
    <div className="App">

      <ActJoemaProvider>
      <NavJoemaProvider>
      <ActJoemaSearchProvider>
      <ActJoemaCuentaProvider>

      
      <AuthProvider>
                    <JoelReactProvider>
                    <DataRequestProvider>
                    <MenuState>
                    <StepLogicalState>
                    <SignatureState>
                        <Routes >
                            <Route index element={ <LoginAcc/>} />
                            <Route path='/external-link' element={<RedirectPage />} />
                            <Route path="/forgot" element={ <Forgot/>} />
                            <Route path="/create" element={ <CreateAcc/>} />
                            {/* <Route path="/logindomo" element={ <LoginDomo/>} />
                            <Route path="/createdomo" element={ <CreateDomo/>} /> */}
                            <Route path="*" element={ <Navigate replace to="/" />} />
                            <Route path="/core" element={<RutaProtegida/>} >
                            <Route index element={ <DashCliente/>} />
                            </Route>
                        </Routes>
                      </SignatureState>
                      </StepLogicalState>
                      </MenuState>
                      </DataRequestProvider>
                      </JoelReactProvider>
          </AuthProvider>
          </ActJoemaCuentaProvider>

          </ActJoemaSearchProvider>           
          </NavJoemaProvider>      
          </ActJoemaProvider>
    </div>
  );
}

export default App;