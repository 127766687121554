import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'
import InputOn from '../../componentes/InputD/InputOn';

const PrivacidadYUsoCompartido = () => {
    const information = [
        {   id:0,
            icon:a,
            title:'¿Por qué mi información no aparece aquí?',
            cuento:'Para proteger tu identidad, ocultamos algunos datos de la cuenta.'
        }, {   id:1,
            icon:b,
            title:'¿Qué datos se pueden editar?',
            cuento:'No se pueden modificar los datos que Airbnb utiliza para verificar tu identidad. Aunque puedes modificar tus datos personales y de contacto, podríamos pedirte que verifiques tu identidad la próxima vez que hagas una reservación o crees un anuncio.'
        }, {   id:2,
            icon:c,
            title:'¿Qué información se comparte con los demás?',
            cuento:'Airbnb solo proporciona los datos de contacto de los anfitriones y los huéspedes cuando se confirma una reservación.'
        },
    ]


    const information02 = [
        {   id:0,
            icon:b,
            title:'Comprometidos con la privacidad',
            cuento:'Responde solo a las solicitudes de personas que conozcas. Es posible que los hackers quieran acceder a tu cuenta imitando el correo electrónico de un miembro del equipo.'
        }
    ]

    const information01 = [
        {   id:0,
            icon:b,
            title:'Vamos a hacer que tu cuenta sea más segura',
            cuento:'Trabajamos constantemente en nuevas formas para aumentar la seguridad de nuestra comunidad. Es por eso que revisamos cada cuenta para asegurarnos de que sea lo más segura posible.',
            btn:'Mejorar'
        }
    ] 

    const information03 = [
        {   id:0,
            icon:a,
            title:'Cómo agregar dispositivos de personas de confianza',
            cuento:'Cuando apruebas una solicitud, le estás dando a esa persona acceso total a tu cuenta. Podrá modificar las reservaciones y enviar mensajes en tu nombre.',
            btn:'Mejorar'
        }
    ]



    const task = [
       { id:0,
        value:'Datos'
        },
        { id:1,
            value:'Divulgación'
            },
            { id:2,
                value:'Servicios'
                },
    ]

    const {
        alessa,
        MeEncantas,
        focustask, setFocusTask
    } = useAcc()
    useEffect( () => {
        if(alessa.T == 'Privacidad y uso compartido') {
            setFocusTask('Datos')
        }
    },[])
    // useEffect( () => {
    //     if(focustask === "") {
    //         setFocusTask('INICIO DE SESIÓN')
    //     }
    // },[alessa])

    return (
        <>
        { alessa.T == 'Privacidad y uso compartido' ?
                 <div className=" box-header-general">
                 <div className={`sabes-que-te-quiero`} >

                     <div>
                         <div className={`header-joema-hola-acc retoquebb`}>
                             <p onClick={MeEncantas} className={`text-deco`}>
                                 Cuenta
                             </p>
                             <span>{'>'}</span>
                                 <p >{alessa.T}</p>
                         </div>
                         
                     </div>
                    <section className={`master-contenedor-action-bar`}>
                     <section className={'contenedor-action-bar'}>
                      { task.map((viste)=> {
                        return (

                            <button key={viste.id} className={`clasic-name-btn ${focustask === viste.value ? 'clasic-name-btn-active' :''} `}   
                                    onClick={ () => {setFocusTask(viste.value)}}
                            >
                                {viste.value}
                            </button>

                        )
                      })

                      }

                     </section>
                     </section>

                     { focustask === 'Datos' ? 
                        <>
                        <section className={`contenedor-doble`}>
                                                <div className={`bombaso`}>
                                                <div className={`contenedor-input `}>
                                                <div className={`set-style-2 `}>
                                                    <h2>
                                                    Administra los datos de tu cuenta
                                                    </h2>
                                                    <p>
                                                    Puedes hacer una solicitud para descargar o eliminar tus datos personales.
                                                    </p>
                                                </div>

                                                <div className={`set-style-2 `}>
                                                <div className={`flex-round-yeah`}> 
                                                Solicita tus datos personales <span className={`epical-select`}>{`>`}</span>
                                                    </div>
                                                    <p className={``}>
                                                    Crearemos un archivo para que descargues tus datos personales.
                                                    </p>
                                                   
                                                </div> 

                                                <div className={`set-style-2 `}>
                                                    <div className={`flex-round-yeah`}>
                                                    Eliminar tu cuenta <span className={`epical-select`}>{`>`}</span>
                                                    </div>
                                                    <p className={``}>
                                                    Si lo haces, tu cuenta y tus datos se borrarán de forma permanente, de acuerdo con la legislación aplicable.
                                                    </p>
                                                    
                                                </div>
                                                </div>



                                                </div>
                                                <div >


                                                    <div className={`information-box-box-container`}>
                                                    {  information02.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                           
                                                                        <h3>{m.title}</h3>
                                                                       
                                                                        <p>Nos compromete a proteger tus datos. Consulta los detalles en nuestra <span className={`btn-text-raya`}>  Política de privacidad.</span></p>
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>
                                            </> : null
                                        }
                                        

                                        
                     { focustask === 'Divulgación' ? 
                        <>
                        <section className={`contenedor-doble`}>
                        <div className={`bombaso`}>
                                <div className={`contenedor-input `}>
                                                <div className={`set-style-2 `}>
                                                    <h2>
                                                    Divulgación de los datos de tu actividad
                                                    </h2>
                                                    <p>
                                                    Decide cómo se muestra su perfil y actividad a los demás.
                                                    </p>
                                                </div>

                                                <div className={`set-style-2 `}>
                                                <div className={`flex-round-yeah`}> 
                                                Incluir mi perfil y mi anuncio en los motores de búsqueda 
                                                    </div>
                                                    <p className={``}>
                                                    Al activar esta opción, los motores de búsqueda, como Google, mostrarán tu perfil y tus anuncios en los resultados de búsqueda.
                                                    </p>

                                                    <InputOn
                                                    state={true}/>
                                                   
                                                </div> 

                                                <div className={`set-style-2 `}>
                                                    <div className={`flex-round-yeah`}>
                                                    Confirmaciones de mensaje visto 
                                                    </div>
                                                    <p className={``}>
                                                    Cuando esta opción está activada, el remitente sabrá que leíste sus mensajes. <span className={`btn-text-raya`}> Más información. </span>
                                                    </p>
                                                    <InputOn
                                                    state={false}/>
                                                </div>
                                </div>
                                <div className={`contenedor-input `}>
                                                <div className={`set-style-2 `}>
                                                    <h2>
                                                    Intercambio de datos
                                                    </h2>
                                                    <p>
                                                    Decide cómo se utilizan tus datos en investigaciones de Airbnb.
                                                    </p>
                                                </div>

                                                <div className={`set-style-2 `}>
                                                <div className={`flex-round-yeah`}> 
                                                Usar mi nombre y mi foto de perfil para ayudar a combatir la discriminación
                                                    </div>
                                                    <p className={``}>
                                                    Si lo activas, nos ayudarás a estudiar mejor cómo podemos detectar e impedir situaciones de discriminación<span className={`btn-text-raya`}> Más información. </span>
                                                    </p>
                                                    <InputOn
                                                        state={false}
                                                    />
                                                </div> 

                                             
                                </div>
                                            

                                             



                                                </div>
                                                <div >

                                                <div className={`information-box-box-container`}>
                                                    {  information02.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                           
                                                                        <h3>{m.title}</h3>
                                                                       
                                                                        <p>Nos compromete a proteger tus datos. Consulta los detalles en nuestra <span className={`btn-text-raya`}>  Política de privacidad.</span></p>
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>
                                            </> : null
                                        }
                                        

                                        
                     { focustask === 'Servicios' ? 
                        <>
                        <section className={`contenedor-doble`}>
                        <div className={`bombaso`}>
                                               

                        <div className={`contenedor-input `}>
                                                <div className={`set-style-2 `}>
                                                    <h2>
                                                    Servicios vinculados
                                                    </h2>
                                                    <p>
                                                    Consulta los servicios que vinculaste a tu cuenta de Airbnb
                                                    </p>
                                                    <p className="smallis">No hay servicios vinculados en este momento</p>
                                                </div>

                                </div>

                                                


                                                </div>
                                                <div >


                                                <div className={`information-box-box-container`}>
                                                    {  information02.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                           
                                                                        <h3>{m.title}</h3>
                                                                       
                                                                        <p>Nos compromete a proteger tus datos. Consulta los detalles en nuestra <span className={`btn-text-raya`}>  Política de privacidad.</span></p>
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>
                                            </> : null
                                        }
                                        
                    

                 </div>

                
          </div> : null
        }
        </>

    );
}
 
export default PrivacidadYUsoCompartido;