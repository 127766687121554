import React from 'react'
import s from '../../imagenes/iconos/search.svg'
import useAct from '../../hooks/useAct';

import useSearchJoe from '../../hooks/useSearchJoe';

const SearchBar = () => {

    /////////// provincia
    ///////// distrito 
    //////// corregimiento
    /////// zona ( opcional )


    const {
        winsearch,WinS
    } = useAct()


    

    const {
        
        filtro
    } = useSearchJoe()

    return (  


        <>

        <section className="search-natural search-love" onClick={WinS}>

            <div>

                {
                    filtro.provincia === "" ? 
                    <>
                    Provincia
                    </> : <>
                    <span className={`robertodijo`}>
                    <span className="robertodijo-hijo">Provincia</span>
                    {filtro.provincia}
                    </span>
                    </>
                }
                

            </div>

            <div className="sexy-border">
            {
                    filtro.distrito === "" ? 
                    <>
                    Distrito
                    </> : <>
                    <span className={`robertodijo`}>

                        <span className="robertodijo-hijo">Distrito</span>
                    {filtro.distrito}
                    </span>
                    </>
                }
                
            </div>

            <div>
            {
                    filtro.corregimiento === "" ? 
                    <>
                    Corregimiento
                    </> : <>
                    <span className={`robertodijo`}>
                    <span className="robertodijo-hijo">Corregimiento</span>
                    {filtro.corregimiento}
                    </span>
                    </>
                }
                
                
            </div>

            <div className="float-btn-joema">
                <img src={s} />

            </div>

        </section>
        </>

    );
}
 
export default SearchBar;