import React, { useEffect } from 'react'




const RedirectPage = () => {
    useEffect(() => {
      window.location.replace('https://www.vivapancafe.com/atlas/formularios/FORM004_Signature_Card.pdf');
    }, [])
  
    // Render some text when redirecting
    // You can use a loading gif or something like that
    return <div classNmae="pdf">
      <h3>Loading PDF...</h3>
    </div>
  }


  export default RedirectPage