import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios';
import useAuth from '../hooks/useAuth';




const axiosAuth = axios.create({


    baseURL : "https://strprisma.com/thedome/webservice",
    headers: {
        'Content-Type' : 'application/json',
        'Accept' : "application/json"
    }


});


export default axiosAuth;

// esto lo vamos a pasar al state  


// const axiosAuth = axios.create({
//     baseURL : "https://strprisma.com/thedome/webservice",
//     headers: {
//         'Content-Type' : 'application/json',
//         'Accept' : "application/json"
//     }
// });


// export { axiosAuth }


