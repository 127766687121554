import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'
import InputBurger from '../../componentes/InputD/InputBurger';

const CreditoYCuponesPorInvitacion = () => {
    const information = [
        {   id:0,
            icon:a,
            title:'¿Por qué mi información no aparece aquí?',
            cuento:'Para proteger tu identidad, ocultamos algunos datos de la cuenta.'
        }, {   id:1,
            icon:b,
            title:'¿Qué datos se pueden editar?',
            cuento:'No se pueden modificar los datos que Airbnb utiliza para verificar tu identidad. Aunque puedes modificar tus datos personales y de contacto, podríamos pedirte que verifiques tu identidad la próxima vez que hagas una reservación o crees un anuncio.'
        }, {   id:2,
            icon:c,
            title:'¿Qué información se comparte con los demás?',
            cuento:'Airbnb solo proporciona los datos de contacto de los anfitriones y los huéspedes cuando se confirma una reservación.'
        },
    ]


    const information02 = [
        {   id:0,
            icon:b,
            title:'Protege tu cuenta',
            cuento:'Responde solo a las solicitudes de personas que conozcas. Es posible que los hackers quieran acceder a tu cuenta imitando el correo electrónico de un miembro del equipo.'
        }
    ]

    const information01 = [
        {   id:0,
            icon:b,
            title:'Vamos a hacer que tu cuenta sea más segura',
            cuento:'Trabajamos constantemente en nuevas formas para aumentar la seguridad de nuestra comunidad. Es por eso que revisamos cada cuenta para asegurarnos de que sea lo más segura posible.',
            btn:'Mejorar'
        }
    ] 

    const information03 = [
        {   id:0,
            icon:a,
            title:'Cómo agregar dispositivos de personas de confianza',
            cuento:'Cuando apruebas una solicitud, le estás dando a esa persona acceso total a tu cuenta. Podrá modificar las reservaciones y enviar mensajes en tu nombre.',
            btn:'Mejorar'
        }
    ]



    const task = [
       { id:0,
        value:'INICIO DE SESIÓN'
        },
        { id:1,
            value:'SOLICITUDES DE INICIO DE SESIÓN'
            },
            { id:2,
                value:'ACCESO COMPARTIDO'
                },
    ]

    const {
        alessa,
        MeEncantas,
        focustask, setFocusTask
    } = useAcc()

    // useEffect( () => {
    //     if(focustask === "") {
    //         setFocusTask('INICIO DE SESIÓN')
    //     }
    // },[])

    // useEffect(
    //     () => {         
    //         window.scrollTo(0, 0)
                                
    //     },[alessa]
    // )


    return (  

        
        <>
        { alessa.T == 'Crédito y cupones por invitaciones' ?
                 <div className=" box-header-general">
                 <div className={`sabes-que-te-quiero`} >

                     <div>
                         <div className={`header-joema-hola-acc`}>
                             <p onClick={MeEncantas} className={`text-deco`}>
                                 Cuenta
                             </p>
                             <span>{'>'}</span>
                                 <p >{alessa.T}</p>
                         </div>
                         <div className={`header-joema-hola-title`}>
                             <p className={`Font-Love-Title`}>
                                 {alessa.T}
                             </p>
                         </div>
                     </div>
                    

                    
                                        

                                        
                                        <section className={`contenedor-uniforme`}>
                                                 <section className={`invitaciones`}>

                                                   
                                                 <p className={`subinputt`}>
                                                    Lleva un seguimiento de tus invitaciones
                                                    </p>                                           

                                                    <div className={`set-style-2`}>
                                                    
                                                    
                                                    
                                                    <div className={`saldera border-0`}>
                                                        <h4>Invitaciones completadas</h4>
                                                        <p>0</p>
                                                    </div>
                                                    <div className={`saldera border-0`}>
                                                        <h4>Registros</h4>
                                                        <p>0</p>
                                                    </div>
                                                    
                                                    <button className={`joema-btn-special w-100`}>
                                                    Mostrar más detalles
                                                    </button>

                                                </div>

                                                 </section>
                                                 

                                               

                             </section>

                             <section className={`contenedor-doble-master bombaso`}>


                                       <div className={``}>

                                        

                                         <div className={`contenedor-input border-0 `}> 


                                            <div className=""> 

                                             <div className={`information-input `}>
                                                <p className={`text-input subinputtx2`}>Preguntas frecuentes</p>
                                                <p className={`text-input`}>
                                                Consulta las respuestas a las preguntas más comunes y más detalles del programa en el <span className="btn-text-raya"> Centro de ayuda.</span>
                                                </p>
                                                </div>

                                            </div>

                                           

                                         </div>

                                                   



                                                </div>
                                                <div className={``}>

                                        

                                         <div className={`contenedor-input border-bottom-kill `}> 


                     

                                            <InputBurger
                                                        inputname={'¿Sigue en marcha el programa de Invitaciones?'}
                                                    
                                                        info={'El programa de invitaciones está cerrado y no se pueden enviar nuevas invitaciones.'}
                                                        info2={'Si te enviaron un cupón antes del cierre del programa, podrás utilizarlo en cualquier reservación que realices antes de que venza.'}
                                                        info3={'Los créditos del remitente se respetarán hasta que venzan. Además, si enviaste invitaciones cuando el programa estaba activo, obtendrás crédito por las estancias que se completen con esos cupones antes de que caduquen. En estos casos, el monto que recibas dependerá de lo que ofreciéramos en aquel momento.'}
                                                       
                                                        action={'Guardar'}
                                               
                                                        onFocus
                                                    />


<InputBurger
                                                        inputname={'Invité a un amigo pero no he recibido crédito de viaje.'}
                                                    
                                                        info={'No ofrecemos crédito de viaje por las invitaciones enviadas después del 1 de octubre de 2020.'}
                                                       
                                                        action={'Guardar'}
                                               
                                                        onFocus
                                                    />

                                           

                                         </div>

                                                   



                                                </div>
                     </section>

                 </div>

                
          </div> : null

        }

       
        </>

    );
}
 
export default CreditoYCuponesPorInvitacion;