import React, { useState } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'


const InterfaseDeJoeMa = [
        {
            id:0,
            key:0,
            nodestone:'330000',
            value:'',
            labelname:'Hola',
            placeholder:'',
            style:'Grande-grande-es-el-Senhor border-board ',
            state:true,
            container:true,
            onchange:'',
            XML:'<<<section',
            child:[    
                     {
                        id:0,
                        nodestone:'003300',
                        value:'',
                        labelname:'',
                        placeholder:'',
                        style:'hw-joema-20',
                        state:true,
                        container:true,
                        onchange:'',
                        XML:'<<<div',
                        child:[]
                     },
                     {
                        id:1,
                        nodestone:'003300',
                        value:'',
                        labelname:'',
                        placeholder:'',
                        style:'hw-joema-80',
                        state:true,
                        container:true,
                        onchange:'',
                        XML:'<<<div',
                        child:[
                              {
                                id:0,
                                key:0,
                                nodestone:'330000',
                                value:'',
                                labelname:'Inicio de Sesión',
                                placeholder:'',
                                style:'border-board joema-joema-card',
                                state:true,
                                container:true,
                                onchange:'',
                                XML:'<<<section',
                                child:[
                                    {
                                      id:0,
                                      key:0,
                                      nodestone:'330000',
                                      value:'',
                                      labelname:'Inicio de Sesión',
                                      placeholder:'',
                                      style:'border-board cornudo',
                                      state:true,
                                      container:true,
                                      onchange:'',
                                      XML:'<<<section',
                                      child:[
                                        {
                                          id:0,
                                          key:0,
                                          nodestone:'330000',
                                          value:'',
                                          labelname:'Inicio de Sesión',
                                          placeholder:'',
                                          style:'border-board',
                                          state:true,
                                          container:true,
                                          onchange:'',
                                          XML:'<<<h3',
                                          child:[],
                                            },
                                            {
                                              id:1,
                                              key:0,
                                              nodestone:'330000',
                                              value:'',
                                              labelname:'Solicitudes de Inicio de Sesión',
                                              placeholder:'',
                                              style:' sborder-board ',
                                              state:true,
                                              container:true,
                                              onchange:'',
                                              XML:'<<<div',
                                              child:[],
                                                },
                                            ],
                                        },
                                        {
                                          id:1,
                                          key:0,
                                          nodestone:'330000',
                                          value:'',
                                          labelname:'Solicitudes de Inicio de Sesión',
                                          placeholder:'',
                                          style:' border-board',
                                          state:true,
                                          container:true,
                                          onchange:'',
                                          XML:'<<<section',
                                          child:[],
                                            },
                                            {
                                              id:2,
                                              key:0,
                                              nodestone:'330000',
                                              value:'',
                                              labelname:'Solicitudes de Inicio de Sesión',
                                              placeholder:'',
                                              style:' border-board ',
                                              state:true,
                                              container:true,
                                              onchange:'',
                                              XML:'<<<section',
                                              child:[],
                                                },
                              ],
                                  },
                                  {
                                    id:1,
                                    key:0,
                                    nodestone:'330000',
                                    value:'',
                                    labelname:'Solicitudes de Inicio de Sesión',
                                    placeholder:'',
                                    style:' border-board d-none',
                                    state:true,
                                    container:true,
                                    onchange:'',
                                    XML:'<<<section',
                                    child:[],
                                      },
                                      {
                                        id:2,
                                        key:0,
                                        nodestone:'330000',
                                        value:'',
                                        labelname:'Solicitudes de Inicio de Sesión',
                                        placeholder:'',
                                        style:' border-board d-none',
                                        state:true,
                                        container:true,
                                        onchange:'',
                                        XML:'<<<section',
                                        child:[],
                                          },
                        ]
                     }
                    ],
              },
              {
                id:0,
                key:0,
                nodestone:'330000',
                value:'',
                labelname:'Hola',
                placeholder:'',
                style:'Grande-grande-es-el-Senhor border-board ',
                state:true,
                container:true,
                onchange:'',
                XML:'<<<section',
                child:[]
             }
              
]



const DatosPersonales = () => {

    

    const information = [
        {   id:0,
            icon:a,
            title:'¿Por qué mi información no aparece aquí?',
            cuento:'Para proteger tu identidad, ocultamos algunos datos de la cuenta.'
        }, {   id:1,
            icon:b,
            title:'¿Qué datos se pueden editar?',
            cuento:'No se pueden modificar los datos que Airbnb utiliza para verificar tu identidad. Aunque puedes modificar tus datos personales y de contacto, podríamos pedirte que verifiques tu identidad la próxima vez que hagas una reservación o crees un anuncio.'
        }, {   id:2,
            icon:c,
            title:'¿Qué información se comparte con los demás?',
            cuento:'Airbnb solo proporciona los datos de contacto de los anfitriones y los huéspedes cuando se confirma una reservación.'
        },
    ]


    const {
        alessa,
        MeEncantas
    } = useAcc()
    return (  

        
        <>
        { alessa.T == 'Datos personales' ?
                 <div className=" box-header-general">
                        <div className={`sabes-que-te-quiero`} >

                            <div>
                                <div className={`header-joema-hola-acc`}>
                                    <p onClick={MeEncantas} className={`text-deco`}>
                                        Cuenta
                                    </p>
                                    <span>{'>'}</span>
                                        <p >{alessa.T}</p>
                                </div>
                                <div className={`header-joema-hola-title`}>
                                    <p className={`Font-Love-Title`}>
                                        {alessa.T}
                                    </p>
                                </div>
                            </div>
                            <section className={`contenedor-doble`}>

                            <div>


                             

                                <InputDynamic
                                    inputname={'Nombre Legal'}
                                    inputlabel={'Joel Marical'}
                                    info={'Este es el nombre que aparece en tu documento de viaje, que puede ser una licencia o un pasaporte.'}
                                    firstlabel={'Nombre'}
                                    seclabel={'Apellido'}
                                    action={'Guardar'}
                                    model={2}
                                    onFocus
                                />

                                <InputDynamic
                                    inputname={'Correo electrónico'}
                                    inputlabel={'j***z@gmail.com'}
                                    info={'Usa una dirección a la que puedas acceder en cualquier momento.'}
                                    firstlabel={'Correo electrónico'}
                                    action={'Guardar'}
                                    model={1}
                                    onFocus
                                />

                                <InputDynamic
                                    inputname={'Números de teléfono'}
                                    inputlabel={'Agrega un número para que los huéspedes con reservaciones confirmadas y Airbnb puedan ponerse en contacto contigo. Puedes agregar otros números y elegir cómo usarlos.'}
                                    info={'Agrega un número para que los huéspedes con reservaciones confirmadas y Airbnb puedan ponerse en contacto contigo. Puedes agregar otros números y elegir cómo usarlos.'}
                                    firstlabel={'Ingresa un número de teléfono nuevo'}
                                    seclabel={''}
                                    action={'Verificar'}
                                    model={3}
                                    onFocus
                                />

                                <InputDynamic
                                    inputname={'Identificación oficial'}
                                    inputlabel={'No se proporciona'}
                                    info={'Tendrás que añadir un documento de identificación oficial Este paso nos sirve para comprobar que eres quien dices ser.'}
                                    firstlabel={'Identificación oficial'}
                                    seclabel={'Diracción, suite (opcional)'}
                                    treslabel={'Provincia'}
                                    cuatrolabel={'Distrito / Corregimiento'}
                                    quintolabel={'Código postal'}
                                    action={'Guardar'}
                                    model={1}
                                    onFocus
                                />

                            <InputDynamic
                                    inputname={'Dirección'}
                                    inputlabel={'No se proporciona'}
                                    info={'Usa una dirección permanente en la que puedas recibir correo.'}
                                    firstlabel={'Dirección'}
                                    seclabel={'Diracción, suite (opcional)'}
                                    treslabel={'Provincia'}
                                    cuatrolabel={'Distrito / Corregimiento'}
                                    quintolabel={'Código postal'}
                                    action={'Guardar'}
                                    model={4}
                                    onFocus
                                />

                            <InputDynamic
                                    inputname={'Contacto de emergencia'}
                                    inputlabel={'No se proporciona'}
                                    info={'Contacto de una persona de confianza a la que podamos avisar en caso de emergencia.'}
                                    firstlabel={'Nombre'}
                                    seclabel={'Relación'}
                                    treslabel={'Correo electrónico'}
                                    cuatrolabel={'Número telefónico'}
                                    action={'Guardar'}
                                    model={5}
                                    onFocus
                                />



                            </div>



                            <div >


                                <div className={`information-box-box-container`}>
                                  {  information.map((m) => {
                                            return(
                                                <div key={m.id} className={`elmentaohijo`}> 
                                                        <img src={m.icon}/>  
                                                      <h3>{m.title}</h3>
                                                     <p>{m.cuento}</p>
                                                 </div>
                                            )
                                        })
                                  }

                                </div>
                                
                            </div>



                        </section>
                           

                        </div>

                        


                        <div className={`aqui-en-el-centro-mi-vida d-none`}> 
                            {InterfaseDeJoeMa.map((c, index) => {
                                return(
                                    <div key={index} className={`${c.style} prueba3 `} onClick={() => {}}>
                                        {c.child.map((c, index) => { 
                                            return(
                                                <div key={index}  className={`${c.style} `}>  
                                                    {c.child.map((c, index) => { 
                                                    return(
                                                        <div key={index}  className={`${c.style}  `}> 
                                                                    {c.child.map((c, index) => { 
                                                                    return(
                                                                        <div key={index}  className={`${c.style}  `}> 

                                                                            
                                                                                {c.child.map((c, index) => { 

                                                                                return(
                                                                                    <div key={index}  className={`${c.style} prueba1  `}>

                                                                                        <>
                                                                                    
                                                                                    
                                                                                            <h3 className={`${c.style}`}>{c.labelname}</h3>
                                                                                            


                                                                                            {c.child.map((c, index) => { 
                                                                                            return(
                                                                                                <div key={index}  className={`${c.style}  `}> 
                                                                                                
                                                                                                </div>
                                                                                                )
                                                                                            })} 
                                                                                    </>
                                                                                    </div>
                                                                                    )
                                                                                })} 
                                                                        
                                                                        </div>
                                                                        )
                                                                    })} 
                                                        
                                                        </div>
                                                        )
                                                    })} 
                                                </div>
                                                    )
                                        })} 
                                        </div>
                                    )
                            })}

                        </div>

                        {/* <div className={`arriba-bebe`}>

                        <button onClick={MeEncantas}>Back</button>

                        </div> */}
                 </div> : null

        }

       
        </>

    );
}
 
export default DatosPersonales;