import React, { useContext } from 'react'

import ActJoemaContext from '../context/Actions/ActJoemaContext'

const useAct = () => {
    return useContext(ActJoemaContext)
}


export default useAct;
