import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'
import f from '../../imagenes/iconos/WorldAir.svg'

const PreferenciasGlobales = () => {
    const information = [
        {   id:0,
            icon:a,
            title:'¿Por qué mi información no aparece aquí?',
            cuento:'Para proteger tu identidad, ocultamos algunos datos de la cuenta.'
        }, {   id:1,
            icon:b,
            title:'¿Qué datos se pueden editar?',
            cuento:'No se pueden modificar los datos que Airbnb utiliza para verificar tu identidad. Aunque puedes modificar tus datos personales y de contacto, podríamos pedirte que verifiques tu identidad la próxima vez que hagas una reservación o crees un anuncio.'
        }, {   id:2,
            icon:c,
            title:'¿Qué información se comparte con los demás?',
            cuento:'Airbnb solo proporciona los datos de contacto de los anfitriones y los huéspedes cuando se confirma una reservación.'
        },
    ]


    const information02 = [
        {   id:0,
            icon:b,
            title:'Protege tu cuenta',
            cuento:'Responde solo a las solicitudes de personas que conozcas. Es posible que los hackers quieran acceder a tu cuenta imitando el correo electrónico de un miembro del equipo.'
        }
    ]

    const information01 = [
        {   id:0,
            icon:f,
            title:'Vamos a hacer que tu cuenta sea más segura',
            cuento:'Trabajamos constantemente en nuevas formas para aumentar la seguridad de nuestra comunidad. Es por eso que revisamos cada cuenta para asegurarnos de que sea lo más segura posible.',
            btn:'Mejorar'
        }
    ] 

    const information03 = [
        {   id:0,
            icon:f,
            title:'Tus preferencias globales',
            cuento:'Al cambiar la moneda, se actualiza la manera en que se muestran los precios. Puedes cambiar la forma en que recibes los cobros en tus preferencias de pago.',
            btn:'Mejorar'
        }
    ]



    const task = [
       { id:0,
        value:'INICIO DE SESIÓN'
        },
        { id:1,
            value:'SOLICITUDES DE INICIO DE SESIÓN'
            },
            { id:2,
                value:'ACCESO COMPARTIDO'
                },
    ]

    const {
        alessa,
        MeEncantas,
        focustask, setFocusTask
    } = useAcc()

    // useEffect( () => {
    //     if(focustask === "") {
    //         setFocusTask('INICIO DE SESIÓN')
    //     }
    // },[alessa])

    return (
        <>
        { alessa.T == 'Preferencias globales' ?
                 <div className=" box-header-general">
                 <div className={`sabes-que-te-quiero`} >

                     <div>
                         <div className={`header-joema-hola-acc`}>
                             <p onClick={MeEncantas} className={`text-deco`}>
                                 Cuenta
                             </p>
                             <span>{'>'}</span>
                                 <p >{alessa.T}</p>
                         </div>
                         <div className={`header-joema-hola-title`}>
                             <p className={`Font-Love-Title`}>
                                 {alessa.T}
                             </p>
                         </div>
                     </div>
                 

                     <section className={`contenedor-doble`}>
                        <div className={`bombaso`}>
                                                    <InputDynamic
                                                        inputname={'Idioma preferido'}
                                                        inputlabel={'Español'}
                                                        info={'Idioma preferido'}
                                                        firstlabel={'Ingrese su Idioma preferido'}
                                                        seclabel={'Apellido'}
                                                        action={'Guardar'}
                                                        model={1}
                                                        onFocus
                                                    />

                                                    <InputDynamic
                                                        inputname={'Moneda preferida'}
                                                        inputlabel={'Dólar estadounidense'}
                                                        info={'Moneda preferida'}
                                                        firstlabel={'Ingrese su moneda preferida'}
                                                        action={'Guardar'}
                                                        model={1}
                                                        onFocus
                                                    />

                                                    <InputDynamic
                                                        inputname={'Zona horaria'}
                                                        inputlabel={'Zona horaria'}
                                                        info={'Zona horaria'}
                                                        firstlabel={'Ingrese su zona horaria'}
                                                        seclabel={''}
                                                        action={'Guardar'}
                                                        model={1}
                                                        onFocus
                                                    />

                                                   



                                                </div>
                                                <div >


                                                    <div className={`information-box-box-container`}>
                                                    {  information03.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                            <img src={m.icon}/>  
                                                                        <h3>{m.title}</h3>
                                                                        <p>{m.cuento}</p>
                                                                      
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>
                                        
               

                 </div>

                
          </div> : null

        }
        </>

    );
}
 
export default PreferenciasGlobales;