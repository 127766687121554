import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Loader from '../componentes/Loader/Loader'
import LoaderCenter from '../A-Bienes-Raices/componentes/Loader/LoaderCenter';



const RutaProtegida = () => {

    const {auth, cargando} = useAuth()
    

    if(cargando) return <LoaderCenter/>




    return (  
        <>

        { auth.status == true ? <Outlet/> : <Navigate to="/"/>


        }
        </>



    );
}
 
export default RutaProtegida;