import React from 'react';

import {

    ACCOUNT_OPEN,
    BENDESIG_NATION,
    ELECTRONIC_COMMUNICATIONS,
    INDIVIDUAL_FORM,
    SIGNATURE_CARD

} from '../../types/index'


export default (state, action ) => {
    switch(action.type) { 

        case ACCOUNT_OPEN : return {
            ...state,
            accopening:action.payload,
            
            
        } 
        case BENDESIG_NATION: return {
            ...state,
                ben:action.payload,
            
        } 
        case ELECTRONIC_COMMUNICATIONS: return {
            ...state,
                electronical:action.payload,
            
        } 
        case INDIVIDUAL_FORM: return {
            ...state,
                individual:action.payload,
            
        } 
        case SIGNATURE_CARD: return {
            ...state,
                signature:action.payload,

        } 





        default: 
            return state;
    }


}