import React,{ useEffect } from 'react';
// import UsuarioFinal from './UsuarioFinal';
// import AdminCore from './AdminCore'
// import ParticlesBackground from '../Particles/ParticlesBackgroundWhite'
// import bg from '../imagenes/background/home2.png'
// // import LogoW from '../imagenes/atlasBankWhite.png'

// import Menu from '../componentes/KitMenu/Menu.jsx'

// import BGWhite from '../imagenes/bgRound.svg'
// import Nav from '../componentes/Nav/Nav.jsx'

// import BurgerNav from '../componentes/Nav/BurgerNav'
 import LoaderWindow from '../Alertas/LoaderUniversal'
 import useData from '../../hooks/useData'
import DashBoardUltimate from './DashBoardUltimate.jsx';
import CrearForo from '../ventanas/CrearForo.jsx';

// import MainNav from '../componentes/MainNav/MainNav';
// import MainNavController from '../componentes/MainNav/MainNavController';

import UsuarioFinalDomo from './UsuarioFinal';
// import UsuarioFinalBanca from '../A-AB-Banca-en-Linea/dash/UsuarioFinal';
import BAlerta from '../Alertas/BAlerta.jsx';
import WindowsOption from '../ventanas/WindowsOption';
import WindowsOptionSettings from '../ventanas/WindowsOptionSettings';
import WindowsOptionCommunity from '../ventanas/WindowsOptionCommunity';
import WindowsOptionAddComunity from '../ventanas/WindowsOptionAddComunity'
import WindowsOptionGroups from '../ventanas/WindowsOptionGroups';
import UltraUsuario from '../dash/UltraUsuario'


////////////////////// aqui vamos a colcoar las selecciones

import WindowSelect from './DashBoardUltimate/WindowSelect';
import WindowHelp from './DashBoardUltimate/WindowHelp';
import WindowAcc from '../dash/DashBoardUltimate/WindowAccount'
import WindowSearch from '../dash/DashBoardUltimate/WindowSearch'
import WindowMensaje from '../dash/DashBoardUltimate/WindowMensaje'
import WindowNotificaciones from './DashBoardUltimate/WindowNotificaciones';
import WindowCompras from './DashBoardUltimate/WindowCompras';
import WindowFavoritos from './DashBoardUltimate/WindowFavoritos';
import WindowIdioma from './DashBoardUltimate/WindowIdioma';
import useAct from '../hooks/useAct';

const Cliente = () => {
    const {
        Loader, 
        coreadmin, 
        MostrarProfile, 
        MostrarForos,
        MostrarComunidades,
        ventanauno,
        OpenVentana1,
        ventanados,
        OpenVentana2,
        ventanatres,
        OpenVentana3,
        ventanacuatro,
        OpenVentana4,
        ventanacinco,
        OpenVentana5,
        ventanaseis,
        OpenVentana6,
        windowalerta,
      } = useData();
        // const token = localStorage.getItem('token') ;

        // const tokenAuth = token =>  {
        //     if(token) {
        //             clienteAxios.defaults.headers.common['Authorization'] = `Bearer ${token}` ;
        //     } else {
        //         delete clienteAxios.defaults.headers.common['Authorization']
        //     }
        // } 

        // tokenAuth(token)
    
    useEffect(

        
        () => {         
            // tokenAuth(token)

                            //  MostrarProfile()
                            //  MostrarForos()
                            //  MostrarComunidades()
                                
        },[]
    )


    return (  

            <>

            <>



            {Loader !== false ? <>
                    <div className="loader-seudo">
                        <LoaderWindow/>

                    </div>
                </> : null

                }




                {windowalerta.open !== false ? <>
                                    <div className="loader-seudo">
                                        <BAlerta/>

                                    </div>
                                </> : null

                         }


            </>

            {ventanauno !== false ? <>
                    <div className="loader-seudo">
                        <CrearForo/>

                    </div>
                </> : null

                }

                

                       
           
                
         
{/* 
                    <div className={`loader-seudo  ${ventanacuatro !== false ? ' op-true  ':''}  ${ventanacuatro !== true ?'animation-open-option-close transform-lejos op-none':''}`}>
                        <WindowsOptionCommunity/>

                    </div>

                    <div className={`loader-seudo  ${ventanaseis !== false ? ' op-true  ':''}  ${ventanaseis !== true ?'animation-open-option-close transform-lejos op-none':''}`}>
                        <WindowsOptionGroups/>

                    </div>

                    <div className={`loader-seudo  ${ventanados !== false ? ' op-true  ':''}  ${ventanados !== true ?'animation-open-option-close transform-lejos op-none':''}`}>
                        <WindowsOption/>

                    </div>

                    

                    <div className={`loader-seudo  ${ventanatres !== false ? ' op-true  ':''}  ${ventanatres !== true ?'animation-open-option-close transform-lejos op-none':''}`}>
                        <WindowsOptionSettings/>

                    </div>

                    
                    <div className={`loader-seudo  ${ventanacinco !== false ? ' op-true  ':''}  ${ventanacinco !== true ?'animation-open-option-close transform-lejos op-none':''}`}>
                        <WindowsOptionAddComunity/>

                    </div> */}

                    <WindowFavoritos/>
                    <WindowMensaje/>
                    <WindowSearch/>
                    <WindowAcc/>
                    <WindowHelp/>
                    <WindowSelect/> 
                   
                    <WindowNotificaciones/>
                    <WindowCompras/>
                    <WindowIdioma/>
                    <UltraUsuario/>
                    {/* <UsuarioFinalDomo/> */}
                   

             {/* <DashBoardUltimate/>       */}

            {/* { coreadmin === 2 ? 
            
            <AdminCore/>:
             null
            }

            {
                coreadmin === 1 ?

                <UsuarioFinal/>: null


            } */} 


           



            </>


    );
}
 
export default Cliente;