import React from 'react'
import ABlue from '../../imagenes/atlasBankWhiteIcon.png'
import useAuth from '../../hooks/useAuth'
import useData from '../../hooks/useData'

const CrearForo = () => {

    const {createform, setCreateForum, AddForm, OpenVentana1} = useData()

    const onChange = e => {
        // voy a colocar guardar usuario en el state
        setCreateForum({
            ...createform,
            [e.target.name] : e.target.value
    
        })
    }
    const onSubmit = e => {
        e.preventDefault();
        AddForm(createform)
      } 
    


    return (  

            <>
            


            <div className="contenedor ventana-white ">
                <section className="main open-opacity-fx">

                    <section className="section-maestro">
                    <div className="color-domo Heigh-100 list-ajust text-white">
                        {"Domo Forum"}

</div>
                        <section className=" color-gray main open-opacity-fx Heigh-100 text-white">

    <div className="box-title-universal">
        <h2>
            Create a Forum
        </h2>

        <p>
        Create your own forum on our application. Connect with people who share your interests and start discussions focused on specific topics.
         Personalize your forum with an attractive title and description, so others can comment and share.
        </p>

    </div>

        <form onSubmit={onSubmit}  className="form-box">

            <div className="input-domo-ultra">
                <label>Title</label>
                <input
                                            type="text"
                                            id="title"
                                            name="title"
                                            onChange={onChange}
                                            value={createform.title == null ? '' : createform.title}                
                />
            </div>
            <div className="input-domo-ultra">
                <label>Description</label>
                <input
                                    type="text"
                                    id="description"
                                    name="description"
                                    onChange={onChange}
                                    value={createform.description == null ? '' : createform.description}
                />

            </div>
            <div className="input-domo-ultra">
                <button type="submit" className="text-white">Send</button>
            </div>






        </form>







</section>
                    <div className="color-domo Heigh-100 list-ajust-2 text-white  " >
                        <button className="btn-domo-ultra" onClick={() => OpenVentana1()}>X</button>

</div>

                    </section>

               

                </section>
            </div>
            
            </>


    );
}
 
export default CrearForo;