import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc'
import Paises from './Paises'
import Idioma from './Idioma'
import boa from '../../imagenes/iconoscuenta/flechaup.svg'
import boo from '../../imagenes/iconoscuenta/flechadown.svg'



const InputBurger = ({info, info2, info3, firstlabel, seclabel, inputname, action, model, inputlabel,onFocus, treslabel, cuatrolabel, quintolabel }) => {

    const {
        alessa,
        MeEncantas,
        focusinput, 
        setFocusInput,
        focus,
        setFocus,
        focusselect , setFocusSelect
    } = useAcc()
        
const [n, setN] = useState(false)

const openN = () => {
    setN(!n)
    setFocusInput(inputname)
    if (focusinput === inputname) {
        setFocusInput('')
    }
    setFocus('')
}

const cara = (v) => {
    setFocus(v)
   
}

const focustime = () => {
    console.log('hola')
}



const onChange = e => {
    // voy a colocar guardar usuario en el state
    setFocusSelect({
         ...focusselect,
        [e.target.name] : e.target.value

    })
    setFocus('')
}

console.log(focus)


useEffect(
    ()=>{
        if(focusinput !== inputname) {
            setN(false)
            setFocusSelect({
                pais:'',
                region:'',
                idioma:''

            })
        }
    },[focusinput]
)

    return(
        <>
                <div className={` magiamagia contenedor-input ${n === false ? '':'border-bottom-active'}`}>

                <div className={`contenedor-header-input `}>
                        <h2 className={`${focusinput === inputname ? 'active-joema' :''}`}>
                            {inputname}
                        </h2>

                        <div onClick={openN}>
                            { n !== true ? 
                            <>
                                <img src={boa} className={`ttt`} />
                            
                               
                            </>:<img src={boo} className={`ttt`}/>}
                        </div>
                </div>
                    {   n === true ? 
                    <>
                    <div className={`information-input `}>
                        <p className={`text-input`}>
                       {info}
                        </p>
                        <p className={`text-input`}>
                       {info2}
                        </p>
                        <p className={`text-input`}>
                       {info3}
                        </p>

                               



                        
                    </div>
                    </>
                    : 
                    null
                    }

                </div>

        </>
    )
}

export default InputBurger;