import React, { useState } from 'react'
import useAct from '../../hooks/useAct';
import useSearchJoe from '../../hooks/useSearchJoe';
import key from '../../imagenes/modalicon/key.svg'
import pets from '../../imagenes/modalicon/pets.svg'
import auto from '../../imagenes/modalicon/auto.svg'
import chat from '../../imagenes/modalicon/chat.svg'
import wifi from '../../imagenes/modalicon/wifi.svg'
import vista from '../../imagenes/modalicon/vista.svg'
import FooterBar from './FooterBar';
import img1x from '../../imagenes/mapimages/PanamaOeste.png'
import img2x from '../../imagenes/mapimages/Bocas-del-Toro.png'
import img3x from '../../imagenes/mapimages/Chiriqui.png'
import img4x from '../../imagenes/mapimages/Cocle.png'
import img5x from '../../imagenes/mapimages/Colon.png'
import img6x from '../../imagenes/mapimages/Darien.png'
import img7x from '../../imagenes/mapimages/GunaYala.png'
import img8x from '../../imagenes/mapimages/Herrera.png'
import img9x from '../../imagenes/mapimages/LosSantos.png'
import img10x from '../../imagenes/mapimages/Ngabe-Bugle.png'
import img11x from '../../imagenes/mapimages/Veraguas.png'
import img12x from '../../imagenes/mapimages/PanamaCiudad.png'
import s from '../../imagenes/iconos/search.svg'
import iconmaestro from '../../imagenes/filtericon/x1.svg'
import { COM, PRO } from '../../microdata/dataLocation'
import LoaderD from '../../componentes/Loader/LoaderD';

//// aqui vamos a colocar el hook del search ////

export const cuenta = [
    {
        id:0,
        T:'Panamá Oeste',
        D:'08',
        icon:img1x,
    },
    {   id:1,
        T:'Bocas del Toro',
        D:'Actualiza la contraseña y protege tu cuenta',
        icon:img2x,

    },
    {   id:2,
        T:'Chiriquí',
        D:'Revisa pagos, cobros, cupones y tarjetas de regalo',
        icon:img3x,

    },
    {   id:3,
        T:'Coclé',
        D:'Administra la información y los documentos fiscales de los contribuyentes',
        icon:img4x,

    },
    {   id:4,
        T:'Colón',
        D:'Elige las preferencias de notificación y tu forma de contacto',
        icon:img5x,

    },
    {   id:5,
        T:'Darien',
        D:'Administra tus datos personales, servicios relacionados y configuración de uso compartido de datos',
        icon:img6x,

    },
    {   id:6,
        T:'Guna Yala',
        D:'Configura tu idioma, moneda y zona horaria',
        icon:img7x,

    },
    {   id:7,
        T:'Herrera',
        D:'Agrega un correo electrónico de empresa para obtener beneficios en los viajes de negocios',
        icon:img8x,

    },
    {   id:8,
        T:'Los Santos',
        D:'Obtén herramientas profesionales si administras varias propiedades en Airbnb',
        icon:img9x,

    },
    {   id:9,
        T:'Ngäbe-Buglé',
        D:'Tienes $0 de crédito y cupones por invitaciones. Más información.',
        icon:img10x,

    },
    {   id:10,
        T:'Veraguas',
        D:'Tienes $0 de crédito y cupones por invitaciones. Más información.',
        icon:img11x,

    },
    {   id:11,
        T:'Panama',
        D:'Tienes $0 de crédito y cupones por invitaciones. Más información.',
        icon:img12x,

    }
    

]


const WindowSearch = () => {

    const {
        location,
        win,
        WinAct,
        img1win, setImg1,
        img2win, setImg2,
        img3win, setImg3,
        img4win, setImg4,
        img5win, setImg5 ,
        winacc,WinAccD,
        winsearch,WinS,
       
    } = useAct() 

    const {
        setSelectPro,
        selectpro,
        setTerm,
        Provincia,
        Distrito,
        Corregimiento,
        p01,
        p02,
        p03,
        term,
        termI,
        setTermI,
        selectdis, 
        setSelectDis,
        termdistrito, setTermDistrito,
        addTermFinal,
        setFinalTerm,
        setTermCorregimiento,
        loeader, 
        oloader,
        cantidadderesultados,
        filtro,
        termcorregimiento
    } = useSearchJoe()

   


    const [profileactive, setProfileAct] = useState(false);
    
    const limpiar = () => {
        setTermCorregimiento("")
    }

    return ( 
            <>
            {
                winsearch === true ?
                 <>
                 
            <div  className={` bg-trans bg-trans-fix ${winsearch !== false ? ' op-true adelante ':''}  ${winsearch !== true ?'animation-open-option-close  op-none':''}`}>

<section className={`candela-closet`} onClick={WinS}>

</section>
<section className="yeah-scroll">
    <section className="box-maestro-search bg-ground" 
    onClick={ () =>{
        // WinS()
        // setTerm("")

    }
    }
    > 

                    <section className="newnewnew-head ">
                    <div className="active-alquiler">
                       Alquiler

                    </div>
                    <div className="">
                        Compra
                        
                    </div>
                    <div>
                        Compra en linea
                    </div>
                    </section>

               <section className="  newnewnew">

                    <div 
                        className={`ss-button ${p01 === true ? 'Active-btn-ss':''}`}
                        >
                            <p className={`elmama ${filtro.provincia !== "" ? 'hopehopehope ' :'hopehopehopeno' }`}> <span>{filtro.provincia}</span> </p>
                      <button onClick={
                        () => {
                            Provincia()
                            setFinalTerm("")
                            setTermDistrito("")
                            setTermCorregimiento("")
                            setTerm("")
                            oloader()
                            

                        }
                        }>
                      Provincia
                      </button>

                    </div>

                    <div 
                        className={`ss-button  ${p02 === true ? 'Active-btn-ss':''}`}
                        onClick={p03 === true ? Distrito  : null}
                        >

                                <p className={`elmama ${filtro.distrito !== "" ? 'hopehopehope ' :'hopehopehopeno' }`}> <span>{filtro.distrito}</span> </p>

                    <button >
                        Distrito


                      </button>
                        
                    </div>

                    <div
                    className={`ss-button ${p03 === true ? 'Active-btn-ss':''}`}
                   
                        onClick={p02 === true && termdistrito !== "" ? Corregimiento  : null}

                        >

                    <p className={`elmama ${filtro.corregimiento !== "" ? 'hopehopehope ' :'hopehopehopeno' }`}> <span>{filtro.corregimiento}</span> </p>
                            <button>
                                Corregimiento
                                
                            </button>
                        
                    </div>

                    <div className="float-btn-joema">
                        <img src={s} />

                    </div>

                </section>
         
       </section>
   

    <section className="">

        <div className={`box-siguiente-brutal ${p02 === true ? 'bx-s-brutal' :''}
            ${p03 === true ? 'bx-s-10' :''}

        `} >    

                            

                        <section className="bottom-information">

                            

                        
                  

                        <span className={`maricon-jones`}> 
                        
                        <p className={` ${filtro.provincia !== "" ? 'hopehopehope willonfx-text' :'hopehopehopeno' }`}> <span>{filtro.provincia}</span> </p>
{/*                                         
                         &nbsp; */}
                        <p className={`${filtro.distrito !== "" ? 'hopehopehope ' :'hopehopehopeno' }`}>&nbsp; <span>{filtro.distrito}</span>  </p>
                        
                     
                        
                        <p className={`${filtro.corregimiento !== "" ? 'hopehopehope ' :'hopehopehopeno' }`}>&nbsp; <span>{filtro.corregimiento}</span> </p>
                        
                        </span>
                       
                        </section>
                        <section className="box-info-master"
                            >
                                {
                                    cantidadderesultados === 0 ? <>
                                   Ups lo sentimos, no se ha encontrado resultados de búsqueda...
                                    </> : 
                                    <><p>Se han encontrado <span className="bombaso-bbb">{cantidadderesultados}</span> resultados de búsqueda</p> </>
                                }
                            </section>
                        <div className={`Loader-style-bottom ${loeader === false ? 'opaopacero':''}`}>
                        <LoaderD/>
                        

                        </div>

            {
                p01 === true ? 
                <div className={`sexy-border-right`}>
                <h2 className="tttt">Busquedas recientes</h2>
                <div className="combo-lista">
                    <img src={iconmaestro}/>
                    <div>
                        <h3>Panama City - Estadías</h3>
                        <p>Cualquier semana</p>
                    </div>
                </div>
                <div className="combo-lista">
                    <img src={iconmaestro}/>
                    <div>
                        <h3>Panama City - Estadías</h3>
                        <p>Cualquier semana</p>
                    </div>
                </div>
                <div className="combo-lista">
                    <img src={iconmaestro}/>
                    <div>
                        <h3>Panama City - Estadías</h3>
                        <p>Cualquier semana</p>
                    </div>
                </div>
                <div className="combo-lista">
                    <img src={iconmaestro}/>
                    <div>
                        <h3>Panama City - Estadías</h3>
                        <p>Cualquier semana</p>
                    </div>
                </div>
            </div>: null
            }


            { p01 === true ?
                 <div className={`sscroll`}>
                 <h2 className="tttt">Seleccione por provincia</h2>

                 <div className="hit-hit">
                 {PRO.map( ree => {
                                                 return(
                                                     
                                                     <div key={ree.id} className={`provincias  ${ ree.provincia === term ? 'mari-mari':''}`} 
                                                     >

                                                         <div className="elpapadelos" onClick={ () => {
                                                             
                                                         setTerm(ree.provincia)
                                                         setSelectPro(ree.distritos)
                                                         setTermI(ree.map)
                                                         Distrito()
                                                         addTermFinal(ree.provincia)
                                                         oloader()
                                                         
                                                     }}
                                                     >
                                                     
                                                         <img src={ree.map} className="icon-normal-size-30" alt="img"/>
                                                         
                                                         <h2 className="">{ree.provincia}</h2>
                                                         </div>
                                                         

                                                     </div>
                                                     
                                                 )
                                         })}
                 </div>
                 </div> :null
            }


            { p02 === true ?
                 <div className={` sscroll sexy-border-right`}>
                 <h2 className="tttt">Seleccione por distrito </h2>
                
                 <div className="sscroll-2 ">
                 {selectpro.map( ree => {
                                                 return(

                                                    <div key={ree.id} className={`combo-lista mangek ${ree.distrito === termdistrito ? 'papitolindo willonfx ':''}`}
                                                        onClick={
                                                            () => { 
                                                                
                                                                setTermDistrito(ree.distrito)
                                                                setSelectDis(ree.corregimientos)
                                                                // Corregimiento()
                                                                addTermFinal(ree.distrito)
                                                                oloader()
                                                            }
                                                        }       
                                                    
                                                    >
                                                    <img src={termI}/>
                                                    <div className="single-box">
                                                        <h3 className={`single-line`}>Distrito de <span> {ree.distrito} </span></h3>
                                                        <p> Provincia de {term}</p>
                                                    </div>
                                                </div>
                                                     
                                                     
                                                     
                                                 )
                                         })}
               </div>
                 </div> :null
            }
             {
                p02 === true ? 
                <div className={`sscroll`}>
                {/* <h2 className="tttt">Busquedas en {term}</h2> */}


                <section className={``}>

                <div className="provincias-2 " 
                                                     >

                                                         <div className="elpapadelos wenwen mari-mari" 
                                                     >
                                                     
                                                         <img src={termI} className="icon-normal-size-302" alt="img"/>
                                                         
                                                         
                                                         </div>

                                                         
                                                        
                                                         

                                                     </div>

                </section>
                <h2 className="text-center"> {termdistrito}</h2>

                { termdistrito !== "" ?

<p className={`text-center`}>
Distrito con {selectdis.length}  corregimientos 

</p>:null
                }

                <p className='text-center'> 
                                            
                                             
                                            </p>
                


                </div>
            
            : null
            }

{ p03 === true ?
                 <div className={` sscroll sexy-border-right`}>
                 <h2 className="tttt">Búsqueda por corregimiento  </h2>
                
                 <div className="sscroll-2 ">
                 {selectdis.map( ree => {
                                                 return(

                                                    <div key={ree.id} className={`combo-lista mangek ${ree.corregimiento === termcorregimiento ? 'papitolindo willonfx ':''}`}
                                                        onClick={
                                                            () => { 
                                                                setTermCorregimiento(ree.corregimiento)
                                                                addTermFinal(ree.corregimiento)
                                                                oloader()
                                                                
                                                            }
                                                        }       
                                                    
                                                    >
                                                    <img src={termI}/>
                                                    <div className="single-box">
                                                        <h3 className={`single-line`}>Corregimiento de <span> {ree.corregimiento} </span></h3>
                                                        <p>Provincia de {term} | Distrito de {termdistrito} </p>
                                                    </div>
                                                </div>
                                                     
                                                     
                                                     
                                                 )
                                         })}
               </div>
                 </div> :null
            }






        </div>

    </section>


    

</section>
</div>
                 </> : null
            }

            </>


     );
}
 
export default WindowSearch;