import React from 'react'
import useAct from '../../hooks/useAct';
import key from '../../imagenes/modalicon/key.svg'
import pets from '../../imagenes/modalicon/pets.svg'
import auto from '../../imagenes/modalicon/auto.svg'
import chat from '../../imagenes/modalicon/chat.svg'
import wifi from '../../imagenes/modalicon/wifi.svg'
import vista from '../../imagenes/modalicon/vista.svg'

const WindowSelect = () => {


    

    const {
        win,
        WinAct,
        img1win, setImg1,
        img2win, setImg2,
        img3win, setImg3,
        img4win, setImg4,
        img5win, setImg5 
    } = useAct()

    

    return ( 
            <>
                {
                    win === true  ? <>

<div onClick={WinAct} className={` windows-selection ${win !== false ? ' op-true adelante ':''}  ${win !== true ?'animation-open-option-close  op-none':''}`}>
                <section className="yeah-scroll">


                 


                    <section className="bar-bar-joema" style={{ background: `url(${img1win})` }}>

                 <p>   GroundScript  </p>   

                    </section>

                    <section className="box-header-joema-modal">
                    <div className="head-bienes">
                        <h2>Cabaña Mountain View</h2>
                    </div>
                    

                    <div className="body-bienes">
                                <div className="ground-ground">
                                <p>4.68</p>
                                <p>41 reseñas</p>
                                <p>Ciudad de Panama, Ancon, Clayton</p>
                            </div>
                            <div className="ground-ground text-center">
                                <p>Compartir</p>
                                <p>Guardar</p>
                            </div>
                    </div>
                    </section>

                    <section className="box-images-joema">

                        <div className="box-img-1-joema">
                            
                            <div
                            className=""  style={{ background: `url(${img1win})` }} 
                            >
                                
                            </div>

                        </div>
                         
                        <div className="box-img-2-joema">

                        <div
                            className=""  style={{ background: `url(${img2win})` }} 
                            >
                                
                            </div>


                            <div
                            className=""  style={{ background: `url(${img3win})` }} 
                            >
                                
                            </div>


                            <div
                            className=""  style={{ background: `url(${img4win})` }} 
                            >
                                
                            </div>


                            <div
                            className=""  style={{ background: `url(${img5win})` }} 
                            >
                                
                            </div>

                            
                        

                        </div>

                    </section>


                    <section className="box-information">

                        <div className="box-header-general">
                        <div className="head-bienes">
                        <h2>Minicasa - Anfitrión: Ronangel</h2>
                    </div>
                    

                    <div className="body-bienes">
                                <div className="ground-ground">
                                <p>2huespedes</p>
                                <p>- 1habitación</p>
                                <p>- 1 cama</p>
                                <p>- 1 Baño</p>
                            </div>
                            <div className="ground-ground text-center">
                                <p>Compartir</p>
                                <p>Guardar</p>
                            </div>
                    </div>


                        </div>

                        <div className="box-header-general box-header-general-extra">

                            <div className="icon-card-yeah-xx">

                                <div className="">
                                    <img src={key} className="icon-normal-size"/>
                                </div>
                                
                                <div className="pl-2">
                                    <h3>¡Fascinante experiencia de llegada!</h3>
                                    <p>El 95 % de los últimos huéspedes han valorado con 5 estrellas el proceso de llegada.</p>
                                </div>

                            </div>

                            <div className="icon-card-yeah-xx">
                                            <div className="">
                                                <img src={pets} className="icon-normal-size"/>
                                            </div>

                                            <div className="pl-2">
                                                <h3>Las mascotas son bienvenidas</h3>
                                                <p>Lleva a tus mascotas al alojamiento.</p>
                                            </div>
                             </div>  
                             <div className="icon-card-yeah-xx">
                                            <div className="">
                                                <img src={chat} className="icon-normal-size"/>
                                            </div>

                                            <div className="pl-2">
                                                <h3>Gran comunicación</h3>
                                                <p>El 90 % de los huéspedes recientes evaluaron a Ronangel con una calificación de 5 estrellas en comunicación.</p>
                                            </div>
                             </div>

                        </div>

                        <div className="box-header-general edit-air">

                            <h3>AirGroundscript</h3>

                            <p>Todas las reservaciones incluyen protección gratuita en caso de que el anfitrión cancele, de que haya imprecisiones en el anuncio o de que surjan otros inconvenientes, como problemas al momento de hacer el check-in.</p>
                            <button>Más información</button>


                        </div>

                        <div className="box-header-general edit-air-services">

                                <h3>Lo que este lugar ofrece</h3>

                                <div className="grid-out">
                                <div className="icon-card-yeah-xx">
                                    <div className="">
                                        <img src={wifi} className="icon-normal-size-2"/>
                                    </div>
                                    <div className="pl-2">
                                        <p>WiFi</p>
                                    
                                    </div>
                                </div>
                                <div className="icon-card-yeah-xx">
                                    <div className="">
                                        <img src={auto} className="icon-normal-size-2"/>
                                    </div>
                                    <div className="pl-2">
                                        <p>Estacionamiento</p>
                                    
                                    </div>
                                </div>
                                <div className="icon-card-yeah-xx">
                                    <div className="">
                                        <img src={vista} className="icon-normal-size-2"/>
                                    </div>
                                    <div className="pl-2">
                                        <p>Vista al océano</p>
                                    
                                    </div>
                                </div>
                                <div className="icon-card-yeah-xx">
                                    <div className="">
                                        <img src={vista} className="icon-normal-size-2"/>
                                    </div>
                                    <div className="pl-2">
                                        <p>Vista natural</p>
                                    
                                    </div>
                                </div>

                                </div>

                              


                                <button>Mostrar todos los servicios</button>


                        </div>





                    </section>

                    <section className="windows-selection-hola-read">
                                <section className="airbox-joema">
                                <div className="air-box-bx">
                                        <h2>Asistencia</h2>
                                        <p>Centro de ayuda</p>
                                        <p>GroundScripCover</p>
                                        <p>Apoyo a las personas con discapacidad</p>
                                        <p>Opciones de cancelacion</p>
                                        <p>Nuestra respuesta frente al COVID-19</p>
                                        <p>Denunciar un problema en el vecindario</p>
                                    </div>
                                
                                    <div className="air-box-bx">
                                        <h2>Comunidad</h2>
                                        <p>Luchamos contra la discriminacion</p>
                                        <p>Alojamiento de ayuda en caso de catastrofe</p>
                                    
                                    </div>
                                
                                    <div className="air-box-bx">
                                        <h2>Modo corredor</h2>
                                        <p>Pon tu propiedad</p>
                                        <p>GroundScripCover para corredores </p>
                                        <p>Apoyo a las personas con discapacidad</p>
                                        <p>Recursos para corredores</p>
                                        <p>Visita el foro de la comunidad</p>
                                        <p>Cómo brindar servicios de Corredor de forma responsable</p>
                                    </div>
                                
                                    <div className="air-box-bx">
                                        <h2>GroundScrip air</h2>
                                        <p>Sala de prensa</p>
                                        <p>Más información</p>
                                        <p>Carta de nuestros fundadores</p>
                                        <p>Carreras</p>
                                        <p>Inversionistas</p>
                                
                                    </div>
                                </section>
                          </section>


                    

                    

                </section>
            </div>



                    </> : null
                }
            
         
            </>


     );
}
 
export default WindowSelect;