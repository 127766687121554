import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios';
import useAuth from '../hooks/useAuth';


const token = localStorage.getItem('token')

const clienteAxios = axios.create({


    baseURL : "https://strprisma.com/thedome/webservice",
    headers: {
        'Content-Type' : 'application/json',
        // 'Authorization' : `${token ? `Bearer ${token}`: 'calma'}`,
        'Accept' : "application/json"
    }


});

const tokenAuth = token =>  {
    if(token) {
            clienteAxios.defaults.headers.common['Authorization'] = `Bearer ${token}` ;
            // console.log('Enfocado')
            
    } else {
        delete clienteAxios.defaults.headers.common['Authorization']
        // console.log('Protegido')
    }
} 

tokenAuth(token)


export default clienteAxios;

// esto lo vamos a pasar al state  


// const axiosAuth = axios.create({
//     baseURL : "https://strprisma.com/thedome/webservice",
//     headers: {
//         'Content-Type' : 'application/json',
//         'Accept' : "application/json"
//     }
// });


// export { axiosAuth }


