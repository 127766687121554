import React from 'react';



const Forgot = () => {
    return (  


        <>hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
        </>



    );
}
 
export default Forgot;