import React, { useState, useEffect } from 'react'
import useAct from '../../hooks/useAct';
import key from '../../imagenes/modalicon/key.svg'
import pets from '../../imagenes/modalicon/pets.svg'
import auto from '../../imagenes/modalicon/auto.svg'
import chat from '../../imagenes/modalicon/chat.svg'
import wifi from '../../imagenes/modalicon/wifi.svg'
import vista from '../../imagenes/modalicon/vista.svg'
import FooterBar from './FooterBar';
import img1x from '../../imagenes/iconosacc/datos.svg'
import img2x from '../../imagenes/iconosacc/inicio.svg'
import img3x from '../../imagenes/iconosacc/x3.svg'
import img4x from '../../imagenes/iconosacc/x4.svg'
import img5x from '../../imagenes/iconosacc/x5.svg'
import img6x from '../../imagenes/iconosacc/x6.svg'
import img7x from '../../imagenes/iconosacc/x7.svg'
import img8x from '../../imagenes/iconosacc/x8.svg'
import img9x from '../../imagenes/iconosacc/x9.svg'
import img10x from '../../imagenes/iconosacc/x10.svg'
import allmensaje from '../../imagenes/iconos/allmensaje.svg'
import x from '../../imagenes/iconos/x.svg'

const WindowFavoritos = () => {

    const cuenta = [
            {
                id:1,
                T:'Datos personales',
                D:'Proporciona tus datos personales e indícanos cómo podemos ponernos en contacto contigo',
                icon:img1x,
            },
            {   id:2,
                T:'Inicio de sesión y seguridad',
                D:'Actualiza la contraseña y protege tu cuenta',
                icon:img2x,

            },
            {   id:3,
                T:'Pagos y cobros',  
                D:'Revisa pagos, cobros, cupones y tarjetas de regalo',
                icon:img3x,

            },
            {   id:4,
                T:'Impuestos',
                D:'Administra la información y los documentos fiscales de los contribuyentes',
                icon:img4x,

            },
            {   id:5,
                T:'Notificaciones',
                D:'Elige las preferencias de notificación y tu forma de contacto',
                icon:img5x,

            },
            {   id:6,
                T:'Privacidad y uso compartido',
                D:'Administra tus datos personales, servicios relacionados y configuración de uso compartido de datos',
                icon:img6x,

            },
            {   id:7,
                T:'Preferencias globales',
                D:'Configura tu idioma, moneda y zona horaria',
                icon:img7x,

            },
            {   id:8,
                T:'Viajes de trabajo',
                D:'Agrega un correo electrónico de empresa para obtener beneficios en los viajes de negocios',
                icon:img8x,

            },
            {   id:9,
                T:'Herramientas para corredores profesionales',
                D:'Obtén herramientas profesionales si administras varias propiedades en Airbnb',
                icon:img9x,

            },
            {   id:10,
                T:'Crédito y cupones por invitaciones',
                D:'Tienes $0 de crédito y cupones por invitaciones. Más información.',
                icon:img10x,

            }
            

    ]

    const {
        win,
        WinAct,
        img1win, setImg1,
        img2win, setImg2,
        img3win, setImg3,
        img4win, setImg4,
        img5win, setImg5,
        winfavoritos, WinFav,
    } = useAct()

    const [profileactive, setProfileAct] = useState(false);
    const [estadoselection, stSelect] = useState(0);
    const StateZona = () => {
        stSelect(0)
    }
    const PriZona = () => {
        stSelect(1)
    }
    const SecZona = () => {
        stSelect(2)
    }
    const TreZona = () => {
        stSelect(3)
    } 

    /////  aqui vamos a coloar el estado del detalles /////

    const [detalles, setDetalle] = useState(true);
    const ActDetalles = () => {
        setDetalle(!detalles)
    }
    useEffect(
        () => {         
            if(detalles === false) {
                SecZona()
            }
            if(detalles === true) {
                TreZona()
            }
                                
        },[detalles]
    )

    

    return ( 
            <>
          {winfavoritos === true ?
            <> 
                  <div  className={` windows-selection ${winfavoritos !== false ? ' op-true adelante willon  ':''}  ${winfavoritos !== true ? 'animation-open-option-close  op-none':''}`}>
                <section className="yeah-scroll">
                <section className="bar-bar-joema" 
                    onClick={ () => {
                        WinFav();
                        stSelect(0);
                    }}
                    > 

                        <section className={`Bar-bar-joema-float`}>
                        <p>   GroundScript   </p>   
                        </section>

                    </section>
                    <section className="box-information">   
                    
                    <section className={`arc d-none ${detalles === false ? 'arc-closet ' : ''}`}>
                                <section 
                                    className={` 
                                    mensaje-1
                                    `}
                                    onClick={
                                        estadoselection !== 1 ? PriZona : StateZona
                                    }
                                >       
                            <div className={`hijo-hijo-hijo
                                        ${estadoselection === 1 ? 'opacity-true' : ''}
                                    `}>
                                        <span className="hijo-center">
                                        </span>
                                        <span className="hijo-left">
                                        </span>
                                    </div>
                                    <section className="box-cloud">
                                       <div className="box-cloud-def">
                                       <p>Mensajes</p>
                                        <div className="img-arc">
                                        <img src={allmensaje}/>
                                        </div>
                                       </div>
                                    </section>

                                    <section className="box-cloud-body">

                                    <div className="box-cloud-def-body">

                                        <h3>No tienes mensajes sin leer</h3>
                                        <p>Cuando reserves un viaje o una experiencia, los mensajes de tu anfitrión aparecerán aquí.</p>

                                        <button className="btn-bienes">
                                            Explorar GroundScript
                                        </button>

                                        </div>
                                    </section>



                                </section>


                                <section className={`mensaje-expand`}>


                                <section
                                        className={` 
                                        mensaje-2
                                      
                                        ${detalles === false ? '  width-true-true' : ''}
                                        `}
                                        onClick={
                                            estadoselection !== 2 ? SecZona : StateZona
                                        }

                                >

                                    <div className={`hijo-hijo-hijo
                                        ${estadoselection === 2 ? 'opacity-true' : ''}
                                    `}>

                                        <span className="hijo-left">

                                        </span>

                                        <span className="hijo-center">

                                        </span>
                                        <span className="hijo-right">

                                        </span>
                                    </div>

                                <section className="box-cloud"
                                     onClick={

                                        () => {
                                            ActDetalles()
                                          

                                        }

                                     }
                                >

                                   

                                    </section>

                                    
                                </section>

                                
                                <section 
                                    className={` 
                                    mensaje-3
                                    ${detalles === false ? 'width-false' : ''}
                                    
                                    `}
                                    onClick={estadoselection !== 3 ? TreZona : StateZona }
                                
                                >

                                <div className={`hijo-hijo-hijo
                                        ${estadoselection === 3 ? 'opacity-true' : ''}
                                    `}>
                                        <span className="hijo-center">

                                        </span>
                                        <span className="hijo-right">

                                        </span>
                                    </div>

                                <section className="box-cloud" > 

                                       <div className="box-cloud-def">

                                       <p>Detalles</p>
                                        <div className="img-arc"
                                        onClick={
                                                () => {
                                                    ActDetalles()
                                                    
                                                  

                                                }
                                        }
                                            >
                                            <img 
                                            src={x}/>
                                        </div>

                                       </div>

                                    </section>
                                    
                                </section>


                                </section>

                                
                                

                               </section>
                    

                    <section className={`favoritos-style`} >
                        <section className={`favoritos-mensaje`}>

                                            <h3 className="">
                                            Favoritos
                                            </h3>

                                            <h4>Crea tu primera lista de favoritos</h4>

                                            <p>Mientras buscas, haz clic en el ícono del corazón para guardar los alojamientos y Experiencias que más te gusten en tus favoritos.</p>
                        </section>



                    </section>

                            
                
                    </section>


                    

                    

                </section>
            </div>
            
            </> : null  
        
        }
            </>


     );
}
 
export default WindowFavoritos;