import React, { useState, useEffect } from 'react'
import useAct from '../../hooks/useAct';
import useAcc from '../../hooks/useAcc';
import key from '../../imagenes/modalicon/key.svg';
import pets from '../../imagenes/modalicon/pets.svg';
import auto from '../../imagenes/modalicon/auto.svg';
import chat from '../../imagenes/modalicon/chat.svg';
import wifi from '../../imagenes/modalicon/wifi.svg';
import vista from '../../imagenes/modalicon/vista.svg';

import FooterBar from './FooterBar';
import img1x from '../../imagenes/iconosacc/datos.svg';
import img2x from '../../imagenes/iconosacc/inicio.svg';
import img3x from '../../imagenes/iconosacc/x3.svg';
import img4x from '../../imagenes/iconosacc/x4.svg';
import img5x from '../../imagenes/iconosacc/x5.svg';
import img6x from '../../imagenes/iconosacc/x6.svg';
import img7x from '../../imagenes/iconosacc/x7.svg';
import img8x from '../../imagenes/iconosacc/x8.svg';
import img9x from '../../imagenes/iconosacc/x9.svg';
import img10x from '../../imagenes/iconosacc/x10.svg';

import CreditoYCuponesPorInvitacion from '../Cuenta/CreditoYCuponesPorInvitaciones';
import DatosPersonales from '../Cuenta/DatosPersonales';
import HerramientasParaCorredoresProfesionales from '../Cuenta/HerramientasParaCorredoresProfesionales';
import Impuestos from '../Cuenta/Impuestos';
import InicioDeSesionYSeguridad from '../Cuenta/InicioDeSesionYSeguridad';
import Notificaciones from '../Cuenta/Notificaciones';
import PagosYCobros from '../Cuenta/PagosYCobros';
import PreferenciasGlobales from '../Cuenta/PreferenciasGlobales';
import PrivacidadYUsoCompartido from '../Cuenta/PrivacidadYUsoCompartido';
import ViajesDeTrabajo from '../Cuenta/ViajesDeTrabajo';

const WindowAccount = () => {
    const rambo = [
            {
                id:1,
                T:'Datos personales',
                D:'Proporciona tus datos personales e indícanos cómo podemos ponernos en contacto contigo',
                icon:img1x,
            },
            {   id:2,
                T:'Inicio de sesión y seguridad',
                D:'Actualiza la contraseña y protege tu cuenta',
                icon:img2x,
            },
            {   id:3,
                T:'Pagos y cobros',  
                D:'Revisa pagos, cobros, cupones y tarjetas de regalo',
                icon:img3x,
            },
            {   id:4,
                T:'Impuestos',
                D:'Administra la información y los documentos fiscales de los contribuyentes',
                icon:img4x,

            },
            {   id:5,
                T:'Notificaciones',
                D:'Elige las preferencias de notificación y tu forma de contacto',
                icon:img5x,
            },
            {   id:6,
                T:'Privacidad y uso compartido',
                D:'Administra tus datos personales, servicios relacionados y configuración de uso compartido de datos',
                icon:img6x,
            },
            {   id:7,
                T:'Preferencias globales',
                D:'Configura tu idioma, moneda y zona horaria',
                icon:img7x,
            },
            {   id:8,
                T:'Viajes de trabajo',
                D:'Agrega un correo electrónico de empresa para obtener beneficios en los viajes de negocios',
                icon:img8x,
            },
            {   id:9,
                T:'Herramientas para corredores profesionales',
                D:'Obtén herramientas profesionales si administras varias propiedades en Airbnb',
                icon:img9x,
            },
            {   id:10,
                T:'Crédito y cupones por invitaciones',
                D:'Tienes $0 de crédito y cupones por invitaciones. Más información.',
                icon:img10x,
            }
    ]

    

    const {
        win,
        WinAct,
        img1win, setImg1,
        img2win, setImg2,
        img3win, setImg3,
        img4win, setImg4,
        img5win, setImg5 ,
        winacc,WinAccD,
    } = useAct() 
    const {
        cupones,
        Tcupones,
        datospersonales,
        Tdatospersonales,
        herramientacorredores,
        Therramienta,
        TImpuesto,
        seguridad,
        TSeguridad,
        notificaciones,
        TNotificaciones,
        pagosycobros,
        TPagosYCobros,
        preferencias,
        TPreferencias,
        TPrivacidad,
        privacidad,
        TViajesDeTrabajo,
        viajesdetrabajo, 
        alessa, 
        AlessandraMontoya,
        MeEncantas,
        activarSc,
        activarscroll
    } = useAcc()
    const [profileactive, setProfileAct] = useState(false);
    const list = {
        CreditoYCuponesPorInvitacion,
        DatosPersonales,
        HerramientasParaCorredoresProfesionales,
        Impuestos,
        InicioDeSesionYSeguridad,
        Notificaciones,
        PagosYCobros,
        PreferenciasGlobales,
        PrivacidadYUsoCompartido,
        ViajesDeTrabajo,
    }

  

    return ( 
            <>

            { winacc === true ? <> 
            
                
                <div  className={` windows-selection ${winacc !== false ? ' op-true adelante ':''}  ${winacc !== true ?'animation-open-option-close  op-none':''}`}>
                <section className="yeah-scroll">
                    <section className="bar-bar-joema" 
                    onClick={WinAccD}
                    > 

                        <section className={`Bar-bar-joema-float`}>
                        <p>   GroundScript  </p>   
                        </section>

                    </section>
                    <section className="box-information">   
                                {
                                    profileactive === false ? 
                                    <>
                                    {
                                            alessa.id === null ? 
                                            <>  
                                            <div className="box-header-general">
                                                    <div className="head-bienes my-5">
                                                            <h2 className="font-perfil-t">Cuenta </h2>
                                                            <p className="font-perfil">Joel Mariscal,<span>joelmariscalflorez@gmail.com .</span> 
                                                            <span className="subline" onClick={ () => {setProfileAct(true)}}>ir a perfil</span></p>
                                                    </div>
                                            </div>
                                            <div className="box-header-general magico-perfil">
                                                    {rambo.map((c, index) => {
                                                            return(
                                                                <div key={index} className="box-option-account-perfil bg-ground-y" onClick={() => {
                                                                    AlessandraMontoya({
                                                                        id:c.id,
                                                                        T:c.T,
                                                                    })
                                                                    activarSc()
                                                                   
                                                                }}>
                                                                    <div className="box-box-box-box">
                                                                    <img src={c.icon} className="icon-normal-size" alt="img"/>
                                                                    <h2 className="">{c.T}</h2>
                                                                    <p className="">{c.D}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                    })}
                                            </div>
                                            <div className="box-header-general desact-acc">
                                                        <div className="">
                                                        <p>¿Necesitas desactivar la cuenta?</p>
                                                        <span> Ocúpate de esto ahora</span>
                                                        </div>
                                            </div>


                                            </> : 
                                                <>
                                                    <list.CreditoYCuponesPorInvitacion/>
                                                    <list.DatosPersonales/>
                                                    <list.HerramientasParaCorredoresProfesionales/>
                                                    <list.Impuestos/>
                                                    <list.InicioDeSesionYSeguridad/>
                                                    <list.Notificaciones/>
                                                    <list.PagosYCobros/>
                                                    <list.PreferenciasGlobales/>
                                                    <list.PrivacidadYUsoCompartido/>
                                                    <list.ViajesDeTrabajo/>
                                                </>
                                    }                
                                    </>
                                    : 
                                    <>
                                    <div onClick={()=> {setProfileAct(false)}}>
                                        <div className="box-header-general ppprofile">
                                                <div className="picture-col">
                                                <div className="box-header-general box1box1 bg-ground-y ">
                                                        <div className="head-bienes my-5 profileman ">
                                                            <div>
                                                            <img className="icon-normal-size mauto" src={img1x} />
                                                            </div>
                                                        <h2 className="font-perfil-t">Joel </h2>
                                                        <p className="font-perfil">Huésped</p>
                                                        </div>
                                                </div>
                                                <div className="box-header-general box1box1 bg-ground-y">
                                                        <div className="head-bienes my-5">
                                                        <h2 className="font-perfil-t">Información confirmada de Joel </h2>
                                                        <p className="font-perfil">Dirección de correo electrónico</p>
                                                        <span className="barbarraya"></span>
                                                        <h2 className="font-perfil-t">Verificación de identidad</h2>
                                                        <p className="font-perfil">Agrega el distintivo de verificación de identidad para que el resto de la comunidad sepa que eres quien dices ser.</p>
                                                        <button className="btn-domo">Obtén la insignia</button>
                                                        </div>
                                                </div>
                                                </div>
                                                <div className="box-header-general">
                                                        <div className="head-bienes my-5">
                                                        <h2 className="font-perfil-t">Es hora de crear tu perfil </h2>
                                                        <p className="font-mm">Tu perfil en GroundScript es una parte importante de todas las reservaciones. Crea el tuyo para que los demás corredores y huéspedes te conozcan mejor.</p>
                                                        <button className="btn-domo">Crear un perfil</button>
                                                        </div>
                                                </div>
                                        </div>
                                    </div>
                                    </>
                                }
                    </section>
                    <section className="windows-selection-hola-read fitfix">
                                <section className="airbox-joema">
                                <div className="air-box-bx">
                                        <h2>Asistencia</h2>
                                        <p>Centro de ayuda</p>
                                        <p>GivartsCover</p>
                                        <p>Apoyo a las personas con discapacidad</p>
                                        <p>Opciones de cancelacion</p>
                                        <p>Nuestra respuesta frente al COVID-19</p>
                                        <p>Denunciar un problema en el vecindario</p>
                                    </div>
                                    <div className="air-box-bx">
                                        <h2>Comunidad</h2>
                                        <p>Luchamos contra la discriminacion</p>
                                        <p>Alojamiento de ayuda en caso de catastrofe</p>
                                    </div>
                                    <div className="air-box-bx">
                                        <h2>Modo corredor</h2>
                                        <p>Pon tu propiedad</p>
                                        <p>GivartsCover para corredores </p>
                                        <p>Apoyo a las personas con discapacidad</p>
                                        <p>Recursos para corredores</p>
                                        <p>Visita el foro de la comunidad</p>
                                        <p>Cómo brindar servicios de Corredor de forma responsable</p>
                                    </div>
                                    <div className="air-box-bx">
                                        <h2>Givarts air</h2>
                                        <p>Sala de prensa</p>
                                        <p>Más información</p>
                                        <p>Carta de nuestros fundadores</p>
                                        <p>Carreras</p>
                                        <p>Inversionistas</p>
                                    </div>
                                </section>
                                <section className="footbar-statik">
                                    <FooterBar/>
                                </section>
                          </section>


                    

                    

                </section>
            </div>
            
            </> : null 

            }
            
            </>


     );
}
 
export default WindowAccount;