import React, { useState, createContext, useEffect} from 'react'
import clienteAxios from '../../config/axios'
import { Link, useNavigate } from 'react-router-dom'
import {  PRO , COM } from '../../microdata/dataLocation'




const ActJoemaContext = createContext();

const ActJoemaProvider = ({children}) => {
    const [location, setLoc] = useState({
        comarcas:COM,
        provincias:PRO,
    
    });
    // console.log(location.provincias[0])
    //// aqui vamos a colocar el estado del menu peque;o
    const [open, setO] = useState(false)
    const toggle = () => {
        setO(!open)
    }
    //// aqui vamos a colocar el estado de las ventanas principales
    const [win, setWin1 ] = useState(false);
    const WinAct = () => {
        setWin1(!win)
    } 
    const [winlow, setWinLow] = useState(false);
    const WinActDos = () => {
        setWinLow(!winlow)
    }
    /////// esta estado hace referencia a las cuentas de perfil, configuraciones y esoo
    const [winacc, setWinAcc] = useState(false);
    const WinAccD = () => {
        setWinAcc(!winacc)
    }
    //// este estado responde a la ventana de busqueda general ,
    const [winsearch, setWinS ] = useState(false);
    const WinS = ()=>{
        setWinS(!winsearch)
    }
    ///// este estado , no estoy muy seguro, creo que repsonde a los mensajes
    const [winmensaje, setWinMensaje] = useState(false);
    const WinM = () => {
        setWinMensaje(!winmensaje)
    }
    /// este estado es para las notificaciones
    const [winnotificaciones, setWinNot] = useState(false);
    const WinNot = ()=> {
        setWinNot(!winnotificaciones)
    }
    ///// este proximo estado es para las compras
    const [wincompras, setWinCompras] = useState(false);
    const WinCompras = () => {
        setWinCompras(!wincompras);
    }
    ////// este proximo estado es para los favoritos
    const [winfavoritos, setWinFav] = useState(false);
    const WinFav = () => {
        setWinFav(!winfavoritos);
    }
    //// este proximo estado es para los idiomas y la moneda
    const [winidioma, setWinIdioma] = useState(false);
    const WinIdioma = () => { 
        setWinIdioma(!winidioma);
    }
    const [img1win, setImg1] = useState('loading')
    const [img2win, setImg2] = useState('loading')
    const [img3win, setImg3] = useState('loading')
    const [img4win, setImg4] = useState('loading')
    const [img5win, setImg5] = useState('loading')
    return (
        <ActJoemaContext.Provider
            value={{
                location, /// lugares
                open, /// menu
                toggle, // menu
                setO, /// menu
                win,
                WinAct,
                img1win, setImg1, /// MODAL 1 
                img2win, setImg2, /// MODAL 1
                img3win, setImg3, /// MODAL 1
                img4win, setImg4, /// MDOAL 1
                img5win, setImg5, /// MODAL 1
                winlow, setWinLow, // Footer
                WinActDos,
                winacc,WinAccD, //perfil 
                winsearch,WinS, // busqueda
                winmensaje, WinM, // mensajes
                winnotificaciones, WinNot, // notificaciones
                wincompras, WinCompras, // compras
                winfavoritos,WinFav, // favoritos
                winidioma, WinIdioma  // idioma y moneda
            }}
        >


            {children}
        </ActJoemaContext.Provider>

    )


}

export {
    ActJoemaProvider
}

export default ActJoemaContext;