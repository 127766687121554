import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SignatureContext from '../../../context/StepLogical/Signature/signatureContext'
import StepLogicalContext from '../../../context/StepLogical/steplogicalContext'

import PropTypes from 'prop-types'
import useAuth from '../../../hooks/useAuth'





const SignatureCard = ({animationOn , ActivarPrimero,
  ActivarSec,
  ActivarTres,
  ActivarCuatro,
  ActivarQuinto,CloseWindows}) => {


    const { lang } = useAuth()


  const signatureContext = useContext(SignatureContext);
  const { primero, segundo,Complete } = signatureContext;

  const steplogicalContext = useContext(StepLogicalContext);
  const { CompleteSignature } = steplogicalContext;

  
  const Fin = () => {
    
    CompleteSignature()
  }

  const navigate = useNavigate()

  const PDF = () => {
      navigate('/external-link')
  }






  








    return (  


            <>

            <div>
                <div className="container-form-tittle ">

                <h2>{`${lang.data.SignatureCard.sign}`}</h2>
                
                <p>{`${lang.data.SignatureCard.sign_sub}`}</p>

                <strong >
                    
                <p>Page { primero === true ?  '1' : null}
                
                
                </p>
                </strong>




                
                



                </div>

                { primero === true ? 

                    <>
                      <p className="BoxTittle">
                      {`${lang.data.SignatureCard.sign_cuento}`}
                    </p>
                    <div className="container-form-window">

                   

                      

                      {/* <PDFViewer
                        document={{
                            url: 'https://www.vivapancafe.com/atlas/formularios/FORM004_Signature_Card.pdf',
                        }}
                        /> */}

                      <div className="input-element">
                          <p className="text-center">{`${lang.data.SignatureCard.cuento}`}</p>
                      </div>
                            <div className="input-element descargue">

                              <p className="text-center">{`${lang.data.SignatureCard.downloadform}`}</p>
                            

                                                             {/* <button className="btn-atlas" > */}
                                                             <Link
                                                              to="/external-link"
                                                              target="_blank"
                                                              rel="noreferrer"
                                                              className="descarga-pdf btn-atlas"
                                                              >

                                                          {`${lang.data.SignatureCard.download}`}
                                                             
                                                             </Link>
                                                             {/* Descargar */}
                                                             {/* </button> */}
                            </div>

                            <div className="input-element">
                                                                <label className="input-size-2">
                                                                {`${lang.data.SignatureCard.uploadsign}`}
                                                                </label>

                                                                <input
                                                                type="file"
                                                                id="password"
                                                                name="password"
                                                                />


                            </div>

                        

                         

                         

                            


                            </div>
                            <div className="container-form-footer">
                                    <button className="btn-atlas cursor-disabled" disabled>{`${lang.data.Header.prev}`}</button>
                                    <button className="btn-atlas"onClick={CloseWindows} >{`${lang.data.Header.complete}`}</button>
                            </div>    
                    
                    
                    </>
                     : null
                }








                                  

                                  
















                





            </div>
            </>

    );
}


PropTypes.shape({
  url: String, // URL to the pdf
  base64: String, // PDF file encoded in base64
})
 
export default SignatureCard;

