import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'

const Impuestos = () => {
    const information = [
        {   id:0,
            icon:a,
            title:'¿Por qué mi información no aparece aquí?',
            cuento:'Para proteger tu identidad, ocultamos algunos datos de la cuenta.'
        }, {   id:1,
            icon:b,
            title:'¿Qué datos se pueden editar?',
            cuento:'No se pueden modificar los datos que Airbnb utiliza para verificar tu identidad. Aunque puedes modificar tus datos personales y de contacto, podríamos pedirte que verifiques tu identidad la próxima vez que hagas una reservación o crees un anuncio.'
        }, {   id:2,
            icon:c,
            title:'¿Qué información se comparte con los demás?',
            cuento:'Airbnb solo proporciona los datos de contacto de los anfitriones y los huéspedes cuando se confirma una reservación.'
        },
    ]


    const information02 = [
        {   id:0,
            icon:b,
            title:'Protege tu cuenta',
            cuento:'Responde solo a las solicitudes de personas que conozcas. Es posible que los hackers quieran acceder a tu cuenta imitando el correo electrónico de un miembro del equipo.'
        }
    ]

    const information01 = [
        {   id:0,
            icon:b,
            title:'Vamos a hacer que tu cuenta sea más segura',
            cuento:'Trabajamos constantemente en nuevas formas para aumentar la seguridad de nuestra comunidad. Es por eso que revisamos cada cuenta para asegurarnos de que sea lo más segura posible.',
            btn:'Mejorar'
        }
    ] 

    const information03 = [
        {   id:0,
            icon:a,
            title:'Cómo agregar dispositivos de personas de confianza',
            cuento:'Cuando apruebas una solicitud, le estás dando a esa persona acceso total a tu cuenta. Podrá modificar las reservaciones y enviar mensajes en tu nombre.',
            btn:'Mejorar'
        }
    ]



    const task = [
       { id:0,
        value:'Contribuyentes'
        },
        { id:1,
            value:'Documentos fiscales'
            }
        
    ]

    const {
        alessa,
        MeEncantas,
        focustask, setFocusTask
    } = useAcc()
    useEffect( () => {
        if(alessa.T == 'Impuestos') {
            setFocusTask('Contribuyentes')
        }
    },[])
    

    return (
        <>
        { alessa.T == 'Impuestos' ?
                <div className=" box-header-general">
                <div className={`sabes-que-te-quiero`} >

                    <div>
                        <div className={`header-joema-hola-acc`}>
                            <p onClick={MeEncantas} className={`text-deco`}>
                                Cuenta
                            </p>
                            <span>{'>'}</span>
                                <p >{alessa.T}</p>
                        </div>
                        <div className={`header-joema-hola-title`}>
                            <p className={`Font-Love-Title`}>
                                {alessa.T}
                            </p>
                        </div>
                    </div>
                   <section className={`master-contenedor-action-bar`}>
                    <section className={'contenedor-action-bar'}>
                     { task.map((viste)=> {
                       return (

                           <button key={viste.id} className={`clasic-name-btn ${focustask === viste.value ? 'clasic-name-btn-active' :''} `}   
                                   onClick={ () => {setFocusTask(viste.value)}}
                           >
                               {viste.value}
                           </button>

                       )
                     })

                     }

                    </section>
                    </section>

                    { focustask === 'Contribuyentes' ? 
                       <>
                       <section className={`contenedor-doble`}>
                       <div className={`bombaso`}>
                                               <div className={`contenedor-input`}>
                                                <div className={`set-style`}>
                                                    <h2>
                                                    Información fiscal
                                                    </h2>
                                                    <p>
                                                    La información fiscal es obligatoria en la mayoría de los <br/>  países y regiones. <span className={`btn-text-raya`}> Más información </span>
                                                    </p>
                                                    <button className={`joema-btn-special`}>
                                                    Agrega tus datos fiscales
                                                    </button>

                                                </div>

                                                <div className={`set-style`}>
                                                    <h2>
                                                    Impuesto al valor agregado (IVA)
                                                    </h2>
                                                    <p className={``}>
                                                    Si estás registrado para efectos del IVA, agrega tu número <br/> de IVA. <span className={`btn-text-raya`}> Más información </span>
                                                    </p>
                                                    <hr className={`special-hr`}/>
                                                    <button className={`joema-btn-special`}>
                                                    Agrega un número de identificación del IVA
                                                    </button>

                                                </div> 

                                                <div className={`set-style`}>
                                                    <h2>
                                                    ¿Necesitas ayuda?
                                                    </h2>
                                                    <p className={``}>
                                                    Encuentra respuestas a tus dudas sobre impuestos en  <br/>  nuestro <span className={`btn-text-raya`}> Centro de ayuda.</span>
                                                    </p>
                                                    

                                                </div>


                               

                                                

                                                </div>
                                                   



                                               </div>
                                               <div >


                                                   {/* <div className={`information-box-box-container`}>
                                                   {  information01.map((m) => {
                                                               return(
                                                                   <div key={m.id} className={`elmentaohijo`}> 
                                                                           <img src={m.icon}/>  
                                                                       <h3>{m.title}</h3>
                                                                       <p>{m.cuento}</p>
                                                                      {
                                                                       m.btn ? <>
                                                                       <button className={`joema-btn-special`}>
                                                                           {m.btn}
                                                                       </button>                 
                                                                       </> : null
                                                                      }
                                                                   </div>
                                                               )
                                                           })
                                                   }

                                                   </div> */}
                                                   
                                               </div>

                    </section>
                                           </> : null
                                       }
                                       

                                       
                    { focustask === 'Documentos fiscales' ? 
                       <>
                       <section className={`contenedor-doble`}>
                       <div className={`bombaso`}>
                                               <div className={`contenedor-input`}>
                                                <div className={`set-style`}>
                                                    
                                                    <p>
                                                    Aquí podrás consultar y descargar todos los documentos fiscales que necesitas para presentar tu declaración.
                                                    </p>
                                                    <p>
                                                    Cuando vayas a hacer tu declaración de impuestos, tienes la opción de usar la información detallada que se incluye en tu <span className={`btn-text-raya`}> resumen de ingresos.</span> 
                                                    </p>
                                         
                                                </div>

                                                <div className={`set-style`}>
                                                    <div className={`yearlist-border`}>
                                                        <h2>
                                                        2022
                                                        </h2>
                                                        <p className={`kill-m`}>
                                                        No se emitió ningún documento fiscal
                                                        </p>
                                                    </div>
                                                    <div className={`yearlist-border`}>
                                                        <h2>
                                                        2021
                                                        </h2>
                                                        <p className={`kill-m`}>
                                                        No se emitió ningún documento fiscal
                                                        </p>
                                                    </div>
                                                    <div className={`yearlist-border`}>
                                                        <h2>
                                                        2020
                                                        </h2>
                                                        <p className={`kill-m`}>
                                                        No se emitió ningún documento fiscal
                                                        </p>
                                                    </div>
                                                    <div className={`yearlist-border`}>
                                                        <h2>
                                                        2019
                                                        </h2>
                                                        <p className={`kill-m`}>
                                                        No se emitió ningún documento fiscal
                                                        </p>
                                                    </div>
                                                    <p>Para obtener documentos fiscales emitidos antes de 2019,<span className={`btn-text-raya`}> comunícate con nosotros.</span> </p>
                                                </div>

                                                

                                                <div className={`set-style`}>
                                                    <h2>
                                                    ¿Necesitas ayuda?
                                                    </h2>
                                                    <p className={``}>
                                                    Encuentra respuestas a tus dudas sobre impuestos en  <br/>  nuestro <span className={`btn-text-raya`}> Centro de ayuda.</span>
                                                    </p>
                                                    

                                                </div>


                               

                                                

                                                </div>

                                                   

                                           

                                            



                                               </div>
                                               <div >


                                                   {/* <div className={`information-box-box-container`}>
                                                   {  information02.map((m) => {
                                                               return(
                                                                   <div key={m.id} className={`elmentaohijo`}> 
                                                                           <img src={m.icon}/>  
                                                                       <h3>{m.title}</h3>
                                                                       <p>{m.cuento}</p>
                                                                   </div>
                                                               )
                                                           })
                                                   }

                                                   </div> */}
                                                   
                                               </div>

                    </section>
                                           </> : null
                                       }
                                       

                                       
                   
                   

                </div>

               
         </div> : null

        }
        </>

    );
}
 
export default Impuestos;