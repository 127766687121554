import React, { useState, useEffect, createContext } from 'react'
import { useNavigate } from 'react-router-dom';
import clienteAxios from '../../config/axios';
import axiosAuth from '../../config/axiosAuth';
import {EN, ES} from '../../config/Language/Lenguage';
import useData from '../../hooks/useData'

const AuthContext = createContext();



const AuthProvider = ({children}) => {

    

    
    

const [lang, setLang] = useState({
    data:ES,
    status:'ES',

});
const ActiveEN = () => {
    setLang({
        data:EN,
        status:'EN',
    })
}
const ActiveES = () => {   
    setLang({
        data:ES,
        status:'ES',
    })
}
const [auth, setAuth ] = useState({
    data:null,
    status:false,
})



const navigate = useNavigate()
const [ cargando, setCargando] = useState(true);
const token = localStorage.getItem('token');
const [iniciarsesionstatus, setInStatus] = useState(false)
    useEffect(
        () => {
            if (  iniciarsesionstatus === true  ){                
            const autenticarUsuario = async () => { 
                if(!token) {
                    setCargando(false)
                } 
                try {
                    const { data } = await axiosAuth.post('/api/client/usuario_autenticado',)  
                    setAuth({
                        data:data.data,
                        status:true,
                    })
                     navigate('/core')
                } catch (error) {
                    setAuth({
                        data:null,
                        status:false,
                    })   
                    navigate('/')
                }
                setCargando(false)
            }
            autenticarUsuario()
            } else {
                const autenticarUsuario = async () => {
                    if(!token) {
                        setCargando(false)
                    } 
                    try {
                        const { data } = await clienteAxios.post('/api/client/usuario_autenticado',)
                        
                        setAuth({
                            data:data.data,
                            status:true,
                        })
                        
                         navigate('/core')
                    } catch (error) {
                        setAuth({
                            data:null,
                            status:false,
                        })   
                        navigate('/')
                    }
                    setCargando(false)
                }
    
                autenticarUsuario()


            }

        },[]
    )


    return(
        <AuthContext.Provider
            value={{
                setAuth,
                auth,
                cargando,
                setInStatus,
                token,
                iniciarsesionstatus,
                lang,
                ActiveEN,
                ActiveES



            }}
        
        >
            {children}
        </AuthContext.Provider>
    )

}

export {
    
    AuthProvider

}

export default AuthContext

