import React, { useContext } from 'react'

import AuthContext from '../context/autenticacion/AuthProvider'

const useAuth = () => {
    return useContext(AuthContext)
}


export default useAuth;
