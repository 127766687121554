import React from 'react'

const Idioma = () => {
    
    return (  

        <>
                
            
<option value="中文 (简体)">中文 (简体)</option>
<option value="中文 (繁體)">中文 (繁體)</option>
<option value="English">English</option>
<option value="Français">Français</option>
<option value="Deutsch">Deutsch</option>
<option value="Italiano">Italiano</option>
<option value="日本語">日本語</option>
<option value="한국어">한국어</option>
<option value="Português">Português</option>
<option value="Русский">Русский</option>
<option value="Español">Español</option>
<option value="العربية">العربية</option>
<option value="Català">Català</option>
<option value="Hrvatski">Hrvatski</option>
<option value="Čeština">Čeština</option>
<option value="Dansk">Dansk</option>
<option value="Nederlands">Nederlands</option>
<option value="Suomi">Suomi</option>
<option value="Ελληνικά">Ελληνικά</option>
<option value="עברית">עברית</option>
<option value="हिन्दी">हिन्दी</option>
<option value="Magyar">Magyar</option>
<option value="Íslenska">Íslenska</option>
<option value="Bahasa Indonesia">Bahasa Indonesia</option>
<option value="Melayu">Melayu</option>
<option value="Norsk">Norsk</option>
<option value="Polski">Polski</option>
<option value="Svenska">Svenska</option>
<option value="ภาษาไทย">ภาษาไทย</option>
<option value="Türkçe">Türkçe</option>
<option value="Afrikaans">Afrikaans</option>
<option value="Shqip">Shqip</option>
<option value="Հայերեն">Հայերեն</option>
<option value="azərbaycan dili">azərbaycan dili</option>
<option value="euskara">euskara</option>
<option value="беларуская мова">беларуская мова</option>
<option value="বাংলা">বাংলা</option>
<option value="Bosanski">Bosanski</option>
<option value="български език">български език</option>
<option value="ဗမာစာ">ဗမာစာ</option>
<option value="eesti">eesti</option>
<option value="Filipino">Filipino</option>
<option value="Galego">Galego</option>
<option value="ქართული">ქართული</option>
<option value="Gujarati">Gujarati</option>
<option value="Kreyòl ayisyen">Kreyòl ayisyen</option>
<option value="Gaeilge">Gaeilge</option>
<option value="ಕನ್ನಡ">ಕನ್ನಡ</option>
<option value="ភាសាខ្មែរ">ភាសាខ្មែរ</option>
<option value="кыргызча">кыргызча</option>
<option value="Lao">Lao</option>
<option value="Latviešu Valoda">Latviešu Valoda</option>
<option value="lietuvių kalba">lietuvių kalba</option>
<option value="македонски јазик">македонски јазик</option>
<option value="Malti">Malti</option>
<option value="فارسی">فارسی</option>
<option value="ਪੰਜਾਬੀ">ਪੰਜਾਬੀ</option>
<option value="Română">Română</option>
<option value="српски језик">српски језик</option>
<option value="Slovenčina">Slovenčina</option>
<option value="Slovenščina">Slovenščina</option>
<option value="Kiswahili">Kiswahili</option>
<option value="Tagalog">Tagalog</option>
<option value="தமிழ்">தமிழ்</option>
<option value="తెలుగు">తెలుగు</option>
<option value="Українська">Українська</option>
<option value="اردو">اردو</option>
<option value="Tiếng Việt">Tiếng Việt</option>
<option value="isiXhosa">isiXhosa</option>
<option value="isiZulu">isiZulu</option>

<option value="Sign Language">Sign Language</option>


        
        </>


    );
}
 
export default Idioma;