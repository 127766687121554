import React from 'react'

import useAuth from '../../hooks/useAuth'

const LoaderUniversal = () => {

    const {auth} = useAuth()


    return (  

            <>
            
            <div className="contenedor 
             kyeahmaster 
            loader-atlas-black">


                <div className="ayudaloader">
                <div className="box-logo ">
     
                <span></span>
                

                </div>
                {/* <p> <strong>Loading </strong> <br/> {auth.data.name}</p> */}
                </div>

                

            </div>
            
            </>


    );
}
 
export default LoaderUniversal;