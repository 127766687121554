import {ReactComponent as Logo} from '../../imagenes/iconloaders/loader01.svg'; 



const LoaderD = () => {
    return (  




            <div className="loader-joema-2023">

                <Logo/>

            </div>


    );
}
 
export default LoaderD;