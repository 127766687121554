import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'
import aa from '../../imagenes/iconoscuenta/aa.svg'

const ViajesDeTrabajo = () => {
    const information = [
        {   id:0,
            icon:a,
            title:'Manejo de gastos simplificado',
            cuento:'Enviaremos los recibos de los viajes de trabajo al correo electrónico de tu empresa para facilitar la administración de gastos.'
        }, {   id:1,
            icon:b,
            title:'Descripción del viaje',
            cuento:'Agrega un código de gastos y un propósito de negocios a tus viajes de trabajo.'
        }, {   id:2,
            icon:c,
            title:'Cuidamos la privacidad de los viajes personales',
            cuento:'Tu empresa solo puede obtener información sobre los viajes que indiques durante el proceso de pago que son de trabajo.'
        },
    ]


    const information02 = [
        {   id:0,
            icon:b,
            title:'Protege tu cuenta',
            cuento:'Responde solo a las solicitudes de personas que conozcas. Es posible que los hackers quieran acceder a tu cuenta imitando el correo electrónico de un miembro del equipo.'
        }
    ]

    const information01 = [
        {   id:0,
            icon:b,
            title:'Vamos a hacer que tu cuenta sea más segura',
            cuento:'Trabajamos constantemente en nuevas formas para aumentar la seguridad de nuestra comunidad. Es por eso que revisamos cada cuenta para asegurarnos de que sea lo más segura posible.',
            btn:'Mejorar'
        }
    ] 

    const information03 = [
        {   id:0,
            icon:a,
            title:'Cómo agregar dispositivos de personas de confianza',
            cuento:'Cuando apruebas una solicitud, le estás dando a esa persona acceso total a tu cuenta. Podrá modificar las reservaciones y enviar mensajes en tu nombre.',
            btn:'Mejorar'
        }
    ]



    const task = [
       { id:0,
        value:'INICIO DE SESIÓN'
        },
        { id:1,
            value:'SOLICITUDES DE INICIO DE SESIÓN'
            },
            { id:2,
                value:'ACCESO COMPARTIDO'
                },
    ]

    const {
        alessa,
        MeEncantas,
        focustask, setFocusTask,
        focusinput, 
        setFocusInput,
        focus,
        setFocus,
        focusselect , setFocusSelect
    } = useAcc()

    // useEffect( () => {
    //     if(focustask === "") {
    //         setFocusTask('INICIO DE SESIÓN')
    //     }
    // },[alessa])
    const onChange = e => {
        // voy a colocar guardar usuario en el state
        setFocusSelect({
             ...focusselect,
            [e.target.name] : e.target.value
    
        })
        setFocus('')

        
    }

    const [mirador, setMirar] = useState(false);

    return (
        <>
        { alessa.T == 'Viajes de trabajo' ?
                 <div className=" box-header-general">
                 <div className={`sabes-que-te-quiero`} >

                     <div>
                         <div className={`header-joema-hola-acc`}>
                             <p onClick={MeEncantas} className={`text-deco`}>
                                 Cuenta
                             </p>
                             <span>{'>'}</span>
                                 <p >{alessa.T}</p>
                         </div>
                         <div className={`header-joema-hola-title`}>
                             <p className={`Font-Love-Title`}>
                                 {alessa.T}
                             </p>
                         </div>
                     </div>
                    

                     <section className={`contenedor-doble`}>
                        <div className={`bombaso`}>

                            
                        <div className={`contenedor-input border-bottom-kill`}>
                                                                       <div className={`set-style-2 `}>
                                                                           <h2>
                                                                           Únete for Work
                                                                           </h2>
                                                                           <p>
                                                                           Agrega tu correo electrónico de empresa para que recibas reportes de gastos y ofertas exclusivas en viajes de trabajo.
                                                                           </p>
                                    
                                                                       </div>
                                                           
                       
                                                       </div>
                                                       <div className={`input-joema-master-geo kill-m  input-hide-hide ${mirador === true ? 'focus-active':''} `}>
                                                                                <label>
                                                                                correo electrónico
                                                                                </label>
                                                                                <input 
                                                                                    id="correo"
                                                                                    name="correo"
                                                                                    onChange={onChange}
                                                                                    onFocus={
                                                                                        () => {
                                                                                         setMirar(true)
                                                                                        }
                                                                                     }

                                                                                />

                                                                            </div>

                                                     <button className={`joema-btn-special margin-top-009`} onClick={
                                                                                        () => {
                                                                                            setMirar(false)
                                                                                        }
                                                                                     }>
                                                    Correo electrónico de empresa
                                                    </button>
                       

                                                   



                                                </div>
                                                <div >


                                                    <div className={`information-box-box-container`}>
                                                    {  information.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                           
                                                                        <h3>{m.title}</h3>
                                                                        <p>{m.cuento}</p>
                                                                      
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>
                                   
                    

                 </div>

                
          </div> : null
        }
        </>

    );
}
 
export default ViajesDeTrabajo;