import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './stylos/style.css';
import './stylos/dash.css';
import './stylos/admin.css';
import './stylos/domo.css';
import './stylos/bancaenlinea.css';
import '../src/A-Bienes-Raices/styles/bienes.css';
import App from './App';
import AppDomo from '../../jimf-codigo-madre-react/src/A-ELDOMO/App';
import AppBienesRaices from '../../jimf-codigo-madre-react/src/A-Bienes-Raices/App';

import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";







const root = ReactDOM.createRoot(document.getElementById('root'));
const changerapp = 3
root.render(
  
  <BrowserRouter>
  { changerapp === 1 ?
      <App /> :null 
  }

  { changerapp === 2 ?
      <AppDomo /> :null 
  }  

{ changerapp === 3 ?
      <AppBienesRaices /> :null 
  }  

  </BrowserRouter>
  
);

  // console.log(process.env.REACT_APP_BACKEND_URL)
  // console.log(process.version)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

