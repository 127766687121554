import React, { useState, createContext, useEffect} from 'react'
import clienteAxios from '../../config/axios'
import { Link, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth';







const JoelReactContext = createContext();


const JoelReactProvider = ({children}) => {
    const [ amenu, setA] = useState(true);
    const [ bmenu, setB] = useState(false);
    const [ cmenu, setC] = useState(false);
    const [ dmenu, setD] = useState(false);
    const Aon = () => {
    setA(true);
    setB(false);
    setC(false);
    setD(false);
    }
    const Bon = () => {
        setA(false);
        setB(true);
        setC(false);
        setD(false);
        }
        const Con = () => {
            setA(false);
            setB(false);
            setC(true);
            setD(false);
            }
            const Don = () => {
                setA(false);
                setB(false);
                setC(false);
                setD(true);
                }
    return (


        <JoelReactContext.Provider
            value={{
                Aon,
                Bon,
                Con,
                Don,
                amenu,
                bmenu,
                cmenu,
                dmenu,

            }}
        
        >


            {children}
        </JoelReactContext.Provider>

    )


}

export {
    JoelReactProvider
}

export default JoelReactContext;
