import React, { useContext } from 'react'

import ActJoemaSearchContext from '../context/Actions/ActJoemaSearchContext'

const useSearchJoe = () => {
    return useContext(ActJoemaSearchContext)
}


export default useSearchJoe;
