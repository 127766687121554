import React from 'react'
import LoaderD from './LoaderD';

const LoaderCenter = () => {
    return (  

            <>




        <section className={`Loader-Joema-central`}>
            <div>
                <LoaderD/>
            </div>
        </section>

            </>

    );
}
 
export default LoaderCenter;