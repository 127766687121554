import React, { useState } from 'react'
import SearchBar from './SearchBar.jsx'
import Logo from '../../imagenes/logog.png'
import B from '../../imagenes/iconos/WorldAir.svg'
import menuAir from '../../imagenes/iconos/menuAir.svg'
import { useNavigate } from 'react-router-dom'
import useAct from '../../hooks/useAct.jsx'

const HeaderBar = () => {

        ///// este componente es especial porque trae las acciones para lenvatar las ventanas
    
    const { 
        winacc, //// variables
        WinAccD, // acciones de perfil cuentas
        WinM, // accciones de mensaje
        WinNot, // aciones de notificaciones
        WinFav, /// accciones para los favoritos
        WinCompras, // aacci0ones para la ventana de compras y alquiler
        WinIdioma, // aaciones para levantar la ventana de idioma
        open, /// variable para reconocer el menu
        toggle, /// accionador para abrir menu y cerrar
        setO,
    }
         = useAct()
    const navigate = useNavigate()
    const Log = () => {
        localStorage.removeItem('token');
        navigate('/')
    }
    return ( 
            <section className="Headerbar">

                <section className="background-epic">

                    <div className="eleme-bg-1">

                    </div>

                    <div className="eleme-bg-2">
                        
                    </div>

                    <div className="eleme-bg-3">
                        
                    </div>


                </section>

                <section className="Headerbar-box">
                             <div className="head-e-1">
                                {/* <img src={Logo} className="Logo-Givarts"/> */}

                                <div className="givartspie">
                                    <h2>¿Quieres comprar o Alquilar?</h2>
                                <p className="givarts-pie">groundscript.com</p>
                                </div>
                            </div>
                            <div className="head-e-2">
                                <SearchBar/>
                            </div>

                            <div className="head-e-3">
                            <div className="menu-container-right">

                            <div className="Header-action">
                            <p>Bienvenido, 
                                Joel Mariscal </p> <img src={B} onClick={WinIdioma}/>    
                            </div>

                            <div className="single-bar" onClick={toggle}>

                                <div className={`menu-leve-leve bg-ground ${ open === false ? 'willoff d-none ':' willon d-grid' } `}>

                                    <div>
                                        <p onClick={WinM}>Mensajes</p>
                                        <p onClick={WinNot}>Notificaciones</p>
                                        <p onClick={WinCompras}>Compras, Alquileres</p>
                                        <p onClick={WinFav}> Lista de favoritos</p>
                                    </div>
                                    <div className="border-top font-light">
                                        <p>Pon tu espacio aquí</p>
                                        <p onClick={WinAccD}>Cuenta</p>
                                    </div>
                                    <div className="border-top font-light">
                                        <p>Ayuda</p>
                                        <p onClick={Log}>Cerrar la sesión</p>
                                    </div>

                                </div>

                                <img 
                                    src={menuAir}                                
                                />

                                <img
                                className='img-max-yeah'
                                    src={`https://a0.muscache.com/defaults/user_pic-50x50.png?`}
                                />

                            </div>

                            </div>
                                
                                
                            </div>
                </section>

                {/* <section className="Headerbar-box-box">
                             <div className="head-e-1">
                            </div>

                            <div className="head-e-2">

                                ¿Quieres comprar o Alquilar?
                                
                            </div>

                            <div className="head-e-3">
                                
                            </div>
                </section> */}

            </section>

     );
}
 
export default HeaderBar;