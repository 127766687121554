const dataPropiedadesUpdate = [
    {
// Basic info
id: 1,
type: 'Master',
label: 'Bastimento, Panamá',
text: "Increíble cabaña para disfrutar",
ncamas:"2",

// Location info
provincia: 'Bocas del Toro',
distrito: 'Bocas del Toro',
corregimiento: 'Bastimentos',
    
// Ratings and pricing
puntuacion: '4.8 (80)',
total: '401',
pornoche: '148',

    
// Image URLs
img1:'https://a0.muscache.com/im/pictures/2d8fd134-8d7c-4b69-a37e-17558908c426.jpg',
img2:'https://a0.muscache.com/im/pictures/2d8fd134-8d7c-4b69-a37e-17558908c426.jpg',
img3:'https://a0.muscache.com/im/pictures/2d8fd134-8d7c-4b69-a37e-17558908c426.jpg',
    
// Image array
img:
[
    {
    picture:'https://a0.muscache.com/im/pictures/miso/Hosting-6822043/original/0471bda4-1a62-43f8-a063-691f130657fb.png',
    id:1,
    },
    {
    picture:'https://a0.muscache.com/im/pictures/miso/Hosting-6822043/original/dc37d3f9-daee-4616-8258-af6f1ea6fc1c.jpeg',
    id:2,
    },
    {
    picture:'https://a0.muscache.com/im/pictures/miso/Hosting-6822043/original/8ea5088a-d9a8-4558-bace-2a01f84db6af.png',
    id:3,
    },
    {
    picture:'https://a0.muscache.com/im/pictures/miso/Hosting-6822043/original/bb525532-29ea-45a5-a6b4-5c84d99cd518.jpeg',
    id:4,
    },
    {
    picture:'https://a0.muscache.com/im/pictures/64204e29-cb71-441b-bb68-1acd07749c3c.jpg',
    id:5,
    },
    {   
    picture:'https://a0.muscache.com/im/pictures/miso/Hosting-6822043/original/3ca95d0c-62c1-4455-8cd3-d9fc66479ddb.png',
    id:6,
    }    
],
    },
    {
  // Basic info
  id: 2,
  type: 'Normal',
  label: 'Casa del árbol en Distrito de San Carlos',
  text: 'Cabaña de la Abuela Tota #2 by AcoModo',
  ncamas:"4",

  // Location info
  provincia: 'Chiriquí',
  distrito: 'David',
  corregimiento: 'San Carlos',
  
  // Ratings and pricing
  puntuacion: '5.0 (45)',
  total: '401',
  pornoche: '102',

  // Image URLs
  img1:'https://a0.muscache.com/im/pictures/2adf6ef9-e131-431b-a34e-9566e768f509.jpg',
  img2:'https://a0.muscache.com/im/pictures/2adf6ef9-e131-431b-a34e-9566e768f509.jpg',
  img3:'https://a0.muscache.com/im/pictures/2adf6ef9-e131-431b-a34e-9566e768f509.jpg',
  
  // Image array
  img:[
      {
          picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-23894329/original/186a7e6b-a4c6-4006-8840-07c2f387b109.jpeg',
          id:1,
      },
      {
          picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-23894329/original/4f3c0b75-fd9a-4217-912b-4405768ffbe4.jpeg',
          id:2,
      },
      {
          picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-23894329/original/de175c7b-a4e2-45c4-bd75-236f90d9aac9.jpeg',
          id:3,
      },
      {
          picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-23894329/original/9350c1ae-bde1-4c9c-a714-a26abcd4b261.jpeg',
          id:4,
      },
      {
          picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-23894329/original/bd069dcc-b42f-4af7-a6cb-94edaff134f3.jpeg',
          id:5,
      },
      {   
          picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-23894329/original/c8fc9233-fe8d-4af8-8452-0afb16bf6802.jpeg',
          id:6,
      }    
  ],
     
    },
    {
   // Basic info
   id: 3,
   type: 'Normal',
   label: 'Cabaña en Provincia de Panamá Oeste',
   text: 'Cabaña Buenavista by Casa Amaya',
   ncamas:"3",

   // Location info
   provincia: 'Panamá Oeste',
   distrito: 'Arraiján',
   corregimiento: 'Nuevo Emperador',
   
   // Ratings and pricing
   puntuacion: '5.0 (30)',
   total: '401',
   pornoche: '55',

   // Image URLs
   img1:'https://a0.muscache.com/im/pictures/82c577ee-3422-4fda-ae09-6716d76e8bef.jpg',
   img2:'https://a0.muscache.com/im/pictures/82c577ee-3422-4fda-ae09-6716d76e8bef.jpg',
   img3:'https://a0.muscache.com/im/pictures/82c577ee-3422-4fda-ae09-6716d76e8bef.jpg',
   
   // Image array
   img:[
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713042514762708988/original/f310097e-25a5-45e0-ab73-6188d7ecb281.jpeg',
           id:1,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713042514762708988/original/33feabc9-a67a-4096-ac36-aa2105162cbb.jpeg',
           id:2,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713042514762708988/original/f310097e-25a5-45e0-ab73-6188d7ecb281.jpeg',
           id:3,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713042514762708988/original/fe1a33d4-7c8b-464b-a5b0-0cfef525eb65.jpeg',
           id:4,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713042514762708988/original/da44b20a-6a12-483c-979d-7584d7317201.jpeg',
           id:5,
       },
       {   
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713042514762708988/original/8564b2ec-356b-4e13-8c87-a8a1c537c1f8.jpeg',
           id:6,
       },
       {   
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713042514762708988/original/8dfa8781-8d5f-436c-b651-488d15dba50c.jpeg',
           id:7,
       }    
   ],
  
    },
    {
   // Basic info
   id: 4,
   type: 'Normal',
   label: 'Apartamento en Maria Chiquita',
   text: 'Un lugar para relajarse',
   ncamas:"11",

   // Location info
   provincia: 'Colón',
   distrito: 'Portobelo',
   corregimiento: 'María Chiquita',
   
   // Ratings and pricing
   puntuacion: '4.7 (35)',
   total: '401',
   pornoche: '42',

   // Image URLs
   img1:'https://a0.muscache.com/im/pictures/1ef9b49c-6f99-4018-95f9-8471a9fbbd15.jpg',
   img2:'https://a0.muscache.com/im/pictures/1ef9b49c-6f99-4018-95f9-8471a9fbbd15.jpg',
   img3:'https://a0.muscache.com/im/pictures/1ef9b49c-6f99-4018-95f9-8471a9fbbd15.jpg',
   
   // Image array
   img:[
       {
           picture:'https://a0.muscache.com/im/pictures/62a2ce55-15e6-45ae-9589-a8f9450a2eba.jpg',
           id:1,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/8664924c-9602-42d4-850e-dbcc5fc7e320.jpg',
           id:2,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/3e2c2d82-f83b-4d70-91d0-ce547025d63d.jpg',
           id:3,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/16dde4a6-16e1-43fc-9b67-b4318efeeaee.jpg',
           id:4,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/8ccfd69c-6947-4c28-be09-175113c936e9.jpg',
           id:5,
       },
       {   
           picture:'https://a0.muscache.com/im/pictures/9df5dda2-fde7-4a5c-a047-480367a122db.jpg',
           id:6,
       }    
   ],
   
    },
//     {
// // Basic info
// id: 5,
// type: 'Normal',
// label: 'Lujosa Casa del Arbol Boquete',
// text: 'Disfruta de la paz y tranquilidad con los tuyos',
// ncamas:"4",

// // Location info
// provincia: 'Chiriquí',
// distrito: 'Boquete',
// corregimiento: 'Los Naranjos',

// // Ratings and pricing
// puntuacion: '4.9 (53)',
// total: '401',
// pornoche: '250',

// // Image URLs
// img1:'https://a0.muscache.com/im/pictures/ace7d6e7-589c-4fd9-be13-c2355a2e9576.jpg',
// img2:'https://a0.muscache.com/im/pictures/ace7d6e7-589c-4fd9-be13-c2355a2e9576.jpg',
// img3:'https://a0.muscache.com/im/pictures/ace7d6e7-589c-4fd9-be13-c2355a2e9576.jpg',

// // Image array
// img:[
//     {
//         picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-51131909/original/fdca7359-d015-4f6b-b4f8-0f68b357d401.jpeg',
//         id:1,
//     },
//     {
//         picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-51131909/original/34ac7355-8bd7-4313-9e73-e8342834f0c0.jpeg',
//         id:2,
//     },
//     {
//         picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-51131909/original/579bf958-5c17-48b3-ba8b-dc9e0d90153f.jpeg',
//         id:3,
//     },
//     {
//         picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-51131909/original/fdca7359-d015-4f6b-b4f8-0f68b357d401.jpeg',
//         id:4,
//     },
//     {
//         picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-51131909/original/283cc728-bfb9-44ba-ae0c-372eb374b0c4.jpeg',
//         id:5,
//     },
//     {   
//         picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-51131909/original/34802d74-0306-427a-80e0-1953d8af4512.jpeg',
//         id:6,
//     }    
// ],
  
//     },
    {
    // Basic info
    id: 6,
    label: 'Cabaña en el aire',
    text: 'Sueña en las nubes',
    ncamas:"2",

    // Location info
    provincia: 'Panamá Oeste',
    distrito: 'Capira',
    corregimiento: 'La Trinidad',

    // Ratings and pricing
    puntuacion: '4.7 (76)',
    total: '401',
    pornoche: '112',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-50374296/original/1ecf8b4d-836c-42c1-b528-4bf7bb7d07fb.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-50374296/original/1ecf8b4d-836c-42c1-b528-4bf7bb7d07fb.jpeg',
    img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-50374296/original/1ecf8b4d-836c-42c1-b528-4bf7bb7d07fb.jpeg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/accb7422-7a6a-4763-9910-3b06062fff3d.jpg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/d74da630-ded1-48de-aad6-dabea3919175.jpg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/7ccb0bdb-e598-49a3-a69d-b6ec21552d57.jpg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/40818585-cb2b-42c0-8ca3-895d40d8d9e4.jpg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/6a1edfa3-2df5-47ee-abb3-feaf61a32b15.jpg',
            id:5,
        },
        {   
            picture:'https://a0.muscache.com/im/pictures/6621d473-8fc0-4a26-902b-175ea207c0ae.jpg',
            id:6,
        }    
    ], 
    },
    {
    // Basic info
    id: 7,
    label: 'Condado en Panamá',
    text: "Luxury 1 bedroom",
    ncamas:"3",

    // Location info
    provincia: 'Panamá',
    distrito: 'Panamá',
    corregimiento: 'San Francisco',

    // Ratings and pricing
    puntuacion: '4.7 (60)',
    total: '401',
    pornoche: '80',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-916438617813279487/original/8391b3b0-a224-40e3-94b3-adbe6d9393ed.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-53298889/original/b6f677cf-9461-4e89-8628-a6e61015eebb.jpeg',
    img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-53298889/original/b6f677cf-9461-4e89-8628-a6e61015eebb.jpeg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-626658760655400671/original/48168a7b-9796-422d-82bb-cd095f560914.jpeg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-626658760655400671/original/8689ccce-c3da-486c-bbf2-18b9ae4a238b.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-626658760655400671/original/8d0c17c9-8ca3-45af-b910-ac8efe4b57ee.jpeg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-626658760655400671/original/4b54b22c-98c1-4081-8efd-94ae0fd5aadf.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-626658760655400671/original/4e40ddd1-91dc-4623-b27d-32363550b730.jpeg',
            id:5,
        },
        {   
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-626658760655400671/original/e7986527-b0b3-452e-a59f-1bd8dfb75980.jpeg',
            id:6,
        }    
    ],


    },
    {
    // Basic info
    id: 8,
    label: 'Apartamento en Panamá Novolux',
    text: 'Estudio nuevo privado Novolux',
    ncamas: "3",

    // Location info
    provincia: 'Panamá',
    distrito: 'Panamá',
    corregimiento: 'San Francisco',

    // Ratings and pricing
    puntuacion: '4.8 (66)',
    total: '401',
    pornoche: '70',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-913053087303616466/original/52c1284c-de3f-4164-8c21-5238f10af08a.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-24020528/original/62f97b0e-00e7-46d5-aac4-9f586cac3dec.jpeg',
    img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-24020528/original/62f97b0e-00e7-46d5-aac4-9f586cac3dec.jpeg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783426148920738645/original/0f7aef42-26ef-482e-9f70-0f04fdc54523.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783426148920738645/original/ffb376e4-2cf1-47d0-9d66-737bc063efdf.jpeg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783426148920738645/original/8e3730be-ac29-41aa-9e78-d4da8a090aaf.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783426148920738645/original/6d930190-3b35-4432-8eab-5799c0b39c3f.jpeg',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783426148920738645/original/0f7aef42-26ef-482e-9f70-0f04fdc54523.jpeg',
            id:6,
        }
    ],
   
    },
    {
// Basic info
id: 9,
label: 'Condo en Nueva Gorgona',
text: 'Espectacular Vista al mar',
ncamas: "3",

// Location info
provincia: 'Panamá Oeste',
distrito: 'Chame',
corregimiento: 'Nueva Gorgona',

// Ratings and pricing
puntuacion: '4.9 (14)',
total: '401',
pornoche: '90',

// Image URLs
img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-51170869/original/9476f028-282b-4d3c-860a-b1a24e1bf46c.jpeg',
img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-51170869/original/9476f028-282b-4d3c-860a-b1a24e1bf46c.jpeg',
img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-51170869/original/9476f028-282b-4d3c-860a-b1a24e1bf46c.jpeg',

// Image array
img:[
    {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-738473599974769746/original/665bd77c-fb84-4530-99f5-21f14ca29a27.jpeg',
        id:1,
    },
    {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-738473599974769746/original/5bcd2d5e-3251-42b1-a991-21986d6ad8b0.jpeg',
        id:2,
    },
    {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-738473599974769746/original/9e938b22-27a4-4b77-84c7-8b2709e960c6.jpeg',
        id:3,
    },
    {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-738473599974769746/original/6cf0fa01-42eb-4710-900b-d74224747085.jpeg',
        id:4,
    },
    {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-738473599974769746/original/fbe4784b-e835-4859-ba2e-c70db7d21a01.jpeg',
        id:5,
    },
    {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-738473599974769746/original/23a4468e-1d60-4e47-9707-741f08fe33dd.jpeg',
        id:6,
    }
],     
    },
    {

    // Basic info
    id: 10,
    label: 'Cabaña en Chame',
    text: 'Cabaña de Luis Guevara',
    ncamas: "4",
        
    // Location info
    provincia: 'Panamá Oeste',
    distrito: 'Chame',
    corregimiento: 'Cabuya',
        
    // Ratings and pricing
    puntuacion: '4.9 (99)',
     total: '401',
    pornoche: '192',
        
    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-42086947/original/7798bc24-1766-416b-9b1e-108b3a78ea14.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-42086947/original/7798bc24-1766-416b-9b1e-108b3a78ea14.jpeg',
    img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-42086947/original/7798bc24-1766-416b-9b1e-108b3a78ea14.jpeg',
        
    // Image array
    img:[
        {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-740719841457623546/original/b66987c6-ea40-4c66-802d-859bbcb45a70.jpeg',
        id:1,
        },
        {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-740719841457623546/original/5f86c64f-fd72-4348-b888-978fae134713.jpeg',
        id:2,
        },
        {
        picture:'https://a0.muscache.com/im/pictures/22dff09e-597f-47f6-8dd0-0d3b40e159ad.jpg',
        id:3,
        },
        {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-740719841457623546/original/5eca906c-a223-41d8-9324-c2d70d7e8808.jpeg',
        id:4,
        },
        {
        picture:'https://a0.muscache.com/im/pictures/miso/Hosting-740719841457623546/original/77bb9031-420a-43fc-99cc-241c21873636.jpeg',
        id:5,
        },
        {
        picture:'https://a0.muscache.com/im/pictures/f140d8b5-449a-4632-a828-6b3008010a72.jpg',
        id:6,
        }
        ],
    
                
    },
    {
    // Basic info
    id: 11,
    label: 'Condo en Nueva Gorgona',
    text: 'El mejor apartamento en Royal Palm',
    ncamas: "4",

    // Location info
    provincia: 'Panamá',
    distrito: 'Chame',
    corregimiento: 'Nueva Gorgona',

    // Ratings and pricing
    puntuacion: '4.8 (73)',
    total: '401',
    pornoche: '172',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-602204472942251748/original/8a68f968-d4d7-4756-af4b-f8180340e887.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-602204472942251748/original/8a68f968-d4d7-4756-af4b-f8180340e887.jpeg',
    img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-602204472942251748/original/8a68f968-d4d7-4756-af4b-f8180340e887.jpeg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-818182781939756384/original/5386bce5-045a-47e2-9330-af7e7dd0f0ff.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-818182781939756384/original/9667d4e7-83c4-4c88-acd9-61f167ac229f.jpeg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-818182781939756384/original/f5caf410-44fd-4031-ac9a-cd00b8342b94.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-818182781939756384/original/54051066-5ba3-4ced-b3c8-6d4d4b8c5927.jpeg',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-818182781939756384/original/5be13abd-7348-4993-8185-df55f67763f5.jpeg',
            id:6,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-818182781939756384/original/4e9544e5-a8b5-4261-8454-bca9a32eb13c.jpeg',
            id:7,
        }
    ],  
    },
    {
  // Basic info
  id: 12,
  label: 'Condo en Rio Hato, Panamá',
  text: 'Moderno con Estilo Vista al mar...',
  ncamas: "4",

  // Location info
  provincia: 'Coclé',
  distrito: 'Antón',
  corregimiento: 'Río Hato',

  // Ratings and pricing
  puntuacion: '4.6 (55)',
  total: '401',
  pornoche: '82',

  // Image URLs
  img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-49206876/original/ad679757-aa55-4860-99b3-6207514314ac.jpeg',
  img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-49206876/original/ad679757-aa55-4860-99b3-6207514314ac.jpeg',
  img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-49206876/original/ad679757-aa55-4860-99b3-6207514314ac.jpeg',

  // Image array
  img:[
      {
          picture:'https://a0.muscache.com/im/pictures/miso/Hosting-749266563196599558/original/1891f41c-4735-4f95-8319-6dc529ca5d15.jpeg',
          id:1,
      },
      {
          picture:'https://a0.muscache.com/im/pictures/miso/Hosting-749266563196599558/original/592ae9f3-3fa9-44b7-b068-2dce3d850528.jpeg',
          id:2,
      },
      {
          picture:'https://a0.muscache.com/im/pictures/miso/Hosting-749266563196599558/original/10e91e48-4c3e-4905-96d9-ca033f734889.jpeg',
          id:3,
      },
      {
          picture:'https://a0.muscache.com/im/pictures/miso/Hosting-749266563196599558/original/1e5e5a93-974e-4cad-8de4-ea2faeabb414.jpeg',
          id:4,
      },
      {
          picture:'https://a0.muscache.com/im/pictures/miso/Hosting-749266563196599558/original/681bf4d7-a178-4c93-80b4-ff37a28d758a.jpeg',
          id:5,
      },
      {
          picture:'https://a0.muscache.com/im/pictures/miso/Hosting-749266563196599558/original/751cef35-3fd7-4a73-a870-7869aed9d70f.jpeg',
          id:6,
      }
      ]
    },
    {
    // Basic info
    id: 13,
    label: 'Place to stay in Panamá',
    text: "Studio Standard (1 Cama King)",
    ncamas: "1",
    type:'Master',

    // Location info
    provincia: 'Panamá',
    distrito: 'Panamá',
    corregimiento: 'Bella Vista',

    // Ratings and pricing
    puntuacion: '4.6 (64)',
    total: '401',
    pornoche: '51',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-54353345/original/311b00a1-f056-4c5a-b2da-cb273e23058e.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-36598885/original/8cdf5462-535f-4eb5-8bfc-b0710412bd0a.jpeg',
    img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-36598885/original/8cdf5462-535f-4eb5-8bfc-b0710412bd0a.jpeg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54353345/original/311b00a1-f056-4c5a-b2da-cb273e23058e.jpeg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54353345/original/7fc0a803-1342-4f1d-a9f8-8e0b0c348436.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54353345/original/0d7cd2bd-0576-411c-9676-e33e8cca17a7.jpeg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54355679/original/c0d6395e-24fc-49d6-9ef4-63b2e084ed6d.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54353345/original/0d7cd2bd-0576-411c-9676-e33e8cca17a7.jpeg',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54353345/original/7fc0a803-1342-4f1d-a9f8-8e0b0c348436.jpeg',
            id:6,
        },
        ]   
    },
    { 
    // Basic info
    id: 14,
    label: 'Apartment in Panamá',
    text: 'Renovated 1BD apartment in historic Casco Viejo',
    ncamas: "1",
    type: 'Normal',

    // Location info
    provincia: 'Panamá',
    distrito: 'Panamá',
    corregimiento: 'San Felipe',

    // Ratings and pricing
    puntuacion: '4.7 (27)',
    total: '401',
    pornoche: '43',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-847756158020537813/original/fd2192db-18d9-4672-beb1-71105c416a0f.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-656267262053988998/original/6c3922ce-0363-4451-8aae-78e06e08e70a.jpeg',
    img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-656267262053988998/original/6c3922ce-0363-4451-8aae-78e06e08e70a.jpeg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-847756158020537813/original/fd2192db-18d9-4672-beb1-71105c416a0f.jpeg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-847756158020537813/original/c9bc2c21-d265-4b65-917d-e984e1524b48.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-847756158020537813/original/9a8aebcd-9ca9-4d8e-a31e-286bf6246f0d.jpeg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-847756158020537813/original/fd2192db-18d9-4672-beb1-71105c416a0f.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-847756158020537813/original/51d323a0-205c-419d-9606-427d00bfd32c.jpeg',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-847756158020537813/original/4fe5a48f-def1-4b94-97fc-0d7a2ae9e804.jpeg',
            id:6,
        }
        ]
    },
    {
   // Basic info
   id: 15,
   label: 'Apartment in Panamá',
   text: 'Best Location, Beautiful Apartment, Panama',
   ncamas: "1",
   type: 'Normal',

   // Location info
   provincia: 'Panamá',
   distrito: 'Panamá',
   corregimiento: 'Bella Vista',

   // Ratings and pricing
   puntuacion: '4.9 (48)',
   total: '401',
   pornoche: '114',

   // Image URLs
   img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-565986046705568105/original/2ed8225e-36c9-44d2-8904-b27579731951.jpeg',
   img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-800250652648024285/original/5711a2f6-4305-4d82-84b0-032ab615e47d.jpeg',
   img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-800250652648024285/original/5711a2f6-4305-4d82-84b0-032ab615e47d.jpeg',

   // Image array
   img:[
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-565986046705568105/original/2ed8225e-36c9-44d2-8904-b27579731951.jpeg',
           id:1,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-565986046705568105/original/6fb6917c-ca7c-4fc7-8330-624741fa712e.jpeg',
           id:2,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-565986046705568105/original/5dd59f48-1238-416d-99b8-e02c4c340d37.jpeg',
           id:3,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-565986046705568105/original/c54e7612-05c4-4176-bcb4-6cabe7566679.jpeg',
           id:4,
       },
       {
           picture:'https://a0.muscache.com/im/pictures/miso/Hosting-565986046705568105/original/ac031b49-4455-430a-abff-155c1c8b9c09.jpeg',
           id:5,
       },
       {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-565986046705568105/original/ba220be0-71c2-4050-80de-feb311ae78c2.jpeg',
            id:6,
        },
        ]
    },
    { 
    // Basic info
    id: 16,
    label: 'Condo in Panamá',
    text: '★★★★★Luxury 1 bedroom apt with free parking',
    ncamas: "1",
    type: 'Normal',

    // Location info
    provincia: 'Panamá',
    distrito: 'Panamá',
    corregimiento: 'Parque Lefevre',

    // Ratings and pricing
    puntuacion: '4.9 (89)',
    total: '401',
    pornoche: '75',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-628163608029910436/original/7c982db6-ae9a-4eea-8f0b-300a06f7b39b.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-628239343174073625/original/254d1924-9819-41d3-baef-f517c29d8bbc.jpeg',
    img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-628239343174073625/original/254d1924-9819-41d3-baef-f517c29d8bbc.jpeg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-628163608029910436/original/7c982db6-ae9a-4eea-8f0b-300a06f7b39b.jpeg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-626658760655400671/original/8689ccce-c3da-486c-bbf2-18b9ae4a238b.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-626658760655400671/original/4e40ddd1-91dc-4623-b27d-32363550b730.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-628163608029910436/original/2d7ce65a-e377-471a-ae34-fa37f4c3a3c3.jpeg',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-628163608029910436/original/ae14fa96-e865-4fba-aaf6-a3627c7b6296.jpeg',
            id:6,
        },
    ]    
    },
    { 
    // Basic info
    id: 17,
    label: 'Condo in Panamá',
    text: 'Columbari',
    ncamas: "1",
    type: 'Normal',

    // Location info
    provincia: 'Panamá',
    distrito: 'Panamá',
    corregimiento: 'Betania',

    // Ratings and pricing
    puntuacion: '5.0 (11)',
    total: '401',
    pornoche: '56',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-842630722507878415/original/82e38bb7-f5a4-4029-8371-fed21ed9f64c.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/miso/Hosting-50773133/original/eaf5492e-e776-48c1-a48c-a1ffdeb6e791.jpeg',
    img3: 'https://a0.muscache.com/im/pictures/miso/Hosting-50773133/original/eaf5492e-e776-48c1-a48c-a1ffdeb6e791.jpeg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-842630722507878415/original/82e38bb7-f5a4-4029-8371-fed21ed9f64c.jpeg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-842630722507878415/original/b3adbcee-1e69-4dff-9344-bfa469970f4f.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-842630722507878415/original/2e6fe8c5-818f-4419-b467-010ea6686327.jpeg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-842630722507878415/original/afe178d3-93de-437d-85e5-c7b1ac394cc5.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/dba71376-0ccc-4af1-bf36-7280fe76027a.jpg',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/9b2109be-7fdb-4c30-bd9e-3f612cd8d228.jpg',
            id:6,
        }
    ]     
    },
    {
    // Basic info
    id: 18,
    label: 'Apartment in Panamá',
    text: 'The Comic Pop Studio by AcoModo',
    ncamas: "1",
    type: 'Normal',

    // Location info
    provincia: 'Panamá',
    distrito: 'Panamá',
    corregimiento: 'Calidonia',

    // Ratings and pricing
    puntuacion: '4.9 (55)',
    total: '401',
    pornoche: '47',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/prohost-api/Hosting-772411966157241684/original/3b49561f-f164-4a90-8e3b-f963f57b3d8f.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-772411966157241684/original/3b49561f-f164-4a90-8e3b-f963f57b3d8f.jpeg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-772411966157241684/original/a8269e7c-1f2d-44b5-beea-882759d60491.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-772411966157241684/original/b81a5400-58b9-4101-b504-653256f1bb9d.jpeg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-772411966157241684/original/57454deb-d997-4686-b036-6c4292d39f97.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-772411966157241684/original/2766aac2-9974-4af0-a0eb-094f98368591.jpeg',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-772411966157241684/original/b6cec39f-338d-4879-ae6e-374cf77d5707.jpeg',
            id:6,
        }
    ]
    },
    {
    // Basic info
    id: 19,
    label: 'Villa in Coclé',
    text: 'The Ocean Lake Villa by AcoModo @ BV (5Bdr)',
    ncamas: "6",
    type: 'Normal',

    // Location info
    provincia: 'Coclé',
    distrito: 'Aguadulce',
    corregimiento: 'El Roble',

    // Ratings and pricing
    puntuacion: '4.8 (55)',
    total: '401',
    pornoche: '42',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/prohost-api/Hosting-874603042241162797/original/700e09c9-c7a1-4b99-b314-f454f3f920e5.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-874603042241162797/original/700e09c9-c7a1-4b99-b314-f454f3f920e5.jpeg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-874603042241162797/original/5fdb310c-a903-4f1c-bf69-1f1aa43f8206.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-874603042241162797/original/a90dec98-847b-4a4b-8804-02b1217bfe61.jpeg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-874603042241162797/original/b2b615fd-9dae-4aed-8446-972c5c390331.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-874603042241162797/original/fb504519-2cd4-4d4c-8b49-9abdc897cbb1.jpeg',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-874603042241162797/original/708236f0-fd07-4c6a-a7eb-24ca7740bec5.jpeg',
            id:6,
        }
    ]   
    },
    {
    // Basic info
    id: 20,
    label: 'Home in Miraflores',
    text: 'Casa cómoda con Rancho y jacuzzi cerca de Playas',
    ncamas: "4",
    type: 'Normal',

    // Location info
    provincia: 'Coclé',
    distrito: 'Natá',
    corregimiento: 'Villarreal',

    // Ratings and pricing
    puntuacion: '4.5 (10)',
    total: '401',
    pornoche: '48',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-871263445695234581/original/8deca8e1-5447-4547-9086-8e1179fb7c95.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-871263445695234581/original/8deca8e1-5447-4547-9086-8e1179fb7c95.jpeg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-871263445695234581/original/330cfc9e-549c-4681-941a-e280b6d8279e.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-871263445695234581/original/78ca2794-e168-4a73-9d6c-c5dbf3b02e9f.png',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-871263445695234581/original/1775e45a-5824-4ba9-8ce5-d5a5dca9bdba.png',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-871263445695234581/original/38db7016-aeb0-4a99-846d-775714db84b5.png',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/miso/Hosting-871263445695234581/original/41164be4-2b62-4de2-bc7d-92d361f26c22.png',
            id:6,
        }
    ]  
    },
    {
     // Basic info
    id: 21,
    label: 'Villa in Cocle',
    text: 'The Villa at Ocean Lake by AcoModo @ BV (4Bdr)',
    ncamas: "1",
    type: 'Normal',

    // Location info
    provincia: 'Coclé',
    distrito: 'Olá',
    corregimiento: 'El Palmar',

    // Ratings and pricing
    puntuacion: '4.1 (2)',
    total: '401',
    pornoche: '313',

    // Image URLs
    img1: 'https://a0.muscache.com/im/pictures/prohost-api/Hosting-881198803387356579/original/bc1ed228-1b88-4d59-b95a-40f7f66ed8ae.jpeg',
    img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',

    // Image array
    img:[
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-881198803387356579/original/bc1ed228-1b88-4d59-b95a-40f7f66ed8ae.jpeg',
            id:1,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-881198803387356579/original/9e80fef6-e8d2-4d74-9586-608f52b4b886.jpeg',
            id:2,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-881198803387356579/original/d5773a72-57e6-4218-8ef0-1e87940400fe.jpeg',
            id:3,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-881198803387356579/original/ff60f5d7-9e86-4643-973d-2dca84917097.jpeg',
            id:4,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-881198803387356579/original/59509413-e4dc-4931-aa06-3afcec44def2.jpeg',
            id:5,
        },
        {
            picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-881198803387356579/original/3566567a-c2df-4aea-a902-3c841aefb094.jpeg',
            id:6,
        }
    ] 
    },
    {
        // Basic info
       id: 22,
       label: 'Villa in Río Grande',
       text: 'Cheerful 3 bedroom countryside Villa with pool.',
       ncamas: "10",
       type: 'Normal',
   
       // Location info
       provincia: 'Coclé',
       distrito: 'Penonomé',
       corregimiento: 'Tulú',
   
       // Ratings and pricing
       puntuacion: '4.9 (27)',
       total: '401',
       pornoche: '119',
   
       // Image URLs
       img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-603816764841873383/original/fab2517d-26e2-4e89-a67d-8aaf87fd236a.jpeg',
       img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
       img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
   
       // Image array
       img:[
           {
               picture:'https://a0.muscache.com/im/pictures/miso/Hosting-603816764841873383/original/fab2517d-26e2-4e89-a67d-8aaf87fd236a.jpeg',
               id:1,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/miso/Hosting-603816764841873383/original/f21d7bed-a494-4310-834f-154a98f1fde9.jpeg',
               id:2,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/miso/Hosting-603816764841873383/original/8a480f7d-dd8a-4d8a-b189-7db17885ac52.jpeg',
               id:3,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/miso/Hosting-603816764841873383/original/431ab6e3-aa87-4264-b1d1-11036b958bf5.jpeg',
               id:4,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/miso/Hosting-603816764841873383/original/bb245cef-b25a-42b0-b855-65cea65ab07f.jpeg',
               id:5,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/miso/Hosting-603816764841873383/original/f4565d04-07ec-4418-9d29-a0706271d95f.jpeg',
               id:6,
           }
       ] 
    },
    {
       // Basic info
       id: 23,
       label: 'Home in Penonome',
       text: 'New house, fenced in, a quiet and safe environment',
       ncamas: "3",
       type: 'Normal',
   
       // Location info
       provincia: 'Coclé',
       distrito: 'Penonomé',
       corregimiento: 'Río Grande',
   
       // Ratings and pricing
       puntuacion: '4.9 (48)',
       total: '401',
       pornoche: '47',
   
       // Image URLs
       img1: 'https://a0.muscache.com/im/pictures/b07dadf4-0ed8-436a-8966-b3cd8368498d.jpg',
       img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
       img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
   
       // Image array
       img:[
           {
               picture:'https://a0.muscache.com/im/pictures/b07dadf4-0ed8-436a-8966-b3cd8368498d.jpg',
               id:1,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/a5df83fb-aadd-48a6-b404-0e07a0f749e1.jpg',
               id:2,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/f41065b0-9530-4b1e-83db-63eaac09753d.jpg',
               id:3,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/23ec8bb4-7e77-4092-867c-820bc5ef2007.jpg',
               id:4,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/c3718dc7-ab37-4796-8609-f24276e73697.jpg',
               id:5,
           },
           {
               picture:'https://a0.muscache.com/im/pictures/fab130f1-6d0d-422c-ae29-f431bcfce54a.jpg',
               id:6,
           }
       ] 
    },
    {
        // Basic info
        id: 24,
        label: 'Farm stay in Marica Abajo',
        text: 'Tiny House in petting farm surrounded by nature',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Coclé',
        distrito: 'La Pintada',
        corregimiento: 'El Harino',
    
        // Ratings and pricing
        puntuacion: '4.7 (47)',
        total: '401',
        pornoche: '83',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-54128566/original/73daf290-9d12-48ed-9baa-2687b7620a67.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54128566/original/73daf290-9d12-48ed-9baa-2687b7620a67.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54128566/original/59cf9492-9b85-4ca7-afcc-a2c3562ec70f.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54128566/original/be8e20a6-01d7-4895-95bd-41a2cc0f3293.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54128566/original/c6613e56-40e6-4de7-8b8d-56b591f95383.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54128566/original/68b60aa8-ba71-486b-849b-b2d64a61054c.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-54128566/original/d93c57db-7566-4e42-83d5-1a75fc400e18.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 25,
        label: 'Home in San Carlos',
        text: 'Cabaña Pika 02 by Pika Cabins',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Coclé',
        distrito: 'Aguadulce',
        corregimiento: 'Pueblos Unidos',
    
        // Ratings and pricing
        puntuacion: '4.7 (39)',
        total: '401',
        pornoche: '71',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-803504754325561440/original/daad5e4c-42c3-4585-8d9a-5dd350562d28.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-803504754325561440/original/daad5e4c-42c3-4585-8d9a-5dd350562d28.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-803504754325561440/original/65f101a1-bf27-4726-a84e-cf47dc18d494.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-803504754325561440/original/41a5de78-9dc3-40bf-80cb-c6401d29d642.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-803504754325561440/original/77ff186d-ed29-4e91-8bb1-0f3e9addb4b9.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-803504754325561440/original/66e52db1-7fb1-41fa-8c02-a572ddb513a4.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-803504754325561440/original/e55d3c8b-5389-43de-85c7-ea80b2829189.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 26,
        label: 'Room in Cocle',
        text: 'Laurel Cottage in Penonomé',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Coclé',
        distrito: 'Olá',
        corregimiento: 'La Pava',
    
        // Ratings and pricing
        puntuacion: '4.9 (33)',
        total: '401',
        pornoche: '28',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-791472248145989821/original/abe3b8ab-ef6b-4d61-8f52-16544780c767.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-791472248145989821/original/abe3b8ab-ef6b-4d61-8f52-16544780c767.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-791472248145989821/original/080a22d4-2b96-4b2a-b410-27b89af4d611.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/fd8ceb62-edaa-4320-9d4b-b1cc07199b1e.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-791472248145989821/original/f085028b-abf2-4d1c-91f8-7f169f66deaf.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-791472248145989821/original/dc335c25-9cd7-4ef3-8b3b-72e15326a442.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-791472248145989821/original/b23460be-1b15-49a3-ba5d-85085ee25c1a.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 27,
        label: 'Bungalow in Panamá',
        text: 'La Casa de la Brisa',
        ncamas: "5",
        type: 'Normal',
    
        // Location info
        provincia: 'Coclé',
        distrito: 'Natá',
        corregimiento: 'Villarreal',
    
        // Ratings and pricing
        puntuacion: '5.0 (40)',
        total: '401',
        pornoche: '121',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/bbdcf35b-c4f6-4b4a-8af0-8ed37d13a9f2.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/bbdcf35b-c4f6-4b4a-8af0-8ed37d13a9f2.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/cbc8e8d8-bc81-456d-ad61-43456744f751.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e26fd6a1-7d61-4dd9-84e3-e7e591e49146.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/77738218-663a-4714-b18d-2649793a0ef4.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/06e8a45d-0890-4033-a1bb-4f1f60e47077.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/033a6dc8-a11a-431a-baad-c7e9a7ab989e.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 29,
        label: 'Tiny home in Gualaca',
        text: 'TROPICAL MINIHOUSE Tiny house surrounded by nature',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'Alanje',
        corregimiento: 'Palo Grande',
    
        // Ratings and pricing
        puntuacion: '4.8 (42)',
        total: '401',
        pornoche: '52',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/0e56ee6b-53c0-4389-9fdf-9602e639807f.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/0e56ee6b-53c0-4389-9fdf-9602e639807f.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/ac39f85f-1322-4735-a19e-adc188f8296b.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/dea38cc1-8293-4cb4-8973-3d602f702c27.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/af272a98-7a38-4230-96c5-ad856b9b7f30.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e8c0a725-047d-499c-8bf2-a56bc814b3d5.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/281b7316-5326-4afb-ac4d-67af963bba0a.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 30,
        label: 'Guest suite in Municipio de David',
        text: 'CasaMonèt',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'David',
        corregimiento: 'Guacá',
    
        // Ratings and pricing
        puntuacion: '4.9 (206)',
        total: '401',
        pornoche: '33',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/d30cf83e-fefc-4fe4-8d41-4d27e1794bf5.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/d30cf83e-fefc-4fe4-8d41-4d27e1794bf5.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/46cba24c-f4ad-4703-a069-6c1148f30599.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/3a417e43-c55d-46f0-b59e-c3123aa70241.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/6eec9568-caa4-43f8-8e4b-009d59063d81.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2483ebc4-9aab-47dd-974e-df48525c5ad8.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/becbcbf6-9dab-43c8-ba3d-c358f0ebfbb8.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 31,
        label: 'Cabin in Alto Boquete',
        text: 'Linda Cabaña Cañon, Naturaleza y Rio 3',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'Boquete',
        corregimiento: 'Alto Boquete',
    
        // Ratings and pricing
        puntuacion: '4.8 (45)',
        total: '401',
        pornoche: '44',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-756356288784770084/original/fccc214a-f56e-4c03-a2e4-0e58a8d496c5.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-756356288784770084/original/fccc214a-f56e-4c03-a2e4-0e58a8d496c5.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-756356288784770084/original/95d935d6-cf62-4791-9739-5cf6f64097c5.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-756356288784770084/original/b0764e83-f3af-4a31-a3da-20a967f2d0fe.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-756356288784770084/original/6a249b06-b7e2-4409-ab63-088e2b81a8ea.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-756356288784770084/original/bb48fa97-4ee1-4faa-8447-1ae3104c0d95.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-756356288784770084/original/8fce822b-6eb1-4396-91b0-d05504ac96da.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 32,
        label: 'Home in Los Algarrobos',
        text: 'Cozy and relaxing house with terrace',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'Dolega',
        corregimiento: 'Los Algarrobos',
    
        // Ratings and pricing
        puntuacion: '4.9 (56)',
        total: '401',
        pornoche: '64',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/7c29d1d4-152c-4804-a328-60be17101b6d.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/7c29d1d4-152c-4804-a328-60be17101b6d.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/6db7ba6c-885e-4f75-a08b-898c1442fb44.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/6b5ba41c-fa73-4f21-a531-706adda654e1.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/992f6231-826e-4979-9863-9e6b0fb26ad0.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/1eea64ff-91be-4b33-9468-2afbf490324c.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/a6395544-d0b0-4483-8ac4-b2e745efc2c0.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 33,
        label: 'Home in David',
        text: 'Beautiful house in the heart of David.',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'David',
        corregimiento: 'David Sur',
    
        // Ratings and pricing
        puntuacion: '5.0 (17)',
        total: '401',
        pornoche: '66',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/7eb6bbca-c87b-4592-9c0f-a5d7d8919435.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/7eb6bbca-c87b-4592-9c0f-a5d7d8919435.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/d8cf82b9-e033-4d0a-a814-fd181013347c.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/26575920-9c32-4d7b-865c-6b3ea6d23832.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/a0fa581e-85ac-425b-8116-8496b2888ca1.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/08139ae2-9a41-4953-ab04-c0063a463e21.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-765386459462284298/original/57b2171b-5f0b-4460-aa79-78f8a93ede57.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 34,
        label: 'Home in Alto Boquete',
        text: 'Two Floor Home, Stunning View & Nature 1',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'Boquete',
        corregimiento: 'Los Naranjos',
    
        // Ratings and pricing
        puntuacion: '4.6 (54)',
        total: '401',
        pornoche: '52',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/e0dda6b2-3402-4c2b-bed0-59dbe80b16d0.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/e0dda6b2-3402-4c2b-bed0-59dbe80b16d0.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-44264880/original/2ab2b935-ba46-491c-ad9c-17955ad6d79a.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-44264880/original/0bf389bc-44fa-4015-aa00-c59302f55d4f.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-44264880/original/4d277b6c-15cc-419c-8d12-92a854efc0c9.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-44264880/original/0656971d-7f5f-45f6-8b00-30d89cc49063.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-44264880/original/dd9b8f79-26f3-4e71-bb2c-b8e26a7ea799.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 35,
        label: 'Cottage in Caldera',
        text: 'Cozy cottage in Caldera, Boquete.',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'Boquete',
        corregimiento: 'Caldera',
    
        // Ratings and pricing
        puntuacion: '4.8 (62)',
        total: '401',
        pornoche: '49',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/ff9ff173-b98f-49d1-9b47-211751983755.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/ff9ff173-b98f-49d1-9b47-211751983755.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e5644a45-d8c7-4220-bbf7-b25676025607.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/079b4614-0312-47ae-b855-ccebe436e16e.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/ca24913c-be37-46c4-8480-022d141e292f.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/7423b567-60b4-43bd-98a8-0b7b45f39934.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/82b64fc9-8654-4382-95f6-c848b34a4fe1.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 36,
        label: 'Home in Los Algarrobos',
        text: 'Cozy house on Via Boquete surrounded by gardens',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'Dolega',
        corregimiento: 'Los Algarrobos',
    
        // Ratings and pricing
        puntuacion: '4.9 (23)',
        total: '401',
        pornoche: '41',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/1586cb63-1f2b-4c4a-b5be-c6fbb1a3ced3.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/1586cb63-1f2b-4c4a-b5be-c6fbb1a3ced3.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2e8fcc33-87a0-4e96-8cc0-4601eda2f127.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/b8dba9af-239a-4e43-a087-ed742884e8ed.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/5f2dc4b8-4513-48a1-ae1e-e61a106c49a5.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/6e3c0340-2683-4c4e-82d4-ef997243dd8e.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-715124008017737154/original/54962d50-7c85-4a60-a04e-3583a32ae932.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 37,
        label: 'Guesthouse in David',
        text: 'Pool House with Shared Pool Access',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'David',
        corregimiento: 'San Carlos',
    
        // Ratings and pricing
        puntuacion: '5.0 (10)',
        total: '401',
        pornoche: '57',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-826964679285878657/original/30b50ad0-793b-4287-8c19-c3a6bb340873.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-826964679285878657/original/30b50ad0-793b-4287-8c19-c3a6bb340873.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-826964679285878657/original/9240a733-347e-42d0-83c7-df0d61c4dfa6.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-826964679285878657/original/24631002-7596-419c-8246-47abcf1d2671.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-826964679285878657/original/ce2ee1b0-2ef5-4da5-877b-755074895c82.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-826964679285878657/original/9f134311-2ab9-44e1-bd45-e84d5a20d90d.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-826964679285878657/original/a556fe4c-10e9-441d-a205-90b6240393ca.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 38,
        label: 'Bungalow in Boca Chica',
        text: 'Finca Colibri',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Chiriquí',
        distrito: 'San Lorenzo',
        corregimiento: 'Boca Chica',
    
        // Ratings and pricing
        puntuacion: '4.8 (47)',
        total: '401',
        pornoche: '102',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/777e9290-9996-46f0-b67a-4e238466cdff.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/777e9290-9996-46f0-b67a-4e238466cdff.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/d8210544-3957-43c5-a188-edf95e992bec.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/21100e75-7bee-4b7b-91e5-a6194c4105cd.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/9193abd6-06d5-49ba-abab-072082fb4856.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/9c2cb5e5-b3e0-4cc2-9730-26ab0bc774ac.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/f23ab8a6-1559-4eea-b939-f0e15b349260.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 39,
        label: 'Cabin in Bocas del Toro',
        text: '1 br Cabin w/pool, close to the best surf spots',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Bocas del Toro',
        corregimiento: 'Bastimentos',
    
        // Ratings and pricing
        puntuacion: '4.8 (53)',
        total: '401',
        pornoche: '99',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-47873937/original/bb00968f-3fa6-4e34-bb49-0bafffe8eba3.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-47873937/original/bb00968f-3fa6-4e34-bb49-0bafffe8eba3.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-47873937/original/418ae909-791b-44a0-828c-fb8386d11071.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-47873937/original/162ed58a-8f89-409f-b63f-e71e63c2de86.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-47873937/original/06c2edec-744b-4a49-ab68-bb520ad6f054.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-47873937/original/2bcc089c-9a55-4e92-b000-5259cb4098d1.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-47873937/original/4694ecdb-db51-481d-af2a-db64ebc69ef3.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 40,
        label: 'Treehouse in Isla Colon',
        text: 'Secret Jungle Treehouse by the Sea',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Almirante',
        corregimiento: 'Miraflores',
    
        // Ratings and pricing
        puntuacion: '4.9 (93)',
        total: '401',
        pornoche: '181',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/68de59f3-b3bf-4f7e-9305-415a613e72ce.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/68de59f3-b3bf-4f7e-9305-415a613e72ce.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/7210c035-c923-46f4-baf4-86dc691cb057.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/01d434a4-d504-47b6-ad1f-d6fcee7d8e4f.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/0b3ddad9-6129-4ce8-9fef-9b9d8cd3c039.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/d1cc5b2b-5894-4fd5-9191-13e4c34aaafd.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/8260ea54-5fd5-4c7f-9430-8d7ce8de0f28.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 41,
        label: 'Island in Tierra Oscura',
        text: 'Floating Lodge La Casa Nenufar',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Bocas del Toro',
        corregimiento: 'Tierra Oscura',
    
        // Ratings and pricing
        puntuacion: '4.9 (158)',
        total: '401',
        pornoche: '124',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-13081373/original/4d434e52-c971-4e83-a427-34cc4007ead2.png',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-13081373/original/4d434e52-c971-4e83-a427-34cc4007ead2.png',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-13081373/original/69884e86-5875-4dee-a1ee-2254ebc7e8e6.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-13081373/original/f5897d8d-fd6f-4223-a46e-cb2797b12f33.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-13081373/original/57557a35-e154-4251-811e-1f84e9f33fd2.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-13081373/original/41d91902-b381-4f9e-a079-60fd3039a1c2.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-13081373/original/317855d5-7265-436b-b276-f93dec98b359.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 42,
        label: 'Cabin in Bocas del Toro',
        text: 'Cozy Studio Cabin over the Caribbean',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Bocas del Toro',
        corregimiento: 'Boca del Drago',
    
        // Ratings and pricing
        puntuacion: '5.0 (22)',
        total: '401',
        pornoche: '67',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-725999086237519995/original/c7e65cc2-278e-4c40-83e0-8ef87a653486.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-725999086237519995/original/c7e65cc2-278e-4c40-83e0-8ef87a653486.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-725999086237519995/original/38c91175-2edf-44cf-84f7-1ece99fdab2a.png',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-725999086237519995/original/8aae318a-5210-4c5e-965e-9b6e4d5bf7b3.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-725999086237519995/original/91f3b82e-0286-4786-8421-2fc406cd325c.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-725999086237519995/original/c2be2294-70d9-4ea0-9818-318deb4415c1.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-725999086237519995/original/f3e4e14f-a581-423c-8b6f-3d313e2e19b8.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 43,
        label: 'Villa in Bocas del Toro',
        text: 'Bocas del toro - Villa over the water- Bahia Coral',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Chiriquí Grande',
        corregimiento: 'Punta Robalo',
    
        // Ratings and pricing
        puntuacion: '5.0 (20)',
        total: '401',
        pornoche: '377',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-646261070902044746/original/ad1f7cfd-3e0d-47f3-861e-31ead4df870c.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-646261070902044746/original/ad1f7cfd-3e0d-47f3-861e-31ead4df870c.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/37cb28f1-3ecf-4d45-82fc-2c18fd374bb5.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-646261070902044746/original/d08a8bfa-1b35-4826-af04-41838aeccf27.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-588459083742129316/original/a69d152c-54fc-4522-a42e-f7716336d1a1.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-646261070902044746/original/e24a01bc-eade-4482-b2e9-45aab8cce075.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e9bd3720-7f87-4fcc-a9ae-90cf93fefb5d.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 44,
        label: 'Room in Bocas del Toro',
        text: 'Garden Room Original with King bed',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Changuinola',
        corregimiento: 'La Mesa',
    
        // Ratings and pricing
        puntuacion: '4.7 (51)',
        total: '401',
        pornoche: '31',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/feaa1200-461b-4894-857a-c3af9cf6c5f7.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/feaa1200-461b-4894-857a-c3af9cf6c5f7.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/f0515919-f4c0-40c2-aaef-368fd48f0d91.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/7aefc29f-383d-4268-9b1b-6ca5d5c8ca21.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/8999eb0c-e898-4c98-9bc8-ea842b627713.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-560071800729699786/original/55192ef5-20e8-4584-9439-eb784241a4d6.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/82a0a3c1-6953-4dcf-bc16-f7953d7bf4fb.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 45,
        label: 'Home in Colón Island',
        text: 'Casa Guacamole',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Almirante',
        corregimiento: 'Almirante',
    
        // Ratings and pricing
        puntuacion: '4.5 (33)',
        total: '401',
        pornoche: '46',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/2f80ad7f-b80f-4ec7-972a-be6d75dc5387.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/2f80ad7f-b80f-4ec7-972a-be6d75dc5387.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/89365aba-f5f3-42cd-8f61-0f6d04220068.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/df9eb210-ff76-46c5-bf62-d2e2aed3a25c.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/4b70a523-1a94-442b-8776-ca2ee330637a.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/32b35862-33d2-4e13-9530-18200da90851.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/23ec1576-fe26-4812-8203-37045d8590fa.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 46,
        label: 'Vacation home in Bocas del Toro',
        text: 'Private Surf Beach House, The Pool House Too',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Almirante',
        corregimiento: 'Ceiba',
    
        // Ratings and pricing
        puntuacion: '5.0 (27)',
        total: '401',
        pornoche: '100',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-736450100505511671/original/8b40173d-0c58-4b92-a13c-a8cc7aa5959c.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-736450100505511671/original/8b40173d-0c58-4b92-a13c-a8cc7aa5959c.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-736450100505511671/original/c381af4f-8b68-4ad3-8165-0614e7e056e1.png',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-736450100505511671/original/885164f1-f89b-4095-8431-21edc1b890eb.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-736450100505511671/original/835ea2fa-6660-4030-b030-fb882fd2da4b.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-736450100505511671/original/bc54854f-8c25-4e3d-b782-4ccf7fb18d77.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-736450100505511671/original/b6adfbf3-0862-4378-b01f-2be27bcc95e9.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 47,
        label: 'Home in Bocas del Toro -  colon island',
        text: 'Purple House One Over The Water',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Bocas del Toro',
        corregimiento: 'San Cristóbal',
    
        // Ratings and pricing
        puntuacion: '4.8 (238)',
        total: '401',
        pornoche: '114',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-1507982/original/5bce80a9-99ad-47e6-83a3-fd626fba9204.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-1507982/original/5bce80a9-99ad-47e6-83a3-fd626fba9204.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-1507982/original/ac5fe7e2-a712-4978-a3e5-15e36fb20338.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-1507982/original/c4ccdd3e-46af-42f8-b0fb-6cfaa80c03d4.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-1507982/original/395c6eba-d124-4a2c-939a-091ddb52c778.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-1507982/original/bbb2407d-df40-4b2f-a13e-1a7608cf020e.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-1507982/original/6bce075c-cccc-4b9f-809e-585a4175f024.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 48,
        label: 'Cabin in Bocas del Toro',
        text: 'Monkey Tiny House - Pool - Jungle View Breakfast',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Bocas del Toro',
        distrito: 'Changuinola',
        corregimiento: 'Guabito',
    
        // Ratings and pricing
        puntuacion: '5.0 (20)',
        total: '401',
        pornoche: '120',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-649431000174053528/original/17b1774f-500e-4d90-b4e5-e43e8fe7900b.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-649431000174053528/original/17b1774f-500e-4d90-b4e5-e43e8fe7900b.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-649431000174053528/original/793773e0-9714-4053-8616-03544660dc5b.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-649431000174053528/original/bd12f9b2-c7f6-43e7-9c5a-5cc1bb6375fc.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-649431000174053528/original/51730fe1-dd98-4480-8ce7-75422e9819d7.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-649431000174053528/original/e09d3335-3ccc-4fd6-85c1-81d09f85672d.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-649431000174053528/original/b5823a1d-239e-4753-80ff-35a4c3c5aaca.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 49,
        label: 'Condo in Colón',
        text: 'HIDDEN BEACH RESORT & MARINA',
        ncamas: "4",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Donoso',
        corregimiento: 'Gobea',
    
        // Ratings and pricing
        puntuacion: '4.8 (123)',
        total: '401',
        pornoche: '238',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/7fcbfefe-58bb-4f36-bdea-1607448d1b27.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/7fcbfefe-58bb-4f36-bdea-1607448d1b27.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/b0816f7b-0a2b-4fd5-ab75-88da759ef37e.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/87a3c6e5-db1f-48f7-9b63-36270ee38bfc.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/38f31f5e-f46d-47a4-93f2-9c695d4bd98d.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/f956c4dc-4838-42c0-adc0-5bec52a39c1a.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/88b80b80-ea4a-4197-9f10-b5aa897e6f5d.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 50,
        label: 'Apartment in María Chiquita',
        text: '105T1 Estudio en PH Bala Beach',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Portobelo',
        corregimiento: 'María Chiquita',
    
        // Ratings and pricing
        puntuacion: '4.9 (18)',
        total: '401',
        pornoche: '75',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/eb2f95ab-f58d-4b83-8230-77dfd3d076e8.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/eb2f95ab-f58d-4b83-8230-77dfd3d076e8.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/aa5ae9a9-167d-4a4e-915d-f7f005ee5d90.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/8f2a128e-b691-4801-9433-ccc750ca5242.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/4b110a9e-1d55-496a-8474-da9f085d3f85.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/cab361cd-35e8-4bff-9792-20db07454824.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/9f6413fc-cab0-41c8-9a78-a77ff09fbce8.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 51,
        label: 'Apartmento en colón',
        text: 'Sea view apartment in Playa Escondida Resort',
        ncamas: "5",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Santa Isabel',
        corregimiento: 'Playa Chiquita',
    
        // Ratings and pricing
        puntuacion: '4.7 (14)',
        total: '401',
        pornoche: '14',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/prohost-api/Hosting-848482884678473778/original/ddd37c9c-9244-4777-8320-cdc4e6fb108a.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-848482884678473778/original/ddd37c9c-9244-4777-8320-cdc4e6fb108a.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-848482884678473778/original/328047b3-2ba5-4880-95b8-66416b34b56a.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-848482884678473778/original/9111f2fd-f5ec-4604-bd81-558e77bccc87.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-848482884678473778/original/fb1a7205-4fd0-49ef-b62d-9e0e850373ce.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-848482884678473778/original/2571b150-ea05-4f7c-aa5c-f78cb522d548.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-848482884678473778/original/b0d843d5-ab26-4476-9dc1-2d15eac9578d.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 52,
        label: 'Home in Maria Chiquita',
        text: 'Luxury house in Playa Escondida!',
        ncamas: "5",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Portobelo',
        corregimiento: 'Isla Grande',
    
        // Ratings and pricing
        puntuacion: '4.8 (5)',
        total: '401',
        pornoche: '594',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-828776092966373582/original/4f7e8cef-fff4-4553-9dda-2d80db611dbc.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-828776092966373582/original/4f7e8cef-fff4-4553-9dda-2d80db611dbc.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-828776092966373582/original/5c078c4f-189e-4a79-b7c3-b8b2a67ac4d1.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-828776092966373582/original/8a643e34-e84e-4449-a3b4-06867345cd0d.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-828776092966373582/original/6ae121fc-b660-4071-a207-53e7bd3f7b06.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-828776092966373582/original/30e181d7-34cd-4d70-99ef-77c69df860b2.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-828776092966373582/original/735262e2-70ff-4fb7-93cf-64262b53f816.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 53,
        label: 'Apartment in the cost of colon',
        text: 'Spacious 4Br Beachside Getaway at Playa Escondida',
        ncamas: "7",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Santa Isabel',
        corregimiento: 'Playa Chiquita',
    
        // Ratings and pricing
        puntuacion: '4.8 (5)',
        total: '401',
        pornoche: '594',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-769016131481959207/original/b37eafdf-2e1e-4ea5-a4bb-cbf7186c16a7.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-769016131481959207/original/b37eafdf-2e1e-4ea5-a4bb-cbf7186c16a7.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-769016131481959207/original/29bf7e66-3efa-471d-a286-955d2cd4df13.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-769016131481959207/original/852da282-9a75-4cc3-b798-49f1c754bba5.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/c5f8bf2b-1eb5-42dc-9f6e-98037929fb63.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/05c0a61a-f0df-4375-b393-72f7bbab8196.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/9179dc09-0558-4691-99fb-47d5969537e2.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 54,
        label: 'Condo in colón',
        text: 'Relaxation by the sea',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Donoso',
        corregimiento: 'El Guásimo',
    
        // Ratings and pricing
        puntuacion: '4.8 (406)',
        total: '401',
        pornoche: '85',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/52ba9bf6-47d4-49b9-965d-830023e730c1.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/52ba9bf6-47d4-49b9-965d-830023e730c1.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/108120445/1d4cafed_original.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-8051838/original/8e7afeb2-9784-4855-a337-5e5a83cbde27.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-8051838/original/eb93251e-8140-4549-b268-bc777161f68a.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/108120049/f11c478e_original.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-8051838/original/68be8ccc-1d51-4a9a-bfc3-9e03bc804cbb.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 55,
        label: 'Apartment in Maria Chiquita',
        text: 'Exclusive Apartment at Playa Escondida in Panama',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Santa Isabel',
        corregimiento: 'Palmira',
    
        // Ratings and pricing
        puntuacion: '4.8 (69)',
        total: '401',
        pornoche: '258',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/61a42a67-34c2-466a-9cfe-52d82626e9f6.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/61a42a67-34c2-466a-9cfe-52d82626e9f6.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/7df9ca3b-947b-4b1a-a51b-222d9f5a4c62.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/17457472-a45d-4d08-98b4-bd678b5991b5.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/ffe0b5d6-0c33-4252-8636-8bebe7b40b2f.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/445c03b9-1ebd-47d0-ad91-4de8882b6d89.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2496d41d-5906-4c6f-8022-946fc6f95e61.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 56,
        label: 'Apartmento en Colón',
        text: 'Hidden Beach, a gem in the Panamanian Caribbean',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Chagres',
        corregimiento: 'Palmas Bellas',
    
        // Ratings and pricing
        puntuacion: '4.8 (67)',
        total: '401',
        pornoche: '287',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/cc3c3929-b286-4074-8b14-dc84c0b63bb2.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/cc3c3929-b286-4074-8b14-dc84c0b63bb2.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/06010181-3214-413a-956e-8649c69b8b3e.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/f921c944-468b-46ef-87aa-f957d5a3bb70.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/ff8ba989-3f40-4cf0-ba2e-54a85163e395.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/11dc4b6c-6bf5-46d4-8671-a49ba3fe54f0.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/b5650634-fdc9-46f3-aa78-bfba008eae84.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 57,
        label: 'Villa in Maria Chiquita',
        text: 'Villa en Playa Escondida Resort',
        ncamas: "7",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Portobelo',
        corregimiento: 'Isla Grande',
    
        // Ratings and pricing
        puntuacion: '4.5 (20)',
        total: '401',
        pornoche: '339',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-905277640995509888/original/114ac0bf-c8b1-451c-a5dc-509552597542.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-905277640995509888/original/114ac0bf-c8b1-451c-a5dc-509552597542.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-905277640995509888/original/782e5975-2c0c-4c76-97a9-066fa6d3cbc9.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-905277640995509888/original/73b3cb63-e02f-4bb3-8cff-eadc24404611.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-905277640995509888/original/e392de43-118a-4c6c-a7ab-7534742502a2.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-905277640995509888/original/18919d4d-53c5-45bd-9d0f-9bafea8cceff.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-905277640995509888/original/240fa648-360e-479b-9a53-da23291dbda7.jpeg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 58,
        label: 'Apartment in Maria Chiquita',
        text: '107T2B Estudio en PH Bala Beach',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Santa Isabel',
        corregimiento: 'Palenque',
    
        // Ratings and pricing
        puntuacion: '4.6 (22)',
        total: '401',
        pornoche: '80',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-775149430347770464/original/b6a6e23e-7e56-4f42-b826-551ddf2e4022.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-775149430347770464/original/b6a6e23e-7e56-4f42-b826-551ddf2e4022.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-775149430347770464/original/1ee01a5b-3261-40e3-9b36-00d210042540.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-775149430347770464/original/d97cba2e-98cf-4a8c-ab50-670b7188ef17.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/f767bdd1-8dd6-4671-bfc0-07123fbb29fc.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2134e8c4-6a50-4bdd-a925-2272e9043beb.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2c527971-1ca5-498f-8eeb-353bb2c656b7.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 59,
        label: 'Nature lodge in Darién Province',
        text: 'La Chunga',
        ncamas: "12",
        type: 'Normal',
    
        // Location info
        provincia: 'Darién',
        distrito: 'Pinogana',
        corregimiento: 'Metetí',
    
        // Ratings and pricing
        puntuacion: '4.7 (16)',
        total: '401',
        pornoche: '33',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/f62a11bc-d58d-4523-a59f-ac603da4fad1.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/f62a11bc-d58d-4523-a59f-ac603da4fad1.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/593ddc5a-a6d0-4ec1-ad6d-70a38a6264a6.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/021b5900-a6a0-4e6a-92fc-2ef6c38ac1a1.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/6af9794a-5e1f-492d-904c-aeebac5805db.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/727db111-9c8a-4e35-a359-c0eebc55e0e2.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/7d451d87-40e9-4b38-9cac-693a057005af.jpg',
                id:6,
            }
        ] 
    },
    {
        // Basic info
        id: 60,
        label: 'Hostel in Sapzurro',
        text: 'Sapzrro La Posada, Hostal and Camping',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Darién',
        distrito: 'Santa Fe',
        corregimiento: 'Agua Fría',
    
        // Ratings and pricing
        puntuacion: '4.5 (10)',
        total: '401',
        pornoche: '33',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/d05c5d5b-efe4-4741-80fc-38f729f64761.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/d05c5d5b-efe4-4741-80fc-38f729f64761.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/82fea5f6-2327-48cf-906c-454ebbe958a2.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/88aee8db-f564-48c6-874b-b17d1b684b05.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/d07875aa-7466-4d21-8cae-58ae47eb01cd.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/7e86736b-b3d0-4943-a58e-f07163d08eed.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/c7b0e0af-bf47-4aeb-938b-fff5c375c0f9.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 61,
        label: 'Nature lodge in Sapzurro',
        text: 'Hotel Paraiso Sapzurro Chocó. Family cabin',
        ncamas: "5",
        type: 'Normal',
    
        // Location info
        provincia: 'Darién',
        distrito: 'Chepigana',
        corregimiento: 'Tucutí',
    
        // Ratings and pricing
        puntuacion: '4.2 (8)',
        total: '401',
        pornoche: '55',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-28410075/original/833ff31b-ecd8-464e-9f09-cef5026db50b.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-28410075/original/833ff31b-ecd8-464e-9f09-cef5026db50b.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-28410075/original/effb8d43-4809-49a6-b10d-350850e89fe7.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-28410075/original/3a799856-7e78-4074-8886-be9c2b49efff.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-28410075/original/4eb3e961-0ad1-468a-bd24-c1436c376955.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-28410075/original/b16a84d5-065a-4000-9745-b8c197deaa63.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-28410075/original/e67ec9f3-cc9e-4b6b-83b4-662eafd98460.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 62,
        label: 'Place to stay in Guna Yala Comarca',
        text: 'Cabaña natural con vista al mar en una isla',
        ncamas: "20",
        type: 'Normal',
    
        // Location info
        provincia: 'Darién',
        distrito: 'Chepigana',
        corregimiento: 'Puerto Piña',
    
        // Ratings and pricing
        puntuacion: '4.7 (10)',
        total: '401',
        pornoche: '67',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/ce0e0088-6ec7-4729-8d08-dc4a6eb747af.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/ce0e0088-6ec7-4729-8d08-dc4a6eb747af.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-596233306957079069/original/0dafb697-57a0-4683-ae45-326cdfb73702.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/423c7638-2f1e-4d28-87ee-42b33fddb5ca.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/31cf12d4-7de9-42c0-8251-3ee1c6d229e9.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/66c70a97-9e81-4222-8c4f-a7aa9a2f70a7.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/33fa8aac-9c0a-4846-98e1-a79b3c6b67aa.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 63,
        label: 'Place to stay in Mamitupu',
        text: 'Habitaciones natural para desconectarse de todo.',
        ncamas: "24",
        type: 'Normal',
    
        // Location info
        provincia: 'Darién',
        distrito: 'Santa Fe',
        corregimiento: 'Cucunatí',
    
        // Ratings and pricing
        puntuacion: '4.6 (18)',
        total: '401',
        pornoche: '63',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/750116e0-0322-49a7-9bbc-baeca010301d.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/750116e0-0322-49a7-9bbc-baeca010301d.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2623c973-5970-4f11-89a8-3d4d8b215666.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-31418481/original/69667d1c-e690-49dc-a9f2-e8373d771ff4.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-31418481/original/495c7653-d9fa-4f36-84c0-3fdbd86e13e9.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-31418481/original/917917c3-04a1-47ba-9c0b-20b075b61088.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-31418481/original/1fbf4c2e-151a-401a-a85f-2f3a6eacd49b.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 64,
        label: 'Shared room in Panamá',
        text: 'Dubbag (baggagup)',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Darién',
        distrito: 'Pinogana',
        corregimiento: 'Paya',
    
        // Ratings and pricing
        puntuacion: '5.0 (9)',
        total: '401',
        pornoche: '112',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/f7aad751-db0e-4790-8ecb-24c9f600ba71.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/f7aad751-db0e-4790-8ecb-24c9f600ba71.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/cf0707c4-cc6c-4261-831f-72290ff9afe6.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/ca031296-65c0-45a1-bee6-408e4a9eb8d5.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/80ab1247-f48b-452b-bac7-78bfbfb4772b.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/bca10a8a-c8d3-4e4d-b430-16ac697c3b25.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/9522ddae-a46b-411b-893d-6b666336ce67.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 65,
        label: 'Cabin in Torio',
        text: '3 bd Cozy & Unique Jungle vibe with river access',
        ncamas: "4",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Chitré',
        corregimiento: 'Llano Bonito',
    
        // Ratings and pricing
        puntuacion: '4.9 (62)',
        total: '401',
        pornoche: '153',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/2ec6f81c-80b2-43ad-99b3-2a34104da78b.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/2ec6f81c-80b2-43ad-99b3-2a34104da78b.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/3d6a9591-191e-465c-90c3-e79a09ecfb0a.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/8f93f2e6-b952-495f-b1c6-f42d794f8c30.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/fc343c93-9869-4bb1-a109-8e7417c2dd5b.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/e64fee5b-1eed-4d75-8c92-79094f5a8cd3.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/975f4934-f914-4d8a-814f-eed6dc53fe9b.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 66,
        label: 'Home in Chitre',
        text: 'Cozy and centrally located house',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Las Minas',
        corregimiento: 'El Toro',
    
        // Ratings and pricing
        puntuacion: '4.7 (13)',
        total: '401',
        pornoche: '57',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-790738195106581844/original/bbf11b48-5ad3-49ea-9f6c-821b3a5d13ea.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-790738195106581844/original/bbf11b48-5ad3-49ea-9f6c-821b3a5d13ea.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-790738195106581844/original/c9622dcb-b084-4b0f-8914-f16570f46ef7.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-790738195106581844/original/d59fb2d1-60a5-41c5-94f9-4c129594c9c7.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/705da800-c904-4864-810b-603c7590d939.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e183697d-30b5-4096-ad18-17454876788c.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/8a8091e8-8235-448e-ba6c-f21663e3841d.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 67,
        label: 'Casita en Chitre',
        text: 'Grandpa s Casita Two Blocks From Downtown Chitré',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Los Pozos',
        corregimiento: 'La Arena',
    
        // Ratings and pricing
        puntuacion: '4.8 (11)',
        total: '401',
        pornoche: '37',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-701509555096135741/original/28952105-7638-4637-abc3-54d90bee7a27.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-701509555096135741/original/28952105-7638-4637-abc3-54d90bee7a27.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-701509555096135741/original/156bdc79-7c77-45e6-94ff-d880144b0082.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-701509555096135741/original/264dbe49-cfab-4348-96c3-f414ab5917c3.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-701509555096135741/original/245764a4-3a7b-40a5-ada3-b2bd5fedb734.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-701509555096135741/original/81919ca2-36b1-4d72-9b5d-472b44425f0c.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-701509555096135741/original/cf8bf39c-c3c6-4be6-9353-8f33d8590ea9.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 68,
        label: 'Chitre house',
        text: 'NINA HOUSE, spacious and cool house in Chitré.',
        ncamas: "5",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Ocú',
        corregimiento: 'Peñas Chatas',
    
        // Ratings and pricing
        puntuacion: '4.8 (37)',
        total: '401',
        pornoche: '115',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/bdd19a2b-811d-4327-aab5-0496ff776d97.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/bdd19a2b-811d-4327-aab5-0496ff776d97.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/efa89aea-de35-4787-9a0b-2a0c6b47043f.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/572b7193-d7e8-403d-9bc2-1b06a4d66d20.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/ffc97696-d75e-4a94-b4cf-f9781fa48698.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/1a65dab0-9f8a-4089-97a2-c78205270284.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/18830f79-64f4-4622-bf74-77f91c1a85b0.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 69,
        label: 'Room in Chitré',
        text: 'Accommodation',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Parita',
        corregimiento: 'Potuga',
    
        // Ratings and pricing
        puntuacion: '4.8 (29)',
        total: '401',
        pornoche: '31',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/ebe85567-823a-4093-a752-9fe5c07f0314.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/ebe85567-823a-4093-a752-9fe5c07f0314.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e10b3434-70e9-4990-849c-02ebe22d3a4a.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/df2251a9-e6c7-4e4f-889f-51726418e97f.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/6b4ac92a-e455-4731-bd60-2f7c7e7d617b.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/89a9fc42-2b03-4031-958e-bccf50c75317.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e10b3434-70e9-4990-849c-02ebe22d3a4a.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 70,
        label: 'Apartment in Chitre',
        text: 'Downtown Apartamento',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Pesé',
        corregimiento: 'El Pájaro',
    
        // Ratings and pricing
        puntuacion: '5.0 (3)',
        total: '401',
        pornoche: '58',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/5e0a003f-ebce-46e3-b07e-c7558ee52092.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/5e0a003f-ebce-46e3-b07e-c7558ee52092.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/fbc5e363-da7f-4624-aae3-156929354227.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/07642651-1273-45c3-b920-03eb74392a4d.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/93820346-7d09-44bf-a56f-57584b3015ac.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2b003c0e-d2d0-4781-a5cc-628eab35d8af.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/5b525c5e-84fe-49e6-8f57-f742ebdf3be6.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 71,
        label: 'Guesthouse in Torio',
        text: 'Torio guesthouse with panoramic view',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Santa María',
        corregimiento: 'El Limón',
    
        // Ratings and pricing
        puntuacion: '4.8 (91)',
        total: '401',
        pornoche: '77',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/6b04bc25-08bc-4bad-aacd-c142455c6f11.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/6b04bc25-08bc-4bad-aacd-c142455c6f11.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/dff67a6e-8bf5-40c0-acbe-8ee6a293bd71.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2970f72e-010a-4604-8101-687469b6aa7b.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/0d06dc8a-5a9e-4642-b935-90d18b43b3cd.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/488f2e20-314c-4266-8422-51d399e532d6.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/0586fff2-1f27-4f75-b4e0-09e664e4f49c.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 72,
        label: 'Home in La Villa de Los Santos',
        text: 'Amplia casa Villa de los Santos',
        ncamas: "5",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Las Minas',
        corregimiento: 'Leones',
    
        // Ratings and pricing
        puntuacion: '5.0 (6)',
        total: '401',
        pornoche: '66',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/783e7dac-7aeb-41ac-b289-ccf5daf05d91.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/783e7dac-7aeb-41ac-b289-ccf5daf05d91.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/8f5c561b-843e-49e2-a82c-eef7a5d0d455.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/2cc5fd98-df9a-44f6-b933-cbf6ca2e19ba.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/e7621ea5-bd9d-4b45-b541-7933587df5b2.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/d61bb4e7-191e-481a-a998-be79d794a0f7.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/cf027643-f8f9-4855-a43d-dd5b85b1fa02.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 73,
        label: 'Home in Chitré',
        text: 'Casa céntrica 2 cuartos 2 baño.',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Los Pozos',
        corregimiento: 'Los Cerritos',
    
        // Ratings and pricing
        puntuacion: '4.5 (15)',
        total: '401',
        pornoche: '55',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/38c269c4-3ced-41c3-bf76-d33e42ff9dce.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/38c269c4-3ced-41c3-bf76-d33e42ff9dce.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/c7f2cafb-45a6-4903-b6f8-6d5e78e15e8a.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/1e420d88-d0f2-4bec-9a50-b1b0016e6141.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/6af12ef3-1c1e-4716-82a6-0c6253f035f9.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2474eba1-0d9a-427e-8711-cc4304259d49.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/f1509a45-965d-4194-b602-18149122252d.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 74,
        label: 'Home in Chitré',
        text: 'Hermosa casa con espacios verdes',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Herrera',
        distrito: 'Parita',
        corregimiento: 'Llano de la Cruz',
    
        // Ratings and pricing
        puntuacion: '4.6 (12)',
        total: '401',
        pornoche: '124',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-907203609566682967/original/8f37613b-74c6-4b6e-ac1f-364f3e67585d.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-907203609566682967/original/8f37613b-74c6-4b6e-ac1f-364f3e67585d.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-907203609566682967/original/d884255a-d60b-4b1f-9009-736fc4115bc0.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-907203609566682967/original/6072c6a2-8ed9-4370-a91e-5f1092e31237.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-907203609566682967/original/24ef1199-cc77-4707-9ef4-f21f6b35d17d.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-907203609566682967/original/32106139-47b2-4800-939c-8579ae9a21ca.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-907203609566682967/original/89cf2f2e-d52a-4424-9f75-153a92b16903.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 75,
        label: 'Tiny home in Playa Los Destiladeros',
        text: 'Casita Brisa del Mar',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Guararé',
        corregimiento: 'Llano Abajo',
    
        // Ratings and pricing
        puntuacion: '4.6 (9)',
        total: '401',
        pornoche: '86',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/f1c7550d-34e9-4f91-8f9c-44743933bbcb.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/f1c7550d-34e9-4f91-8f9c-44743933bbcb.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/19d752f9-6971-4d34-82ac-9ee41155856d.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/a6eb96cc-5bcb-4166-acc9-f9c48be2d031.jpg',
                id:3,
            },
            {
                picture:'"https://a0.muscache.com/im/pictures/6519d52b-d8bd-4ea1-b1d4-296619a4ba53.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/80aedbba-e033-4a86-813e-1912681a7a88.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2ccc673c-6c3b-4a2c-96f8-213e1ecb1af7.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 76,
        label: 'Home in Las Tablas',
        text: 'Beautiful 2 Story Cozy Beach House (Fast WiFi)',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Las Tablas',
        corregimiento: 'La Palma',
    
        // Ratings and pricing
        puntuacion: '4.7 (132)',
        total: '401',
        pornoche: '69',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/5a7729b1-c298-424e-b272-09e590d21802.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/5a7729b1-c298-424e-b272-09e590d21802.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/7b77cd84-238e-4499-a13c-680c2dba46ea.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/543959e1-5e29-40f6-b63e-fd59a2d13dac.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/4cf2e6b1-cfe8-4f18-b26e-f93bda0147a1.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/215921c3-4b06-4a0d-83d9-47969696fcf5.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/ae67628c-17ae-41c5-86b4-51d2419ec82f.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 77,
        label: 'Earthen home in playa el arenal pedasi',
        text: 'Earth House Pedasi Experience',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Los Santos',
        corregimiento: 'Los Ángeles',
    
        // Ratings and pricing
        puntuacion: '4.9 (132)',
        total: '401',
        pornoche: '95',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/e35201a0-7301-4d00-89ba-b81e3c28ffd7.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/e35201a0-7301-4d00-89ba-b81e3c28ffd7.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/058c2b90-890f-45b6-9aba-b87ba69a6c6b.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/605951b4-9059-4150-b04a-abe7ec0ed545.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/184f131b-da77-4d89-873f-8b01e2b9a7d5.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/619e9d04-5d78-48e3-9f96-abb94787c09e.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/c8369802-5873-4db5-b920-6cd9dc4d05b7.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 78,
        label: 'Home in La Villa de Los Santos',
        text: 'Amplia casa Villa de los Santos',
        ncamas: "5",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Macaracas',
        corregimiento: 'El Cedro',
    
        // Ratings and pricing
        puntuacion: '5.0 (6)',
        total: '401',
        pornoche: '66',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/783e7dac-7aeb-41ac-b289-ccf5daf05d91.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/783e7dac-7aeb-41ac-b289-ccf5daf05d91.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/8f5c561b-843e-49e2-a82c-eef7a5d0d455.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/2cc5fd98-df9a-44f6-b933-cbf6ca2e19ba.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/e7621ea5-bd9d-4b45-b541-7933587df5b2.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/cc02505f-ec48-43ee-ab6e-80db6c004b10.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-834974248743490171/original/d61bb4e7-191e-481a-a998-be79d794a0f7.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 79,
        label: 'Room in El Cocal',
        text: 'Humble,peaceful bed and bath.',
        ncamas: "5",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Macaracas',
        corregimiento: 'El Cedro',
    
        // Ratings and pricing
        puntuacion: '4.6 (3)',
        total: '401',
        pornoche: '30',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-783198146776274531/original/b3ce8f4f-c9ca-4f07-80f5-0a0bc3464744.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783198146776274531/original/b3ce8f4f-c9ca-4f07-80f5-0a0bc3464744.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783198146776274531/original/78dd9376-ff20-436b-b13d-908f9472082e.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783198146776274531/original/6d41fba8-91db-43cc-b6d4-6967de9af24b.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783198146776274531/original/56da8a0a-023d-4401-8f44-58ccda0a1169.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/b66a8ae8-2437-4f49-8778-e5906a47b0b7.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-783198146776274531/original/0ceb458b-9aa6-421c-aaa5-6adeece546a6.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 80,
        label: 'Boutique hotel in Las Tablas Abajo',
        text: 'Blue Room for 2 - right on the beach!',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Pedasí',
        corregimiento: 'Mariabé',
    
        // Ratings and pricing
        puntuacion: '4.7 (47)',
        total: '401',
        pornoche: '65',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-48603741/original/897522ad-7919-4b78-b827-79db70fa6690.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-48603741/original/897522ad-7919-4b78-b827-79db70fa6690.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-48603741/original/7c7a6499-d840-4e3b-ba5e-aa5808fb6c95.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-48603741/original/080cca15-369f-4bd7-9b0b-5e2f1ff40298.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/7c4b668e-53af-460d-ad46-74d04db4ea76.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/a4111b6d-1867-4637-9510-98aabcdc3412.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/b72a96cc-e0ba-4081-a63c-9a1c6f423690.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 81,
        label: 'Home in Pedasí',
        text: 'Casa Samambaia, a modern tropical  home with pool',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Pocrí',
        corregimiento: 'Paritilla',
    
        // Ratings and pricing
        puntuacion: '4.9 (27)',
        total: '401',
        pornoche: '191',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-609464383617119474/original/94fb76c0-7a6c-4d3f-a10e-4871533b5207.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-609464383617119474/original/94fb76c0-7a6c-4d3f-a10e-4871533b5207.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-609464383617119474/original/f638f68e-222d-487f-9aba-ce179cd7ad59.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-609464383617119474/original/840bad7b-dfee-46c5-ad13-802820186249.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-609464383617119474/original/938117d4-c544-4f74-b79b-b285350155c6.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-609464383617119474/original/e16e70b6-6ebc-4d5b-b1e9-c97899a58fb9.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-609464383617119474/original/2d34d814-514a-46d7-9b29-998cb29b412c.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 82,
        label: 'Villa Estrella in Playa Venao',
        text: 'Villa Estrella in Playa Venao',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Pedasí',
        corregimiento: 'Oria Arriba',
    
        // Ratings and pricing
        puntuacion: '4.7 (53)',
        total: '401',
        pornoche: '167',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-32643696/original/5ef27855-7a2c-4b3b-a10c-6f0806f0ee6d.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-32643696/original/5ef27855-7a2c-4b3b-a10c-6f0806f0ee6d.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-32643696/original/d3b4fefc-6ec1-4fa0-bddb-760d160fe175.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/9aef34ec-3d3d-4c78-af1b-634b454892de.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/fcdb756b-3642-4642-bf0d-7eabc4080c3f.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e35d21c4-19b3-467a-a93f-68adffd6f9e7.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/3276a641-5678-42fb-8db5-d0444521a1b5.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 83,
        label: 'Room in La Villa de Los Santos',
        text: '**Your home in La Villa!**',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Los Santos',
        corregimiento: 'Villa Lourdes',
    
        // Ratings and pricing
        puntuacion: '4.8 (33)',
        total: '401',
        pornoche: '14',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/333a452e-1347-490a-aba0-eff4eab02dbe.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/333a452e-1347-490a-aba0-eff4eab02dbe.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/8bc43ce3-ad71-4904-aeb8-09a21271df5b.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/69491f5b-3c16-41ef-9e51-cc2ba53d6b3b.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/333a452e-1347-490a-aba0-eff4eab02dbe.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/8bc43ce3-ad71-4904-aeb8-09a21271df5b.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/69491f5b-3c16-41ef-9e51-cc2ba53d6b3b.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 84,
        label: 'Place to stay in Guánico Abajo',
        text: 'Ocean Surf House - Apt. 3',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Tonosí',
        corregimiento: 'Guánico',
    
        // Ratings and pricing
        puntuacion: '4.8 (5)',
        total: '401',
        pornoche: '63',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/41d453d4-bb0b-4dd1-81cc-a6a7f748576e.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/41d453d4-bb0b-4dd1-81cc-a6a7f748576e.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/cef3ac42-7069-4fd3-939a-32b8a10d09eb.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/a1efd8cc-a5c5-4bb0-a133-bb355927b86a.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/d009346c-d0af-4606-bb5f-ab447f2f9139.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e5e27662-74ec-4c8b-b92e-411be32bb636.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/724dc09b-cc7b-4d34-a150-63528aa2d518.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 85,
        label: 'Place to stay in Guánico Abajo',
        text: 'Ocean Surf House - Apt. 3',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Los Santos',
        distrito: 'Tonosí',
        corregimiento: 'Guánico',
    
        // Ratings and pricing
        puntuacion: '4.8 (5)',
        total: '401',
        pornoche: '63',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/41d453d4-bb0b-4dd1-81cc-a6a7f748576e.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/41d453d4-bb0b-4dd1-81cc-a6a7f748576e.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/cef3ac42-7069-4fd3-939a-32b8a10d09eb.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/a1efd8cc-a5c5-4bb0-a133-bb355927b86a.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-807313981409589298/original/d009346c-d0af-4606-bb5f-ab447f2f9139.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e5e27662-74ec-4c8b-b92e-411be32bb636.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/724dc09b-cc7b-4d34-a150-63528aa2d518.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 86,
        label: 'Apartment in Panamá',
        text: 'Awesome Apartment With City View Balcony',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Panamá',
        distrito: 'Panamá',
        corregimiento: 'Bella Vista',
    
        // Ratings and pricing
        puntuacion: '4.8 (8)',
        total: '401',
        pornoche: '78',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/prohost-api/Hosting-690417091858065448/original/e967e178-14a0-47f3-a61a-1c226dec80d6.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-690417091858065448/original/e967e178-14a0-47f3-a61a-1c226dec80d6.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-690417091858065448/original/d7264f9a-920c-43c3-a998-e5544ef0eb43.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-690417091858065448/original/f73c1183-16ad-4eb5-8c2c-3cda552ebd52.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-690417091858065448/original/52c1014e-afb3-453e-92ab-503c719b4a95.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-690417091858065448/original/d560ba67-dd4f-406c-a089-0267294beda7.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/prohost-api/Hosting-690417091858065448/original/303a68db-c93a-456f-938b-d4774809c3ca.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 87,
        label: 'Place to stay in Panamá',
        text: 'Private room in panama',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Panamá',
        distrito: 'Panamá',
        corregimiento: 'San Francisco',
    
        // Ratings and pricing
        puntuacion: '4.9 (21)',
        total: '401',
        pornoche: '31',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-729011719152889861/original/bf346182-337c-4f43-8735-4fa50d01ad03.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-729011719152889861/original/bf346182-337c-4f43-8735-4fa50d01ad03.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-729011719152889861/original/0ee965a1-cd44-469b-ad2f-0e57660c5bad.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-729011719152889861/original/6593da40-a5f3-46ee-a620-8ebcb960e0b8.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-729011719152889861/original/de8e4d72-dd62-475d-b190-e7935e11cbb8.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-729011719152889861/original/92c48c16-968f-4248-9b7f-b266a5b7eb59.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-729011719152889861/original/1bac6230-f05e-49e3-ac76-d40b944d2aed.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 88,
        label: 'Apartment in Panamá',
        text: 'Great location • New • Ocean Front',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Panamá',
        distrito: 'Panamá',
        corregimiento: 'Parque Lefevre',
    
        // Ratings and pricing
        puntuacion: '4.6 (2)',
        total: '401',
        pornoche: '101',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-916438617813279487/original/8391b3b0-a224-40e3-94b3-adbe6d9393ed.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-916438617813279487/original/8391b3b0-a224-40e3-94b3-adbe6d9393ed.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-916438617813279487/original/f0c75ff4-cf92-4237-a734-8b52cce972d0.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-916438617813279487/original/f8fdb0be-4837-4b33-ab96-2ec93a92be3d.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-916438617813279487/original/6a936b05-1a14-42e4-9ba7-3f3c0db33ea0.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-916438617813279487/original/2cfb8344-c314-45f9-8421-4c9c670df4c2.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-916438617813279487/original/5a04174f-7f39-4b9a-9b1e-83b6f1d3287c.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 89,
        label: 'Cabin in Chame',
        text: 'Las Nubes En Chica',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Panamá Oeste',
        distrito: 'Chame',
        corregimiento: 'Chame',
    
        // Ratings and pricing
        puntuacion: '5.0 (4)',
        total: '401',
        pornoche: '78',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-896416001534582644/original/fa49eab8-96bd-4ab5-82cb-80c6b733607e.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-896416001534582644/original/fa49eab8-96bd-4ab5-82cb-80c6b733607e.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-896416001534582644/original/17f01d90-774e-48e9-9549-03a60ddb1ad0.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-896416001534582644/original/3ebec532-7fb4-432c-a12d-6819355aebf4.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-896416001534582644/original/2d26dc82-bc5e-4331-a731-d30ee4e028e2.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-896416001534582644/original/02542059-fad9-4045-8740-b2905c05d084.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-896416001534582644/original/0323f02e-a09a-431d-9ebc-a10e66479a62.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 90,
        label: 'Condo in Coronado',
        text: 'Awesome Rates, Beautiful Ocean Views',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Panamá Oeste',
        distrito: 'San Carlos',
        corregimiento: 'San Carlos',
    
        // Ratings and pricing
        puntuacion: '5.0 (8)',
        total: '401',
        pornoche: '89',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-16688534/original/0df491aa-11ea-46cc-b758-2222c894fd7c.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-16688534/original/0df491aa-11ea-46cc-b758-2222c894fd7c.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-16688534/original/6f1e8372-3626-4b95-a9c8-5d4d7475b8b3.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-16688534/original/dc67dc99-b59a-4dd6-8bce-bc9515fbd874.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-16688534/original/3e05103b-24f7-45be-8efd-b3c9a18df946.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-16688534/original/16654299-d651-4438-a8ae-820b496b70dd.png',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-16688534/original/981c0ca2-b62e-4be4-886f-765a8a2075cc.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 91,
        label: 'Guest suite in Gamboa',
        text: 'Beautiful annex in the middle of Gamboa Rainforest',
        ncamas: "4",
        type: 'Normal',
    
        // Location info
        provincia: 'Colón',
        distrito: 'Colón',
        corregimiento: 'Nuevo Chagres',
    
        // Ratings and pricing
        puntuacion: '4.9 (37)',
        total: '401',
        pornoche: '57',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-713729819766409836/original/e8499341-0fa7-4af2-9702-b1b08a9a84a1.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713729819766409836/original/e8499341-0fa7-4af2-9702-b1b08a9a84a1.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713729819766409836/original/460d7238-c96c-41c0-982f-68511d9cdefa.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713729819766409836/original/1b927d72-b990-418a-b3da-db804a577150.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713729819766409836/original/fbc3c472-4ab4-4b4e-8135-30c785ede135.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713729819766409836/original/34d1dd44-f49c-4f80-9b22-0b1ebf9ae423.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-713729819766409836/original/2795fffc-5ec0-4fef-9833-f89139364198.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 92,
        label: 'Apartment in Nueva Gorgona',
        text: '*Top Condo* Luxury Beach front @Gorgona Bahia',
        ncamas: "3",
        type: 'Normal',
    
        // Location info
        provincia: 'Panamá Oeste',
        distrito: 'Chame',
        corregimiento: 'Nueva Gorgona',
    
        // Ratings and pricing
        puntuacion: '4.8 (30)',
        total: '401',
        pornoche: '111',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-788307483843168131/original/40118ff4-6c81-4cfd-a283-4765d0023ff1.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-788307483843168131/original/40118ff4-6c81-4cfd-a283-4765d0023ff1.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-788307483843168131/original/2756c5a1-9896-43fa-9651-79eabd30269d.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-788307483843168131/original/6972f3c4-22b9-489e-8723-dfda521ee0e4.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-788307483843168131/original/96d7ff37-16b6-4575-85a6-790c403d2b61.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-788307483843168131/original/0b09a6e6-7ed1-4fc7-9022-48fe3429b13b.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-788307483843168131/original/d3a95404-ef24-489c-b4c6-060d9dfbf515.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 93,
        label: 'Condo in San Carlos',
        text: 'Ocean Breeze at Playa Corona',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Panamá Oeste',
        distrito: 'San Carlos',
        corregimiento: 'San José',
    
        // Ratings and pricing
        puntuacion: '4.9 (14)',
        total: '401',
        pornoche: '63',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/81ef34c0-fc5e-4fd8-aa9e-dd8aea9c3502.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/81ef34c0-fc5e-4fd8-aa9e-dd8aea9c3502.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/fd41abb0-7c23-418f-89ad-b67d559baedf.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/61317e6a-b4a0-443f-abf3-2f2ecd89a0e3.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/e40151c6-01ce-453b-92e1-c7d17cf3457d.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/fbdbcb5b-bce7-43b7-8d16-838017aec5ee.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/523822fe-d7ea-44d2-8e44-6b1900864ba8.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 94,
        label: 'Treehouse in Panamá Oeste',
        text: 'Tarzan Treehouse ~Delightful 1 bedroom treehouse',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Panamá Oeste',
        distrito: 'Capira',
        corregimiento: 'Santa Rosa',
    
        // Ratings and pricing
        puntuacion: '4.9 (77)',
        total: '401',
        pornoche: '109',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/5e2e8b23-4b5b-444c-af14-3834c361b530.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/5e2e8b23-4b5b-444c-af14-3834c361b530.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/581b7e13-eb1d-4416-85d4-861efea6d787.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-626904904088618827/original/d50a1713-6b55-4b5b-adef-73c123887366.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/dd00305b-dbf3-4012-a2a4-959e9389836c.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/073ef6c9-bbec-4ee3-b0fd-8130733420d5.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/70fb87c3-ed8f-4e5a-9365-4dd2089922e0.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 95,
        label: 'Cabin in Torio',
        text: '3 bd Cozy & Unique Jungle vibe with river access',
        ncamas: "4",
        type: 'Normal',
    
        // Location info
        provincia: 'Veraguas',
        distrito: 'La Mesa',
        corregimiento: 'Los Milagros',
    
        // Ratings and pricing
        puntuacion: '4.9 (62)',
        total: '401',
        pornoche: '133',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/2ec6f81c-80b2-43ad-99b3-2a34104da78b.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/2ec6f81c-80b2-43ad-99b3-2a34104da78b.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/3d6a9591-191e-465c-90c3-e79a09ecfb0a.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/8f93f2e6-b952-495f-b1c6-f42d794f8c30.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/fc343c93-9869-4bb1-a109-8e7417c2dd5b.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-53729946/original/e64fee5b-1eed-4d75-8c92-79094f5a8cd3.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/975f4934-f914-4d8a-814f-eed6dc53fe9b.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 96,
        label: 'Farm stay in Santa Fe',
        text: 'Santa Fe villa with a espectacular view',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Veraguas',
        distrito: 'Santa Fe',
        corregimiento: 'El Cuay',
    
        // Ratings and pricing
        puntuacion: '4.9 (22)',
        total: '401',
        pornoche: '70',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/ef55594b-6439-4e76-8b3d-96568c2cd021.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/ef55594b-6439-4e76-8b3d-96568c2cd021.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/b76a88fc-f4f1-45d7-81ed-a9398a2dd924.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/8bdcc9fa-7402-480b-98b5-7ffba865d04d.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/5b649d13-eb03-4012-8523-25ba9764849e.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/f441e560-e64f-4299-a754-38af73784c14.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/6c371acf-3e27-4332-9d56-47d325980f38.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 97,
        label: 'Bungalow in Santa Catalina',
        text: 'OCTOPUS studio',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Veraguas',
        distrito: 'Santiago',
        corregimiento: 'La Colorada',
    
        // Ratings and pricing
        puntuacion: '4.8 (56)',
        total: '401',
        pornoche: '62',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-52072096/original/b9ccedcb-ba04-4340-876e-935d1dc6ccee.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-52072096/original/b9ccedcb-ba04-4340-876e-935d1dc6ccee.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-52072096/original/23f1f672-721c-485b-9193-92748d7659d1.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-52072096/original/cb5caabc-bdae-4efa-88c3-265939790715.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-52072096/original/8b946e1b-470a-4244-bd8e-2ed10b6a7568.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-52072096/original/5339c7f1-b82a-4616-b70a-3cb91c36b4cf.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-52072096/original/713cf690-e3b1-41b2-ac10-4e77d19c08f1.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 98,
        label: 'Guesthouse in Torio',
        text: 'Torio guesthouse with panoramic view',
        ncamas: "2",
        type: 'Normal',
    
        // Location info
        provincia: 'Veraguas',
        distrito: 'Montijo',
        corregimiento: 'Leones',
    
        // Ratings and pricing
        puntuacion: '4.8 (91)',
        total: '401',
        pornoche: '80',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/6b04bc25-08bc-4bad-aacd-c142455c6f11.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/6b04bc25-08bc-4bad-aacd-c142455c6f11.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/dff67a6e-8bf5-40c0-acbe-8ee6a293bd71.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/2970f72e-010a-4604-8101-687469b6aa7b.jpg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/0d06dc8a-5a9e-4642-b935-90d18b43b3cd.jpg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/0f44d1cb-b0b3-4747-848a-aec8a1aa77b6.jpg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/488f2e20-314c-4266-8422-51d399e532d6.jpg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 99,
        label: 'Home in Torio',
        text: 'Comfortable, modern house - breathtaking sea view',
        ncamas: "1",
        type: 'Normal',
    
        // Location info
        provincia: 'Veraguas',
        distrito: 'Calobre',
        corregimiento: 'Chitra',
    
        // Ratings and pricing
        puntuacion: '5.0 (23)',
        total: '401',
        pornoche: '85',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/da248548-6fa8-425f-b7e6-0bc0c4a92ff7.jpg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/da248548-6fa8-425f-b7e6-0bc0c4a92ff7.jpg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/f21d27ea-3a5b-4793-80fb-029dff30ccdb.jpg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-7650003/original/87c2b081-e49c-479c-a1f8-6c4938963945.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-7650003/original/a1fa34ba-585c-4a8c-9990-137a0f8ddcf4.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-7650003/original/92113bd5-a586-4585-ac30-354a66107fbe.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-7650003/original/92113bd5-a586-4585-ac30-354a66107fbe.jpeg',
                id:6,
            }
            ] 
    },
    {
        // Basic info
        id: 100,
        label: 'Villa in Santa Catalina',
        text: 'Infinity Pool Boutique Villa on The Point Break',
        ncamas: "13",
        type: 'Normal',
    
        // Location info
        provincia: 'Veraguas',
        distrito: 'Soná',
        corregimiento: 'Río Grande',
    
        // Ratings and pricing
        puntuacion: '4.9 (14)',
        total: '401',
        pornoche: '295',
    
        // Image URLs
        img1: 'https://a0.muscache.com/im/pictures/miso/Hosting-642628659942312302/original/b939ec75-b99a-4bc8-a63b-dbb691f71be8.jpeg',
        img2: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
        img3: 'https://a0.muscache.com/im/pictures/738e8c53-f1d4-4813-b8a1-63f1a6e2c582.jpg',
    
        // Image array
        img:[
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-642628659942312302/original/b939ec75-b99a-4bc8-a63b-dbb691f71be8.jpeg',
                id:1,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-642628659942312302/original/eeb1b0e5-f043-4c2a-9881-47c1cfa352fe.jpeg',
                id:2,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-642628659942312302/original/9d10e916-5211-48f7-b912-e89e828a4b2f.jpeg',
                id:3,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-642628659942312302/original/617b58f6-48d2-4d94-a974-f1c252eed942.jpeg',
                id:4,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-642628659942312302/original/8fa1819e-2093-4fd0-9a50-ab25addd31b8.jpeg',
                id:5,
            },
            {
                picture:'https://a0.muscache.com/im/pictures/miso/Hosting-642628659942312302/original/f4fbf08b-8459-4947-84c8-0196f211b6ea.jpeg',
                id:6,
            }
            ] 
    },
] 
export { dataPropiedadesUpdate }