import React from 'react'

const Paises = () => {
    
    return (  

        <>
                
            
                {/* <option value="93AF">Afganistán (+93)</option>
                <option value="355AL">Albania (+355)</option>
                <option value="49DE">Alemania (+49)</option>
                <option value="376AD">Andorra (+376)</option>
                <option value="244AO">Angola (+244)</option>
                <option value="1AI">Anguila (+1)</option>
                <option value="1AG">Antigua y Barbuda (+1)</option>
                <option value="966SA">Arabia Saudí (+966)</option>
                <option value="213DZ">Argelia (+213)</option>
                <option value="54AR">Argentina (+54)</option>
                <option value="374AM">Armenia (+374)</option>
                <option value="297AW">Aruba (+297)</option>
                <option value="61AU">Australia (+61)</option>
                <option value="43AT">Austria (+43)</option>
                <option value="994AZ">Azerbaiyán (+994)</option>
                <option value="1BS">Bahamas (+1)</option>
                <option value="880BD">Bangladés (+880)</option>
                <option value="1BB">Barbados (+1)</option>
                <option value="973BH">Baréin (+973)</option>
                <option value="32BE">Bélgica (+32)</option>
                <option value="501BZ">Belice (+501)</option>
                <option value="229BJ">Benín (+229)</option>
                <option value="1BM">Bermudas (+1)</option>
                <option value="375BY">Bielorrusia (+375)</option>
                <option value="591BO">Bolivia (+591)</option>
                <option value="599BQ">Bonaire, Sint Eustatius i Saba (+599)</option>
                <option value="387BA">Bosnia-Herzegovina (+387)</option>
                <option value="267BW">Botsuana (+267)</option>
                <option value="55BR">Brasil (+55)</option>
                <option value="673BN">Brunéi (+673)</option>
                <option value="359BG">Bulgaria (+359)</option>
                <option value="226BF">Burkina Faso (+226)</option>
                <option value="257BI">Burundi (+257)</option>
                <option value="975BT">Bután (+975)</option>
                <option value="238CV">Cabo Verde (+238)</option>
                <option value="855KH">Camboya (+855)</option>
                <option value="237CM">Camerún (+237)</option>
                <option value="1CA">Canadá (+1)</option>
                <option value="974QA">Catar (+974)</option>
                <option value="235TD">Chad (+235)</option>
                <option value="420CZ">Chequia (+420)</option>
                <option value="56CL">Chile (+56)</option>
                <option value="86CN">China (+86)</option>
                <option value="357CY">Chipre (+357)</option>
                <option value="379VA">Ciudad del Vaticano (+379)</option>
                <option value="57CO">Colombia (+57)</option>
                <option value="269KM">Comoras (+269)</option>
                <option value="242CG">Congo (+242)</option>
                <option value="82KR">Corea del Sur (+82)</option>
                <option value="225CI">Costa de Marfil (+225)</option>
                <option value="506CR">Costa Rica (+506)</option>
                <option value="385HR">Croacia (+385)</option>
                <option value="53CU">Cuba (+53)</option>
                <option value="599CW">Curazao (+599)</option>
                <option value="243CD">República Democrática del Congo (+243)</option>
                <option value="45DK">Dinamarca (+45)</option>
                <option value="1DM">Dominica (+1)</option>
                <option value="593EC">Ecuador (+593)</option>
                <option value="20EG">Egipto (+20)</option>
                <option value="503SV">El Salvador (+503)</option>
                <option value="971AE">Emiratos Árabes Unidos (+971)</option>
                <option value="291ER">Eritrea (+291)</option>
                <option value="421SK">Eslovaquia (+421)</option>
                <option value="386SI">Eslovenia (+386)</option>
                <option value="34ES">España (+34)</option>
                <option value="1US">Estados Unidos (+1)</option>
                <option value="372EE">Estonia (+372)</option>
                <option value="268SZ">Esuatini (+268)</option>
                <option value="251ET">Etiopía (+251)</option>
                <option value="63PH">Filipinas (+63)</option>
                <option value="358FI">Finlandia (+358)</option>
                <option value="679FJ">Fiyi (+679)</option>
                <option value="33FR">Francia (+33)</option>
                <option value="241GA">Gabón (+241)</option>
                <option value="220GM">Gambia (+220)</option>
                <option value="995GE">Georgia (+995)</option>
                <option value="233GH">Ghana (+233)</option>
                <option value="350GI">Gibraltar (+350)</option>
                <option value="1GD">Granada (+1)</option>
                <option value="30GR">Grecia (+30)</option>
                <option value="299GL">Groenlandia (+299)</option>
                <option value="590GP">Guadalupe (+590)</option>
                <option value="1GU">Guam (+1)</option>
                <option value="502GT">Guatemala (+502)</option>
                <option value="594GF">Guayana Francesa (+594)</option>
                <option value="44GG">Guernesey (+44)</option>
                <option value="224GN">Guinea (+224)</option>
                <option value="240GQ">Guinea Ecuatorial (+240)</option>
                <option value="245GW">Guinea-Bisáu (+245)</option>
                <option value="592GY">Guyana (+592)</option>
                <option value="509HT">Haití (+509)</option>
                <option value="504HN">Honduras (+504)</option>
                <option value="852HK">Hong Kong (+852)</option>
                <option value="36HU">Hungría (+36)</option>
                <option value="91IN">India (+91)</option>
                <option value="62ID">Indonesia (+62)</option>
                <option value="964IQ">Irak (+964)</option>
                <option value="353IE">Irlanda (+353)</option>
                <option value="44IM">Isla de Man (+44)</option>
                <option value="61CX">Isla de Navidad (+61)</option>
                <option value="672NF">Isla Norfolk (+672)</option>
                <option value="354IS">Islandia (+354)</option>
                <option value="358AX">Islas Åland (+358)</option>
                <option value="1KY">Islas Caimán (+1)</option>
                <option value="61CC">Islas Cocos (+61)</option>
                <option value="682CK">Islas Cook (+682)</option>
                <option value="298FO">Islas Feroe (+298)</option>
                <option value="500GS">Islas Georgia del Sur y Sandwich del Sur (+500)</option>
                <option value="500FK">Islas Malvinas (Falkland) (+500)</option>
                <option value="1MP">Islas Marianas del Norte (+1)</option>
                <option value="692MH">Islas Marshall (+692)</option>
                <option value="64PN">Islas Pitcairn (+64)</option>
                <option value="677SB">Islas Salomón (+677)</option>
                <option value="1TC">Islas Turcas y Caicos (+1)</option>
                <option value="1VG">Islas Vírgenes Británicas (+1)</option>
                <option value="1VI">Islas Vírgenes de EE. UU. (+1)</option>
                <option value="972IL">Israel (+972)</option>
                <option value="39IT">Italia (+39)</option>
                <option value="1JM">Jamaica (+1)</option>
                <option value="81JP">Japón (+81)</option>
                <option value="44JE">Jersey (+44)</option>
                <option value="962JO">Jordania (+962)</option>
                <option value="7KZ">Kazajistán (+7)</option>
                <option value="254KE">Kenia (+254)</option>
                <option value="996KG">Kirguistán (+996)</option>
                <option value="686KI">Kiribati (+686)</option>
                <option value="383XK">Kosovo (+383)</option>
                <option value="965KW">Kuwait (+965)</option>
                <option value="856LA">Laos (+856)</option>
                <option value="266LS">Lesoto (+266)</option>
                <option value="371LV">Letonia (+371)</option>
                <option value="961LB">Líbano (+961)</option>
                <option value="231LR">Liberia (+231)</option>
                <option value="218LY">Libia (+218)</option>
                <option value="423LI">Liechtenstein (+423)</option>
                <option value="370LT">Lituania (+370)</option>
                <option value="352LU">Luxemburgo (+352)</option>
                <option value="853MO">Macao (+853)</option>
                <option value="389MK">Macedonia del Norte (+389)</option>
                <option value="261MG">Madagascar (+261)</option>
                <option value="60MY">Malasia (+60)</option>
                <option value="265MW">Malaui (+265)</option>
                <option value="960MV">Maldivas (+960)</option>
                <option value="223ML">Mali (+223)</option>
                <option value="356MT">Malta (+356)</option>
                <option value="212MA">Marruecos (+212)</option>
                <option value="596MQ">Martinica (+596)</option>
                <option value="230MU">Mauricio (+230)</option>
                <option value="222MR">Mauritania (+222)</option>
                <option value="262YT">Mayotte (+262)</option>
                <option value="52MX">México (+52)</option>
                <option value="691FM">Micronesia (+691)</option>
                <option value="373MD">Moldavia (+373)</option>
                <option value="377MC">Mónaco (+377)</option>
                <option value="976MN">Mongolia (+976)</option>
                <option value="382ME">Montenegro (+382)</option>
                <option value="1MS">Montserrat (+1)</option>
                <option value="258MZ">Mozambique (+258)</option>
                <option value="95MM">Myanmar (+95)</option>
                <option value="264NA">Namibia (+264)</option>
                <option value="674NR">Nauru (+674)</option>
                <option value="977NP">Nepal (+977)</option>
                <option value="505NI">Nicaragua (+505)</option>
                <option value="227NE">Níger (+227)</option>
                <option value="234NG">Nigeria (+234)</option>
                <option value="683NU">Niue (+683)</option>
                <option value="47NO">Noruega (+47)</option>
                <option value="687NC">Nueva Caledonia (+687)</option>
                <option value="64NZ">Nueva Zelanda (+64)</option>
                <option value="968OM">Omán (+968)</option>
                <option value="31NL">Países Bajos (+31)</option>
                <option value="92PK">Pakistán (+92)</option>
                <option value="680PW">Palaos (+680)</option>
                <option value="507PA">Panamá (+507)</option>
                <option value="675PG">Papúa Nueva Guinea (+675)</option>
                <option value="595PY">Paraguay (+595)</option>
                <option value="51PE">Perú (+51)</option>
                <option value="689PF">Polinesia Francesa (+689)</option>
                <option value="48PL">Polonia (+48)</option>
                <option value="351PT">Portugal (+351)</option>
                <option value="1PR">Puerto Rico (+1)</option>
                <option value="44GB">Reino Unido (+44)</option>
                <option value="236CF">República Centroafricana (+236)</option>
                <option value="1DO">República Dominicana (+1)</option>
                <option value="262RE">Reunión (+262)</option>
                <option value="250RW">Ruanda (+250)</option>
                <option value="40RO">Rumanía (+40)</option>
                <option value="7RU">Rusia (+7)</option>
                <option value="212EH">Sáhara Occidental (+212)</option>
                <option value="685WS">Samoa (+685)</option>
                <option value="1AS">Samoa Americana (+1)</option>
                <option value="590BL">San Bartolomé (+590)</option>
                <option value="1KN">San Cristóbal y Nieves (+1)</option>
                <option value="378SM">San Marino (+378)</option>
                <option value="590MF">San Martín (+590)</option>
                <option value="508PM">San Pedro y Miquelón (+508)</option>
                <option value="1VC">San Vicente y las Granadinas (+1)</option>
                <option value="290SH">Santa Elena (+290)</option>
                <option value="1LC">Santa Lucía (+1)</option>
                <option value="239ST">Santo Tomé y Príncipe (+239)</option>
                <option value="221SN">Senegal (+221)</option>
                <option value="381RS">Serbia (+381)</option>
                <option value="248SC">Seychelles (+248)</option>
                <option value="232SL">Sierra Leona (+232)</option>
                <option value="65SG">Singapur (+65)</option>
                <option value="1SX">Sint Maarten (+1)</option>
                <option value="252SO">Somalia (+252)</option>
                <option value="94LK">Sri Lanka (+94)</option>
                <option value="27ZA">Sudáfrica (+27)</option>
                <option value="249SD">Sudán (+249)</option>
                <option value="211SS">Sudán del Sur (+211)</option>
                <option value="46SE">Suecia (+46)</option>
                <option value="41CH">Suiza (+41)</option>
                <option value="597SR">Surinam (+597)</option>
                <option value="47SJ">Svalbard y Jan Mayen (+47)</option>
                <option value="66TH">Tailandia (+66)</option>
                <option value="886TW">Taiwán (+886)</option>
                <option value="255TZ">Tanzania (+255)</option>
                <option value="992TJ">Tayikistán (+992)</option>
                <option value="246IO">Territorio Británico del Océano Índico (+246)</option>
                <option value="970PS">Territorios Palestinos (+970)</option>
                <option value="670TL">Timor-Leste (+670)</option>
                <option value="228TG">Togo (+228)</option>
                <option value="690TK">Tokelau (+690)</option>
                <option value="676TO">Tonga (+676)</option>
                <option value="1TT">Trinidad y Tobago (+1)</option>
                <option value="216TN">Túnez (+216)</option>
                <option value="993TM">Turkmenistán (+993)</option>
                <option value="90TR">Turquía (+90)</option>
                <option value="688TV">Tuvalu (+688)</option>
                <option value="380UA">Ucrania (+380)</option>
                <option value="256UG">Uganda (+256)</option>
                <option value="598UY">Uruguay (+598)</option>
                <option value="998UZ">Uzbekistán (+998)</option>
                <option value="678VU">Vanuatu (+678)</option>
                <option value="58VE">Venezuela (+58)</option>
                <option value="84VN">Vietnam (+84)</option>
                <option value="681WF">Wallis y Futuna (+681)</option>
                <option value="967YE">Yemen (+967)</option>
                <option value="253DJ">Yibuti (+253)</option>
                <option value="260ZM">Zambia (+260)</option>
                <option value="263ZW">Zimbabue (+263)</option> */}
            
<option value="Afganistán (+93)">Afganistán (+93)</option>
<option value="Albania (+355)">Albania (+355)</option>
<option value="Alemania (+49)">Alemania (+49)</option>
<option value="Andorra (+376)">Andorra (+376)</option>
<option value="Angola (+244)">Angola (+244)</option>
<option value="Anguila (+1)">Anguila (+1)</option>
<option value="Antigua y Barbuda (+1)">Antigua y Barbuda (+1)</option>
<option value="Arabia Saudí (+966)">Arabia Saudí (+966)</option>
<option value="Argelia (+213)">Argelia (+213)</option>
<option value="Argentina (+54)">Argentina (+54)</option>
<option value="Armenia (+374)">Armenia (+374)</option>
<option value="Aruba (+297)">Aruba (+297)</option>
<option value="Australia (+61)">Australia (+61)</option>
<option value="Austria (+43)">Austria (+43)</option>
<option value="Azerbaiyán (+994)">Azerbaiyán (+994)</option>
<option value="Bahamas (+1)">Bahamas (+1)</option>
<option value="Bangladés (+880)">Bangladés (+880)</option>
<option value="Barbados (+1)">Barbados (+1)</option>
<option value="Baréin (+973)">Baréin (+973)</option>
<option value="Bélgica (+32)">Bélgica (+32)</option>
<option value="Belice (+501)">Belice (+501)</option>
<option value="Benín (+229)">Benín (+229)</option>
<option value="Bermudas (+1)">Bermudas (+1)</option>
<option value="Bielorrusia (+375)">Bielorrusia (+375)</option>
<option value="Bolivia (+591)">Bolivia (+591)</option>
<option value="Bonaire, Sint Eustatius i Saba (+599)">Bonaire, Sint Eustatius i Saba (+599)</option>
<option value="Bosnia-Herzegovina (+387)">Bosnia-Herzegovina (+387)</option>
<option value="Botsuana (+267)">Botsuana (+267)</option>
<option value="Brasil (+55)">Brasil (+55)</option>
<option value="Brunéi (+673)">Brunéi (+673)</option>
<option value="Bulgaria (+359)">Bulgaria (+359)</option>
<option value="Burkina Faso (+226)">Burkina Faso (+226)</option>
<option value="Burundi (+257)">Burundi (+257)</option>
<option value="Bután (+975)">Bután (+975)</option>
<option value="Cabo Verde (+238)">Cabo Verde (+238)</option>
<option value="Camboya (+855)">Camboya (+855)</option>
<option value="Camerún (+237)">Camerún (+237)</option>
<option value="Canadá (+1)">Canadá (+1)</option>
<option value="Catar (+974)">Catar (+974)</option>
<option value="Chad (+235)">Chad (+235)</option>
<option value="Chequia (+420)">Chequia (+420)</option>
<option value="Chile (+56)">Chile (+56)</option>
<option value="China (+86)">China (+86)</option>
<option value="Chipre (+357)">Chipre (+357)</option>
<option value="Ciudad del Vaticano (+379)">Ciudad del Vaticano (+379)</option>
<option value="Colombia (+57)">Colombia (+57)</option>
<option value="Comoras (+269)">Comoras (+269)</option>
<option value="Congo (+242)">Congo (+242)</option>
<option value="Corea del Norte (+850)">Corea del Norte (+850)</option>
<option value="Corea del Sur (+82)">Corea del Sur (+82)</option>
<option value="Costa de Marfil (+225)">Costa de Marfil (+225)</option>
<option value="Costa Rica (+506)">Costa Rica (+506)</option>
<option value="Croacia (+385)">Croacia (+385)</option>
<option value="Cuba (+53)">Cuba (+53)</option>
<option value="Curazao (+599)">Curazao (+599)</option>
<option value="Dinamarca (+45)">Dinamarca (+45)</option>
<option value="Dominica (+1)">Dominica (+1)</option>
<option value="Ecuador (+593)">Ecuador (+593)</option>
<option value="Egipto (+20)">Egipto (+20)</option>
<option value="El Salvador (+503)">El Salvador (+503)</option>
<option value="Emiratos Árabes Unidos (+971)">Emiratos Árabes Unidos (+971)</option>
<option value="Eritrea (+291)">Eritrea (+291)</option>
<option value="Eslovaquia (+421)">Eslovaquia (+421)</option>
<option value="Eslovenia (+386)">Eslovenia (+386)</option>
<option value="España (+34)">España (+34)</option>
<option value="Estados Unidos (+1)">Estados Unidos (+1)</option>
<option value="Estonia (+372)">Estonia (+372)</option>
<option value="Eswatini (+268)">Eswatini (+268)</option>
<option value="Etiopía (+251)">Etiopía (+251)</option>
<option value="Filipinas (+63)">Filipinas (+63)</option>
<option value="Finlandia (+358)">Finlandia (+358)</option>
<option value="Fiyi (+679)">Fiyi (+679)</option>
<option value="Francia (+33)">Francia (+33)</option>
<option value="Gabón (+241)">Gabón (+241)</option>
<option value="Gambia (+220)">Gambia (+220)</option>
<option value="Georgia (+995)">Georgia (+995)</option>
<option value="Ghana (+233)">Ghana (+233)</option>
<option value="Gibraltar (+350)">Gibraltar (+350)</option>
<option value="Granada (+1)">Granada (+1)</option>
<option value="Grecia (+30)">Grecia (+30)</option>
<option value="Groenlandia (+299)">Groenlandia (+299)</option>
<option value="Guadalupe (+590)">Guadalupe (+590)</option>
<option value="Guam (+1)">Guam (+1)</option>
<option value="Guatemala (+502)">Guatemala (+502)</option>
<option value="Guayana Francesa (+594)">Guayana Francesa (+594)</option>
<option value="Guernsey (+44)">Guernsey (+44)</option>
<option value="Guinea (+224)">Guinea (+224)</option>
<option value="Guinea Ecuatorial (+240)">Guinea Ecuatorial (+240)</option>
<option value="Guinea-Bisáu (+245)">Guinea-Bisáu (+245)</option>
<option value="Guyana (+592)">Guyana (+592)</option>
<option value="Haití (+509)">Haití (+509)</option>
<option value="Honduras (+504)">Honduras (+504)</option>
<option value="Hong Kong (+852)">Hong Kong (+852)</option>
<option value="Hungría (+36)">Hungría (+36)</option>
<option value="India (+91)">India (+91)</option>
<option value="Indonesia (+62)">Indonesia (+62)</option>
<option value="Irak (+964)">Irak (+964)</option>
<option value="Irán (+98)">Irán (+98)</option>
<option value="Irlanda (+353)">Irlanda (+353)</option>
<option value="Isla Bouvet (+47)">Isla Bouvet (+47)</option>
<option value="Isla de Man (+44)">Isla de Man (+44)</option>
<option value="Isla de Navidad (+61)">Isla de Navidad (+61)</option>
<option value="Isla Norfolk (+672)">Isla Norfolk (+672)</option>
<option value="Islandia (+354)">Islandia (+354)</option>
<option value="Islas Åland (+358)">Islas Åland (+358)</option>
<option value="Islas Caimán (+1)">Islas Caimán (+1)</option>
<option value="Islas Cocos (+61)">Islas Cocos (+61)</option>
<option value="Islas Cook (+682)">Islas Cook (+682)</option>
<option value="Islas Feroe (+298)">Islas Feroe (+298)</option>
<option value="Islas Georgias del Sur y Sandwich del Sur (+500)">Islas Georgias del Sur y Sandwich del Sur (+500)</option>
<option value="Islas Heard y McDonald (+672)">Islas Heard y McDonald (+672)</option>
<option value="Islas Maldivas (+960)">Islas Maldivas (+960)</option>
<option value="Islas Malvinas (+500)">Islas Malvinas (+500)</option>
<option value="Islas Marianas del Norte (+1)">Islas Marianas del Norte (+1)</option>
<option value="Islas Marshall (+692)">Islas Marshall (+692)</option>
<option value="Islas Pitcairn (+870)">Islas Pitcairn (+870)</option>
<option value="Islas Salomón (+677)">Islas Salomón (+677)</option>
<option value="Islas Turcas y Caicos (+1)">Islas Turcas y Caicos (+1)</option>
<option value="Islas Ultramarinas Menores de Estados Unidos (+1)">Islas Ultramarinas Menores de Estados Unidos (+1)</option>
<option value="Islas Vírgenes Británicas (+1)">Islas Vírgenes Británicas (+1)</option>
<option value="Islas Vírgenes de EE. UU. (+1)">Islas Vírgenes de EE. UU. (+1)</option>
<option value="Islas de Aland (+358)">Islas de Aland (+358)</option>
<option value="Israel (+972)">Israel (+972)</option>
<option value="Italia (+39)">Italia (+39)</option>
<option value="Jamaica (+1)">Jamaica (+1)</option>
<option value="Japón (+81)">Japón (+81)</option>
<option value="Jersey (+44)">Jersey (+44)</option>
<option value="Jordania (+962)">Jordania (+962)</option>
<option value="Kazajistán (+7)">Kazajistán (+7)</option>
<option value="Kenia (+254)">Kenia (+254)</option>
<option value="Kirguistán (+996)">Kirguistán (+996)</option>
<option value="Kiribati (+686)">Kiribati (+686)</option>
<option value="Kuwait (+965)">Kuwait (+965)</option>
<option value="Laos (+856)">Laos (+856)</option>
<option value="Lesoto (+266)">Lesoto (+266)</option>
<option value="Letonia (+371)">Letonia (+371)</option>
<option value="Líbano (+961)">Líbano (+961)</option>
<option value="Liberia (+231)">Liberia (+231)</option>
<option value="Libia (+218)">Libia (+218)</option>
<option value="Liechtenstein (+423)">Liechtenstein (+423)</option>
<option value="Lituania (+370)">Lituania (+370)</option>
<option value="Luxemburgo (+352)">Luxemburgo (+352)</option>
<option value="Macao (+853)">Macao (+853)</option>
<option value="Macedonia del Norte (+389)">Macedonia del Norte (+389)</option>
<option value="Madagascar (+261)">Madagascar (+261)</option>
<option value="Malasia (+60)">Malasia (+60)</option>
<option value="Malaui (+265)">Malaui (+265)</option>
<option value="Mali (+223)">Mali (+223)</option>
<option value="Malta (+356)">Malta (+356)</option>
<option value="Marruecos (+212)">Marruecos (+212)</option>
<option value="Martinica (+596)">Martinica (+596)</option>
<option value="Mauricio (+230)">Mauricio (+230)</option>
<option value="Mauritania (+222)">Mauritania (+222)</option>
<option value="Mayotte (+262)">Mayotte (+262)</option>
<option value="México (+52)">México (+52)</option>
<option value="Micronesia (+691)">Micronesia (+691)</option>
<option value="Moldavia (+373)">Moldavia (+373)</option>
<option value="Mónaco (+377)">Mónaco (+377)</option>
<option value="Mongolia (+976)">Mongolia (+976)</option>
<option value="Montenegro (+382)">Montenegro (+382)</option>
<option value="Montserrat (+1)">Montserrat (+1)</option>
<option value="Mozambique (+258)">Mozambique (+258)</option>
<option value="Myanmar (Birmania) (+95)">Myanmar (Birmania) (+95)</option>
<option value="Namibia (+264)">Namibia (+264)</option>
<option value="Nauru (+674)">Nauru (+674)</option>
<option value="Nepal (+977)">Nepal (+977)</option>
<option value="Nicaragua (+505)">Nicaragua (+505)</option>
<option value="Níger (+227)">Níger (+227)</option>
<option value="Nigeria (+234)">Nigeria (+234)</option>
<option value="Niue (+683)">Niue (+683)</option>
<option value="Noruega (+47)">Noruega (+47)</option>
<option value="Nueva Caledonia (+687)">Nueva Caledonia (+687)</option>
<option value="Nueva Zelanda (+64)">Nueva Zelanda (+64)</option>
<option value="Omán (+968)">Omán (+968)</option>
<option value="Países Bajos (+31)">Países Bajos (+31)</option>
<option value="Pakistán (+92)">Pakistán (+92)</option>
<option value="Palaos (+680)">Palaos (+680)</option>
<option value="Palestina (+970)">Palestina (+970)</option>
<option value="Panamá (+507)">Panamá (+507)</option>
<option value="Papúa Nueva Guinea (+675)">Papúa Nueva Guinea (+675)</option>
<option value="Paraguay (+595)">Paraguay (+595)</option>
<option value="Perú (+51)">Perú (+51)</option>
<option value="Polinesia Francesa (+689)">Polinesia Francesa (+689)</option>
<option value="Polonia (+48)">Polonia (+48)</option>
<option value="Portugal (+351)">Portugal (+351)</option>
<option value="Puerto Rico (+1)">Puerto Rico (+1)</option>
<option value="Qatar (+974)">Qatar (+974)</option>
<option value="Reino Unido (+44)">Reino Unido (+44)</option>
<option value="República Centroafricana (+236)">República Centroafricana (+236)</option>
<option value="República Checa (+420)">República Checa (+420)</option>
<option value="República Democrática del Congo (+243)">República Democrática del Congo (+243)</option>
<option value="República Dominicana (+1)">República Dominicana (+1)</option>
<option value="Reunión (+262)">Reunión (+262)</option>
<option value="Ruanda (+250)">Ruanda (+250)</option>
<option value="Rumanía (+40)">Rumanía (+40)</option>
<option value="Rusia (+7)">Rusia (+7)</option>
<option value="Sahara Occidental (+212)">Sahara Occidental (+212)</option>
<option value="Samoa (+685)">Samoa (+685)</option>
<option value="Samoa Americana (+1)">Samoa Americana (+1)</option>
<option value="San Bartolomé (+590)">San Bartolomé (+590)</option>
<option value="San Cristóbal y Nieves (+1)">San Cristóbal y Nieves (+1)</option>
<option value="San Marino (+378)">San Marino (+378)</option>
<option value="San Martín (+590)">San Martín (+590)</option>
<option value="San Pedro y Miquelón (+508)">San Pedro y Miquelón (+508)</option>
<option value="San Vicente y las Granadinas (+1)">San Vicente y las Granadinas (+1)</option>
<option value="Santa Elena (+290)">Santa Elena (+290)</option>
<option value="Santa Lucía (+1)">Santa Lucía (+1)</option>
<option value="Santo Tomé y Príncipe (+239)">Santo Tomé y Príncipe (+239)</option>
<option value="Senegal (+221)">Senegal (+221)</option>
<option value="Serbia (+381)">Serbia (+381)</option>
<option value="Seychelles (+248)">Seychelles (+248)</option>
<option value="Sierra Leona (+232)">Sierra Leona (+232)</option>
<option value="Singapur (+65)">Singapur (+65)</option>
<option value="Sint Maarten (+1)">Sint Maarten (+1)</option>
<option value="Siria (+963)">Siria (+963)</option>
<option value="Somalia (+252)">Somalia (+252)</option>
<option value="Sri Lanka (+94)">Sri Lanka (+94)</option>
<option value="Sudáfrica (+27)">Sudáfrica (+27)</option>
<option value="Sudán (+249)">Sudán (+249)</option>
<option value="Sudán del Sur (+211)">Sudán del Sur (+211)</option>
<option value="Suecia (+46)">Suecia (+46)</option>
<option value="Suiza (+41)">Suiza (+41)</option>
<option value="Surinam (+597)">Surinam (+597)</option>
<option value="Svalbard y Jan Mayen (+47)">Svalbard y Jan Mayen (+47)</option>
<option value="Tailandia (+66)">Tailandia (+66)</option>
<option value="Taiwán (+886)">Taiwán (+886)</option>
<option value="Tanzania (+255)">Tanzania (+255)</option>
<option value="Tayikistán (+992)">Tayikistán (+992)</option>
<option value="Territorio Británico del Océano Índico (+246)">Territorio Británico del Océano Índico (+246)</option>
<option value="Territorios Australes Franceses (+262)">Territorios Australes Franceses (+262)</option>
<option value="Timor-Leste (+670)">Timor-Leste (+670)</option>
<option value="Togo (+228)">Togo (+228)</option>
<option value="Tokelau (+690)">Tokelau (+690)</option>
<option value="Tonga (+676)">Tonga (+676)</option>
<option value="Trinidad y Tobago (+1)">Trinidad y Tobago (+1)</option>
<option value="Túnez (+216)">Túnez (+216)</option>
<option value="Turkmenistán (+993)">Turkmenistán (+993)</option>
<option value="Turquía (+90)">Turquía (+90)</option>
<option value="Tuvalu (+688)">Tuvalu (+688)</option>
<option value="Ucrania (+380)">Ucrania (+380)</option>
<option value="Uganda (+256)">Uganda (+256)</option>
<option value="Uruguay (+598)">Uruguay (+598)</option>
<option value="Uzbekistán (+998)">Uzbekistán (+998)</option>
<option value="Vanuatu (+678)">Vanuatu (+678)</option>
<option value="Vaticano (+39)">Vaticano (+39)</option>
<option value="Venezuela (+58)">Venezuela (+58)</option>
<option value="Vietnam (+84)">Vietnam (+84)</option>
<option value="Wallis y Futuna (+681)">Wallis y Futuna (+681)</option>
<option value="Yemen (+967)">Yemen (+967)</option>
<option value="Yibuti (+253)">Yibuti (+253)</option>
<option value="Zambia (+260)">Zambia (+260)</option>
<option value="Zimbabue (+263)">Zimbabue (+263)</option>
        
        </>


    );
}
 
export default Paises;