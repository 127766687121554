import React, { useState, createContext, useEffect} from 'react'
import clienteAxios from '../../config/axios'
import { Link, useNavigate } from 'react-router-dom'




const NavJoemaContext = createContext();


const NavJoemaProvider = ({children}) => {
    ///// aqui vamos a colocar el menu en 11 estados ////
    const [menu, setMenu] = useState(1);
    const Menu1 = () => {
        setMenu(1)
    }
    const Menu2 = () => {
        setMenu(2)
    }
    const Menu3 = () => {
        setMenu(3)
    }
    const Menu4 = () => {
        setMenu(4)
    }
    const Menu5 = () => {
        setMenu(5)
    }
    const Menu6 = () => {
        setMenu(6)
    }
    const Menu7 = () => {
        setMenu(7)
    }
    const Menu8 = () => {
        setMenu(8)
    }
    const Menu9 = () => {
        setMenu(9)
    }
    const Menu10 = () => {
        setMenu(10)
    }
    const Menu11 = () => {
        setMenu(11)
    }
    return (


        <NavJoemaContext.Provider
            value={{
                menu,
                Menu1,
                Menu2,
                Menu3,
                Menu4,
                Menu5,
                Menu6,
                Menu7,
                Menu8,
                Menu9,
                Menu10,
                Menu11,

            }}
        
        >


            {children}
        </NavJoemaContext.Provider>

    )


}

export {
    NavJoemaProvider
}

export default NavJoemaContext;