import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'
import d from '../../imagenes/iconoscuenta/d.svg'

const PagosYCobros = () => {
    const information = [
        {   id:0,
            icon:a,
            title:'¿Por qué mi información no aparece aquí?',
            cuento:'Para proteger tu identidad, ocultamos algunos datos de la cuenta.'
        }, {   id:1,
            icon:b,
            title:'¿Qué datos se pueden editar?',
            cuento:'No se pueden modificar los datos que Airbnb utiliza para verificar tu identidad. Aunque puedes modificar tus datos personales y de contacto, podríamos pedirte que verifiques tu identidad la próxima vez que hagas una reservación o crees un anuncio.'
        }, {   id:2,
            icon:c,
            title:'¿Qué información se comparte con los demás?',
            cuento:'Airbnb solo proporciona los datos de contacto de los anfitriones y los huéspedes cuando se confirma una reservación.'
        },
    ]


    const information02 = [
        {   id:0,
            icon:b,
            title:'Protege tu cuenta',
            cuento:'Responde solo a las solicitudes de personas que conozcas. Es posible que los hackers quieran acceder a tu cuenta imitando el correo electrónico de un miembro del equipo.'
        }
    ]

    const information01 = [
        {   id:0,
            icon:d,
            title:'Realiza todos los pagos a través de Air',
            cuento:'Si realizas tus pagos y te comunicas a través de la plataforma, podrás disfrutar de la protección que te brindan nuestros Términos de servicio, nuestros Términos del servicio de pagos, cancelación y otras garantías',
            btn:'Más información'
        }
    ] 

    const information03 = [
        {   id:0,
            icon:a,
            title:'Cómo agregar dispositivos de personas de confianza',
            cuento:'Cuando apruebas una solicitud, le estás dando a esa persona acceso total a tu cuenta. Podrá modificar las reservaciones y enviar mensajes en tu nombre.',
            btn:'Mejorar'
        }
    ]



    const task = [
       { id:0,
        value:'Pagos'
        },
        { id:1,
            value:'Cobros'
            },
            
    ]

    const {
        alessa,
        MeEncantas,
        focustask, setFocusTask
    } = useAcc()

    useEffect( () => {
        if(alessa.T == 'Pagos y cobros') {
            setFocusTask('Pagos')
        }
    },[])

    return (
        <>
        { alessa.T == 'Pagos y cobros' ?
                <div className=" box-header-general">
                <div className={`sabes-que-te-quiero`} >

                    <div>
                        <div className={`header-joema-hola-acc`}>
                            <p onClick={MeEncantas} className={`text-deco`}>
                                Cuenta
                            </p>
                            <span>{'>'}</span>
                                <p >{alessa.T}</p>
                        </div>
                        <div className={`header-joema-hola-title`}>
                            <p className={`Font-Love-Title`}>
                                {alessa.T}
                            </p>
                        </div>
                    </div>
                   <section className={`master-contenedor-action-bar`}>
                    <section className={'contenedor-action-bar'}>
                     { task.map((viste)=> {
                       return (

                           <button key={viste.id} className={`clasic-name-btn ${focustask === viste.value ? 'clasic-name-btn-active' :''} `}   
                                   onClick={ () => {setFocusTask(viste.value)}}
                           >
                               {viste.value}
                           </button>

                       )
                     })

                     }

                    </section>
                    </section>

                    { focustask === 'Pagos' ? 
                       <>
                       <section className={`contenedor-doble`}>
                       <div className={`bombaso`}>  
                                               <div className={`contenedor-input`}>
                                                <div className={`set-style`}>
                                                    <h2>
                                                        Tus pagos
                                                    </h2>
                                                    <p>
                                                    Lleva un registro de todos tus pagos y reembolsos.
                                                    </p>
                                                    <button className={`joema-btn-special`}>
                                                    Administra los pagos
                                                    </button>

                                                </div>

                                                <div className={`set-style`}>
                                                    <h2>
                                                    Formas de pago
                                                    </h2>
                                                    <p className={``}>
                                                    Agrega una forma de pago con nuestro sistema de pago seguro y comienza a planear tu próximo viaje.
                                                    </p>
                                                    <hr className={`special-hr`}/>
                                                    <button className={`joema-btn-special`}>
                                                    Administra los pagos
                                                    </button>

                                                </div>

                                                <div className={`set-style`}>
                                                    <h2>
                                                    Crédito de regalo
                                                    </h2>
                                                    <p className={``}>
                                                    Canjea una tarjeta de regalo y consulta tu saldo de crédito. <span className={`btn-text-raya`}> Aplican condiciones </span>
                                                    </p>
                                                    
                                                    <div className={`saldera`}>
                                                        <h4>Saldo actual</h4>
                                                        <p>$0.00</p>
                                                    </div>
                                                    
                                                    <button className={`joema-btn-special`}>
                                                    Agregar una tarjeta de regalo
                                                    </button>

                                                </div>

                                                <div className={`set-style`}>
                                                    <h2>
                                                    Cupones
                                                    </h2>
                                                    <p className={``}>
                                                    Agrega un cupón y ahorra en tu próximo viaje. 
                                                    </p>
                                                    
                                                    <div className={`saldera`}>
                                                        <h4>Tus cupones</h4>
                                                        <p>0</p>
                                                    </div>
                                                    
                                                    <button className={`joema-btn-special`}>
                                                    Agregar cupón
                                                    </button>

                                                </div>

                                                </div>
                                                   
                                             



                                               </div>
                                               <div >


                                                   <div className={`information-box-box-container`}>
                                                   {  information01.map((m) => {
                                                               return(
                                                                   <div key={m.id} className={`elmentaohijo`}> 
                                                                           <img src={m.icon}/>  
                                                                       <h3>{m.title}</h3>
                                                                       <p >{m.cuento}</p>
                                                                      {
                                                                       m.btn ? <>
                                                                       <button className={`joema-btn-special`}>
                                                                           {m.btn}
                                                                       </button>                 
                                                                       </> : null
                                                                      }
                                                                   </div>
                                                               )
                                                           })
                                                   }

                                                   </div>
                                                   
                                               </div>

                    </section>
                                           </> : null
                                       }
                                       

                                       
                    { focustask === 'Cobros' ? 
                       <>
                       <section className={`contenedor-doble`}>
                       <div className={`bombaso`}> 

                                               <div className={`contenedor-input`}>
                                                    
                                               <div className={`set-style`}>
                                                    <h2>
                                                    Cómo recibirás los cobros
                                                    </h2>
                                                    <p>
                                                    Agrega al menos una forma de cobro para que sepamos a dónde enviar tu dinero.
                                                    </p>
                                                    <button className={`joema-btn-special`}>
                                                    Configura los cobros
                                                    </button>

                                                </div>
                                                   

                                           
                                                </div>
                                            



                                               </div>
                                               <div >


                                                   <div className={`information-box-box-container`}>
                                                   {  information01.map((m) => {
                                                               return(
                                                                   <div key={m.id} className={`elmentaohijo`}> 
                                                                           <img src={m.icon}/>  
                                                                       <h3>{m.title}</h3>
                                                                       <p>{m.cuento}</p>
                                                                       {
                                                                       m.btn ? <>
                                                                       <button className={`joema-btn-special`}>
                                                                           {m.btn}
                                                                       </button>                 
                                                                       </> : null
                                                                      }
                                                                   </div>
                                                               )
                                                           })
                                                   }

                                                   </div>
                                                   
                                               </div>

                    </section>
                                           </> : null
                                       }
                                       

                                       
                    
                   

                </div>

               
         </div> : null

        }
        </>

    );
}
 
export default PagosYCobros;