import React, { useEffect, useContext} from 'react';
import LogoSvg from '../../imagenes/background/LogoOutLine.svg'
import LogoSvg2 from '../../imagenes/background/LogoBgFill.svg'
import useData from '../../hooks/useData';
import MenuContext from '../../context/menu/menuContext'

import { useNavigate } from 'react-router-dom'



const BAlerta = ({redirectlog
    ,setRedirect}) => {

    const {windowalerta, setWinAlert, Recargar} = useData();

    const navigate = useNavigate()
    
    const Log = () => {
        navigate('/')
    }

    const menuContext = useContext(MenuContext);

    const {CloseWindows,IndiOpen,AccOpen,BenOpen,EleOpen,SigOpen,
    
        individual,
        accopening,
        ben,
        signature,
        electronical,
    
    
    } = menuContext;

    // useEffect(() => {
    //     if(redirectlog === true) {
    //         Log();
    //         setRedirect(false)
    //     }
    // }, [])

    return (  

        

        

            <>

                {
                    windowalerta.open === true ?

                



            <section className="beauty-joema-alert">

                <span className={`span-loader-joema-alert-group ${windowalerta.status === false ? 'noaceptar':'aceptar'}`}>
                    <span className="span-hijo-1">
                    </span>
                    <span className="span-hijo-2">
                    </span>
                    

                </span>


            {/* <img className="img-svg-out" src={LogoSvg} /> */}
            <img className="img-svg-out-2" src={LogoSvg2} />

                
                    { windowalerta.data === null ? <h3>Cargando...</h3> : <h3>{`${windowalerta.data}`}</h3>}

                    


                    <button className="btn-continuar-alerta" 
                    onClick={ () => {setWinAlert({
                                data:null,
                                status:false,
                                open:false,
                                })
                                Recargar()
                                CloseWindows()
                                if(redirectlog === true) {
                                    Log();
                                }
                                // setRedirect(false)
                                }}>
                            Continuar
                    </button>

            

                                    
                

            </section> :null

            }





        </> 
    
    



    );
}
 
export default BAlerta;