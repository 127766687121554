import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc'
import Paises from './Paises'
import Idioma from './Idioma'



const InputDynamic = ({info, firstlabel, seclabel, inputname, action, model, inputlabel,onFocus, treslabel, cuatrolabel, quintolabel }) => {

    const {
        alessa,
        MeEncantas,
        focusinput, 
        setFocusInput,
        focus,
        setFocus,
        focusselect , setFocusSelect
    } = useAcc()
        
const [n, setN] = useState(false)

const openN = () => {
    setN(!n)
    setFocusInput(inputname)
    if (focusinput === inputname) {
        setFocusInput('')
    }
    setFocus('')
}

const cara = (v) => {
    setFocus(v)
   
}

const focustime = () => {
    console.log('hola')
}



const onChange = e => {
    // voy a colocar guardar usuario en el state
    setFocusSelect({
         ...focusselect,
        [e.target.name] : e.target.value

    })
    setFocus('')
}

console.log(focus)


useEffect(
    ()=>{
        if(focusinput !== inputname) {
            setN(false)
            setFocusSelect({
                pais:'',
                region:'',
                idioma:''

            })
        }
    },[focusinput]
)

    return(
        <>
                <div className={`contenedor-input ${n === false ? '':'border-bottom-active'}`}>

                <div className={`contenedor-header-input`}>
                        <h2 className={`${focusinput === inputname ? 'active-joema' :''}`}>
                            {inputname}
                        </h2>

                        <button onClick={openN}>
                            { n !== true ? 'Editar':'Cancelar'}
                        </button>
                </div>
                    {   n === true ? 
                    <>
                    <div className={`information-input`}>
                        <p className={`text-input`}>
                       {info}
                        </p>

                        {
                            model === 1 ? 
                            <div className="input-joema-master-geo-contenedor input-contenedor-100">  
                            <div className={`input-joema-master-geo input-hide-hide ${firstlabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {firstlabel}
                                </label>
                                <input onFocus={
                                   () => {
                                    setFocus(firstlabel)
                                   }
                                }/>

                            </div>
                           
                            </div>
                            
                            :null
                        }


                        {
                            model === 2 ? 
                            <div className="input-joema-master-geo-contenedor">  
                            <div className={`input-joema-master-geo ${firstlabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {firstlabel}
                                </label>
                                <input onFocus={
                                        () => {
                                            setFocus(firstlabel)
                                           }


                                }/>

                            </div>

                            <div className={`input-joema-master-geo ${seclabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {seclabel}
                                </label>
                                <input onFocus={
                                    () => {
                                        setFocus(seclabel)
                                       }
                                        
                                }/>


                            </div>
                            </div>
                            
                            
                            :null
                        }

                      {
                            model === 3 ? 
                            <div className="input-joema-master-geo-contenedor input-contenedor-100">  

                            <div>
                                <p className={`font-clarity`}>Ingresa un número de teléfono nuevo</p>
                                </div>
                            <div className={`input-joema-master-geo input-hide-hide select-sty ${focusselect.pais !== '' ? 'focus-active':''}`}>
                                <label>
                                    País o región
                                </label>
                                

                                <select 
                                       id="pais"
                                       name="pais"
                                        onChange={onChange}
                                    //    value={individualblank.marital_status == null ? '' : individualblank.marital_status}
                                
                                className={`bg-ground`} 
                                    onFocus={focustime}
                                >
                                <option value="">Seleccione su país</option>
                                    <Paises/>
                                </select>

                            </div>
                            <div className={`input-joema-master-geo input-hide-hide ${inputname === focus ? 'focus-active':''}`}>
                                <label>
                                Número Telefónico
                                </label>
                                <input onFocus={
                                   () => {
                                    setFocus(inputname)
                                    setFocusSelect({
                                        pais:'',
                                        region:'',
                                        idioma:'',
                                    })
                                   }
                                }/>

                            </div>

                            <div>
                                <p className={`font-clarity`}>Te vamos a enviar un código por SMS para verificar tu número. Sujeto a tarifas estándares para mensajes y datos.</p>
                                </div>

                           
                            </div>:null
                        }

                        {
                            model === 4 ? 

                            <>
                            <div className="input-joema-master-geo-contenedor pad-00-y">  
                            <div className={`input-joema-master-geo input-hide-hide select-sty ${focusselect.pais !== '' ? 'focus-active':''}`}>
                                <label>
                                    País o región
                                </label>

                                <select 
                                       id="pais"
                                       name="pais"
                                        onChange={onChange}
                                    //    value={individualblank.marital_status == null ? '' : individualblank.marital_status}
                                
                                className={`bg-ground`} 
                                    onFocus={focustime}
                                >
                                <option value="">Seleccione su país</option>
                                    <Paises/>
                                </select>

                            </div>

                            </div>
                            <div className="input-joema-master-geo-contenedor pad-00-y input-contenedor-100">  
                            <div className={`input-joema-master-geo input-hide-hide pad-01-y  ${firstlabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {firstlabel}
                                </label>
                                <input onFocus={
                                   () => {
                                    setFocus(firstlabel)
                                   }
                                }/>

                            </div>

                            <div className={`input-joema-master-geo pad-01-y  input-hide-hide ${seclabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {seclabel}
                                </label>
                                <input onFocus={
                                   () => {
                                    setFocus(seclabel)
                                   }
                                }/>

                            </div>
                            </div>
                             
                            <div className="input-joema-master-geo-contenedor pad-00-y input-contenedor-50">

                                
                                

                                <div className={`input-joema-master-geo pad-01-y  input-hide-hide ${treslabel === focus ? 'focus-active':''}`}>
                                    <label>
                                        {treslabel}
                                    </label>
                                    <input  onFocus={
                                    () => {
                                        setFocus(treslabel)
                                        setFocusSelect({
                                            pais:'',
                                            region:'',
                                            idioma:'',
                                        })
                                    }
                                    }
                                    />

                                </div>   

                                <div className={`input-joema-master-geo pad-01-y  input-hide-hide ${cuatrolabel === focus ? 'focus-active':''}`}>
                                    <label>
                                        {cuatrolabel}
                                    </label>
                                    <input  onFocus={
                                    () => {
                                        setFocus(cuatrolabel)
                                        setFocusSelect({
                                            pais:'',
                                            region:'',
                                            idioma:'',
                                        })
                                    }
                                    }
                                    />

                                </div>   

                                <div className={`input-joema-master-geo pad-01-y  input-hide-hide ${quintolabel === focus ? 'focus-active':''}`}>
                                    <label>
                                        {quintolabel}
                                    </label>
                                    <input  onFocus={
                                    () => {
                                        setFocus(quintolabel)
                                        setFocusSelect({
                                            pais:'',
                                            region:'',
                                            idioma:'',
                                        })
                                    }
                                    }
                                    />

                                </div>   




                                </div>

                            
                            </>
                            
                            :null
                        }           


                        {
                            model === 5  ? 

                            <>
                           
                            <div className="input-joema-master-geo-contenedor pad-00-y input-contenedor-100">  

                           
                            <div className={`input-joema-master-geo input-hide-hide pad-01-y  ${firstlabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {firstlabel}
                                </label>
                                <input  onFocus={
                                   () => {
                                    setFocus(firstlabel)
                                    setFocusSelect({
                                        pais:'',
                                        region:'',
                                        idioma:'',
                                    })
                                   }
                                }
                                />

                            </div>

                            <div className={`input-joema-master-geo pad-01-y  input-hide-hide ${seclabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {seclabel}
                                </label>
                                <input  onFocus={
                                   () => {
                                    setFocus(seclabel)
                                    setFocusSelect({
                                        pais:'',
                                        region:'',
                                        idioma:'',
                                    })
                                   }
                                }
                                />

                            </div>  

                            <div className={`input-joema-master-geo input-hide-hide select-sty ${focusselect.idioma !== '' ? 'focus-active':''}`}>
                                <label>
                                    Idioma de preferencia
                                </label>
                                

                                <select 
                                       id="idioma"
                                       name="idioma"
                                        onChange={onChange}
                                    //    value={individualblank.marital_status == null ? '' : individualblank.marital_status}
                                
                                className={`bg-ground`} 
                                    onFocus={focustime}
                                >
                                <option value="">Idioma de preferencia</option>
                                    <Idioma/>
                                </select>

                            </div>      

                            
                            <div className={`input-joema-master-geo pad-01-y  input-hide-hide ${treslabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {treslabel}
                                </label>
                                <input  onFocus={
                                   () => {
                                    setFocus(treslabel)
                                    setFocusSelect({
                                        pais:'',
                                        region:'',
                                        idioma:'',
                                    })
                                   }
                                }
                                />

                            </div>  
                            
                            </div>

                            <div className="input-joema-master-geo-contenedor pad-00-y input-contenedor-370">

                                
                            <div className={`input-joema-master-geo input-hide-hide select-sty ${focusselect.pais !== '' ? 'focus-active':''}`}>
                                <label>
                                    Código de país
                                </label>
                                

                                <select 
                                       id="pais"
                                       name="pais"
                                        onChange={onChange}
                                    //    value={individualblank.marital_status == null ? '' : individualblank.marital_status}
                                
                                className={`bg-ground`} 
                                    onFocus={focustime}
                                >
                                <option value="">Seleccione su país</option>
                                    <Paises/>
                                </select>

                            </div>    

                            <div className={`input-joema-master-geo pad-01-y  input-hide-hide ${cuatrolabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {cuatrolabel}
                                </label>
                                <input  onFocus={
                                   () => {
                                    setFocus(cuatrolabel)
                                    setFocusSelect({
                                        pais:'',
                                        region:'',
                                        idioma:'',
                                    })
                                   }
                                }
                                />

                            </div>   




                            </div>
                        
                           

                            
                            </>
                            
                            :null
                        }

{
                            model === 6 ? 

                            <>
                         
                            <div className="input-joema-master-geo-contenedor pad-00-y input-contenedor-100">  
                            <div className={`input-joema-master-geo input-hide-hide pad-01-y  ${firstlabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {firstlabel}
                                </label>
                                <input onFocus={
                                   () => {
                                    setFocus(firstlabel)
                                   }
                                }
                                type={`password`}
                                />

                            </div>

                            <div className={`input-joema-master-geo pad-01-y  input-hide-hide ${seclabel === focus ? 'focus-active':''}`}>
                                <label>
                                    {seclabel}
                                </label>
                                <input onFocus={
                                   () => {
                                    setFocus(seclabel)
                                   }
                                }
                                type={`password`}
                                />

                            </div>
                            
                            <div className={`input-joema-master-geo pad-01-y  input-hide-hide ${treslabel === focus ? 'focus-active':''}`}>
                                    <label>
                                        {treslabel}
                                    </label>
                                    <input  onFocus={
                                    () => {
                                        setFocus(treslabel)
                                        setFocusSelect({
                                            pais:'',
                                            region:'',
                                            idioma:'',
                                        })
                                    }
                                    }
                                    type={`password`}
                                    />

                                </div>  
                            </div>
                             
                           

                            
                            </>
                            
                            :null
                        }           



                        <div className="btn-container">
                            <button className={`joema-btn-special`}>
                                    {action}
                            </button>
                            
                        </div>
                    </div>
                    </>
                    : 
                    <>
                    <div className={`information-input`}>
                        <p className={`text-input-2`}>
                        {inputlabel}
                        </p>
                    </div>
                    </>
                    }

                </div>

        </>
    )
}

export default InputDynamic;