import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'

const Notificaciones = () => {
    const information = [
        {   id:0,
            icon:a,
            title:'¿Por qué mi información no aparece aquí?',
            cuento:'Para proteger tu identidad, ocultamos algunos datos de la cuenta.'
        }, {   id:1,
            icon:b,
            title:'¿Qué datos se pueden editar?',
            cuento:'No se pueden modificar los datos que Airbnb utiliza para verificar tu identidad. Aunque puedes modificar tus datos personales y de contacto, podríamos pedirte que verifiques tu identidad la próxima vez que hagas una reservación o crees un anuncio.'
        }, {   id:2,
            icon:c,
            title:'¿Qué información se comparte con los demás?',
            cuento:'Airbnb solo proporciona los datos de contacto de los anfitriones y los huéspedes cuando se confirma una reservación.'
        },
    ]


    const information02 = [
        {   id:0,
            icon:b,
            title:'¿Dónde quieres recibir los mensajes de texto?',
            cuento:'Al marcar la casilla Mensajes SMS, aceptas recibir mensajes de texto promocionales automatizados de Airbnb y sus socios en',
            cuento2:'Para más información, envía la palabra HELP en un mensaje de texto al 247262. Para cancelar las notificaciones a tu celular, envía la palabra STOP en un mensaje de texto al 247262. Podrían aplicarse costos adicionales de mensajes y datos.'
        }
    ]

    const information01 = [
        {   id:0,
            icon:b,
            title:'Vamos a hacer que tu cuenta sea más segura',
            cuento:'Trabajamos constantemente en nuevas formas para aumentar la seguridad de nuestra comunidad. Es por eso que revisamos cada cuenta para asegurarnos de que sea lo más segura posible.',
            btn:'Mejorar'
        }
    ] 

    const information03 = [
        {   id:0,
            icon:a,
            title:'Cómo agregar dispositivos de personas de confianza',
            cuento:'Cuando apruebas una solicitud, le estás dando a esa persona acceso total a tu cuenta. Podrá modificar las reservaciones y enviar mensajes en tu nombre.',
            btn:'Mejorar'
        }
    ]



    const task = [
       { id:0,
        value:'Ofertas y novedades'
        },
        { id:1,
            value:'Cuenta'
            }
         
    ]

    const {
        alessa,
        MeEncantas,
        focustask, setFocusTask,
        focusinput, 
        setFocusInput,
        focus,
        setFocus,
        focusselect , setFocusSelect
    } = useAcc()

    // useEffect( () => {
    //     if(focustask === "") {
    //         setFocusTask('INICIO DE SESIÓN')
    //     }
    // },[alessa])
    useEffect( () => {
        if(alessa.T == 'Notificaciones') {
            setFocusTask('Ofertas y novedades')
        }
    },[])

    const onChange = e => {
        // voy a colocar guardar usuario en el state
        setFocusSelect({
             ...focusselect,
            [e.target.name] : e.target.value
    
        })
        setFocus('')
    }
    
    useEffect(
        ()=>{
            if(focusinput !== inputname) {
                setN(false)
                setFocusSelect({
                    pais:'',
                    region:'',
                    idioma:''
    
                })
            }
        },[focusinput]
    )
    const [n, setN] = useState(false)
    let inputname = 'Joel'

    return (
        <>
        { alessa.T == 'Notificaciones' ?
                 <div className=" box-header-general">
                 <div className={`sabes-que-te-quiero`} >

                     <div>
                         <div className={`header-joema-hola-acc`}>
                             <p onClick={MeEncantas} className={`text-deco`}>
                                 Cuenta
                             </p>
                             <span>{'>'}</span>
                                 <p >{alessa.T}</p>
                         </div>
                         <div className={`header-joema-hola-title`}>
                             <p className={`Font-Love-Title`}>
                                 {alessa.T}
                             </p>
                         </div>
                     </div>
                    <section className={`master-contenedor-action-bar`}>
                     <section className={'contenedor-action-bar'}>
                      { task.map((viste)=> {
                        return (

                            <button key={viste.id} className={`clasic-name-btn ${focustask === viste.value ? 'clasic-name-btn-active' :''} `}   
                                    onClick={ () => {setFocusTask(viste.value)}}
                            >
                                {viste.value}
                            </button>

                        )
                      })

                      }

                     </section>
                     </section>

                     { focustask === 'Ofertas y novedades' ? 
                        <>
                        <section className={`contenedor-doble`}>
                                      <div className={`bombaso`}>

                                                <div className={`contenedor-input padd66`}>
                                                <div className={`set-style-2 `}>
                                                    <h2>
                                                    Consejos de viaje y ofertas
                                                    </h2>
                                                    <p>
                                                    Inspírate para tu próximo viaje con recomendaciones personalizadas y ofertas especiales.
                                                    </p>
                                                </div>

                                                <div className={`set-style-2 `}>
                                                    <p>
                                                    Inspiración y ofertas
                                                    </p>
                                                    <p className={``}>
                                                    Se seleccionó: Correo electrónico
                                                    </p>
                                                    <span className={`btn-text-raya`}> Editar</span>
                                                </div> 

                                                <div className={`set-style-2 `}>
                                                    <p>
                                                    Planeación de viajes
                                                    </p>
                                                    <p className={``}>
                                                    Se seleccionó: Correo electrónico
                                                    </p>
                                                    <span className={`btn-text-raya`}> Editar</span>
                                                </div>
                                                </div>

                                                <div className={`contenedor-input padd66`}>
                                                <div className={`set-style-2 `}>
                                                    <h2>
                                                    Novedades 
                                                    </h2>
                                                    <p>
                                                    Mantente al día de las últimas noticias y dinos cómo podemos mejorar.
                                                    </p>
                                                </div>

                                                <div className={`set-style-2 `}>
                                                    <p>
                                                    Novedades y programas
                                                    </p>
                                                    <p className={``}>
                                                    Se seleccionó: Correo electrónico
                                                    </p>
                                                    <span className={`btn-text-raya`}> Editar</span>
                                                </div> 

                                                <div className={`set-style-2 `}>
                                                    <p>
                                                    Comentarios
                                                    </p>
                                                    <p className={``}>
                                                    Se seleccionó: Correo electrónico
                                                    </p>
                                                    <span className={`btn-text-raya`}> Editar</span>
                                                </div>
                                                <div className={`set-style-2 `}>
                                                    <p>
                                                    Normativas sobre los viajes
                                                    </p>
                                                    <p className={``}>
                                                    Se seleccionó: Correo electrónico
                                                    </p>
                                                    <span className={`btn-text-raya`}> Editar</span>
                                                </div>
                                                
                                                </div>
                                                <div className={`contenedor-input`}>
                                                <p><span className={`btn-text-raya`}>  Cancela la suscripción </span> de todos los correos electrónicos de marketing</p>
                                                

                           
                                                </div>

                                    
                                                    


                                                </div>
                                                <div >


                                                    <div className={`information-box-box-container`}>
                                                    {  information02.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                            
                                                                        <h3>{m.title}</h3>
                                                                        <p>{m.cuento}</p>
                                                                        <div className={`input-joema-master-geo input-hide-hide select-sty ${focusselect.pais !== '' ? 'focus-active':''}`}>
                                                                                <label>
                                                                                    País o región
                                                                                </label>
                                                                                

                                                                                <select 
                                                                                    id="pais"
                                                                                    name="pais"
                                                                                    onChange={onChange}
                                                                                    //    value={individualblank.marital_status == null ? '' : individualblank.marital_status}
                                                                                
                                                                                className={`bg-ground`} 
                                                                                    
                                                                                >
                                                                                <option value="">Elige un número de teléfono</option>
                                                                                    
                                                                                </select>

                                                                            </div>
                                                                       <p>{m.cuento2}</p>
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>
                                            </> : null
                                        }
                                        

                                        
                     { focustask === 'Cuenta' ? 
                        <>
                        <section className={`contenedor-doble`}>
                        <div className={`bombaso`}>
                                                <div className={`contenedor-input padd66`}>
                                                <div className={`set-style-2 `}>
                                                    <h2>
                                                    Actividad de la cuenta y políticas
                                                    </h2>
                                                    <p>
                                                    Confirma las reservas, consulta la actividad de tu cuenta y descubre las políticas más importantes
                                                    </p>
                                                </div>

                                                <div className={`set-style-2 `}>
                                                    <p>
                                                    Actividad de la cuenta
                                                    </p>
                                                    <p className={``}>
                                                    Se seleccionaron: Correo electrónico, Mensaje de texto
                                                    </p>
                                                    <span className={`btn-text-raya`}> Editar</span>
                                                </div> 

                                                <div className={`set-style-2 `}>
                                                    <p>
                                                    Políticas relativas a los viajeros
                                                    </p>
                                                    <p className={``}>
                                                    Se seleccionaron: Correo electrónico, Mensaje de texto
                                                    </p>
                                                    <span className={`btn-text-raya`}> Editar</span>
                                                </div>
                                                </div>

                                                <div className={`contenedor-input padd66`}>
                                                <div className={`set-style-2 `}>
                                                    <h2>
                                                    Recordatorios
                                                    </h2>
                                                    <p>
                                                    Recibe recordatorios importantes sobre las reservaciones, los anuncios y la actividad de tu cuenta.
                                                    </p>
                                                </div>

                                                <div className={`set-style-2 `}>
                                                    <p>
                                                    Recordatorios
                                                    </p>
                                                    <p className={``}>
                                                    Se seleccionaron: Correo electrónico, Mensaje de texto
                                                    </p>
                                                    <span className={`btn-text-raya`}> Editar</span>
                                                </div> 

                                                
                                                
                                                </div>

                                                <div className={`contenedor-input padd66`}>
                                                <div className={`set-style-2 `}>
                                                    <h2>
                                                    Mensajes de los viajeros y anfitriones
                                                    </h2>
                                                    <p>
                                                    Mantente en contacto con el anfitrión o los viajeros durante la reservación y antes de que empiece.
                                                    </p>
                                                </div>

                                                <div className={`set-style-2 `}>
                                                    <p>
                                                    Mensajes
                                                    </p>
                                                    <p className={``}>
                                                    Se seleccionaron: Correo electrónico, Mensaje de texto
                                                    </p>
                                                    <span className={`btn-text-raya`}> Editar</span>
                                                </div>     
                                                </div>
                                                    
                                                    

                                            

                                             



                                                </div>
                                                <div >


                                                <div className={`information-box-box-container`}>
                                                    {  information02.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                            
                                                                        <h3>{m.title}</h3>
                                                                        <p>{m.cuento}</p>
                                                                        <div className={`input-joema-master-geo input-hide-hide select-sty ${focusselect.pais !== '' ? 'focus-active':''}`}>
                                                                                <label>
                                                                                    País o región
                                                                                </label>
                                                                                

                                                                                <select 
                                                                                    id="pais"
                                                                                    name="pais"
                                                                                    onChange={onChange}
                                                                                    //    value={individualblank.marital_status == null ? '' : individualblank.marital_status}
                                                                                
                                                                                className={`bg-ground`} 
                                                                                    
                                                                                >
                                                                                <option value="">Elige un número de teléfono</option>
                                                                                    
                                                                                </select>

                                                                            </div>
                                                                       <p>{m.cuento2}</p>
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>
                                            </> : null
                                        }
                                        

                                        
                   
                    

                 </div>

                
          </div> : null

        }
        </>

    );
}
 
export default Notificaciones;