import React from 'react'
import useAct from '../../hooks/useAct';
import x from '../../imagenes/iconos/x.svg'

const WindowHelp = () => {


    

    const {
        winlow,
        WinActDos,
        img1win, setImg1,
        img2win, setImg2,
        img3win, setImg3,
        img4win, setImg4,
        img5win, setImg5 
    } = useAct()

    

    return ( 
            <>
            
            {
                    winlow === true ?
                    <>
                         <div  className={` windows-selection-33 ${winlow !== false ? ' op-true adelante ':''}  ${winlow !== true ?'animation-open-option-close  op-none':''}`}>
                <section className="windows-selection-help">

                    <section className="windows-selection-hola">
                        <div className="windows-selection-hola-hola">
                            <img
                                className="img-fix-x"
                                src={x}
                                onClick={WinActDos}
                            />

                            

                        </div>



                      <section className="airbox-joema">
                      <div className="air-box-bx">
                            <h2>Asistencia</h2>
                            <p>Centro de ayuda</p>
                            <p>GroundScripCover</p>
                            <p>Apoyo a las personas con discapacidad</p>
                            <p>Opciones de cancelacion</p>
                            <p>Nuestra respuesta frente al COVID-19</p>
                            <p>Denunciar un problema en el vecindario</p>
                        </div>

                        <div className="air-box-bx">
                            <h2>Comunidad</h2>
                            <p>Luchamos contra la discriminacion</p>
                            <p>Alojamiento de ayuda en caso de catastrofe</p>
                         
                        </div>

                        <div className="air-box-bx">
                            <h2>Modo corredor</h2>
                            <p>Pon tu propiedad</p>
                            <p>GroundScripCover para corredores </p>
                            <p>Apoyo a las personas con discapacidad</p>
                            <p>Recursos para corredores</p>
                            <p>Visita el foro de la comunidad</p>
                            <p>Cómo brindar servicios de Corredor de forma responsable</p>
                        </div>

                        <div className="air-box-bx">
                            <h2>GroundScript air</h2>
                            <p>Sala de prensa</p>
                            <p>Más información</p>
                            <p>Carta de nuestros fundadores</p>
                            <p>Carreras</p>
                            <p>Inversionistas</p>
   
                        </div>


                      </section>


                    </section>

                </section>
            </div>
                    
                    </> :null
            }
           
            </>


     );
}
 
export default WindowHelp;