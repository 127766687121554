import React, { useState } from 'react'
import e from '../../imagenes/iconoscuenta/e.svg'


const InputOn = ({state}) => {

    const [estate, setEs] = useState(state)

    const t = () => {
        setEs(!estate)
    }
    return ( <>
    
        <section className={`btn-on ${estate === true ? 'active-on-flex-btn-bor' : ''}`} onClick={t} >

            <div className={`btn-on-flex ${estate === true ? 'active-on-flex-btn' : ''}`} >
                <img src={e} alt="img" />

            </div>

        </section>
    
    
    </> );
}
 
export default InputOn;