
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
// import MaskedInput from 'react-input-mask'

import createNumberMask from 'text-mask-addons/dist/createNumberMask'



const defaultMaskOptions = {
  suffix: '',
  prefix:'',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const CurrencyInput = ({ money, maskOptions, ...inputProps }) => {

    const Currency ={
        prefix:`${money}`,
    }


  const currencyMask = createNumberMask({
     ...Currency,
    ...defaultMaskOptions,
    ...maskOptions,
  })
  return <><MaskedInput  mask={currencyMask} {...inputProps}  /></>
    
}

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
}

CurrencyInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    
    thousandsSeparatorSymbol: PropTypes.bool,
    
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.number,
    
    
    
    integerLimit: PropTypes.number,
  }),
}

export default CurrencyInput